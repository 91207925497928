import React, { useState } from "react";
import { BoyIcon, GirlIcon } from "../../../assets/image/gender/gender";
import { DatePicker, Form, Input } from 'antd';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const CaculatorGrowth = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nameBaby: '',
    gender: 'boy',
    height: '',
    weight: '',
    headCircumference: '',
    bmi: '',
    Dob: dayjs().format('YYYY-MM-DD'),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const checkValueNumber = name === 'nameBaby' ? value : Number(value);

    setFormData((prev) => ({ ...prev, [name]: checkValueNumber }));

    if (formData.height && formData.weight) {
      const heightInMeters = formData.height / 100;
      const bmiValue = formData.weight / (heightInMeters * heightInMeters);

      setFormData((prev) => ({ ...prev, bmi: bmiValue.toFixed(2)}));
    }
  };

  const handleDateChange = (date) => {
    let dateValue = dayjs().format('YYYY-MM-DD')
    if (date !== null) {
      dateValue = date.format('YYYY-MM-DD')
    }

    setFormData((prev) => ({ ...prev, ["Dob"]: dateValue }))
  }

  const handleSelectGender = (tool) => {
    setFormData((prev) => ({ ...prev, ["gender"]: tool }))
  };

  const handleFinishSubmot = () => {
    navigate("/tool/growth-child/result", { state: formData });
  }

  return (
    <div className="w-full">
      <div className="bg-gray-100 p-6 rounded-[50px]">
        <Form className="space-y-5" onFinish={handleFinishSubmot} initialValues={formData}>
          {/* Enter baby name */}
          <div className="relative">
            {formData.nameBaby.length > 0 && (
              <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                Enter baby's name
              </label>
            )}
            <Form.Item
              rules={[
                { required: true, message: "Please enter your baby's name!" }
              ]}
              name="nameBaby"
              className='mb-8'
            >
              <Input
                name="nameBaby"
                placeholder="Enter baby's name"
                className="p-4 w-full mr-2 rounded-lg"
                value={formData.nameBaby}
                onChange={handleInputChange}
              />
            </Form.Item>
          </div>

          {/* Choose gender */}
          <p>What is the baby's gender?</p>
          <div className="flex justify-evenly sm:grid-cols-2 lg:grid-cols-3 sm:px-2 lg:px-4">
            <button
              name="gender"
              onClick={() => handleSelectGender("boy")}
              className={`flex justify-center ${formData.gender === "boy" ? "" : "opacity-50"
                }`}
            >
              <div
                className={`bg-white h-20 sm:h-20 rounded-full w-[15rem] flex justify-center items-center ${formData.gender === "boy" ? "ring-2 ring-pinkColor" : ""
                  }`}
              >
                {BoyIcon}
                <h5 className="text-base text-center ml-3 pt-2">Boy</h5>
              </div>
            </button>

            <button
              name="gender"
              onClick={() => handleSelectGender("girl")}
              className={`flex justify-center ${formData.gender === "girl" ? "" : "opacity-50"
                }`}
            >
              <div
                className={`bg-white h-20 sm:h-20 rounded-full w-[15rem] flex justify-center items-center  ${formData.gender === "girl" ? "ring-2 ring-pinkColor" : ""
                  }`}
              >
                {GirlIcon}
                <h5 className="text-base text-center ml-3 pt-2">Girl</h5>
              </div>
            </button>
          </div>
          {/* Enter information baby */}
          <div>
            <Form.Item
              rules={[
                { required: true, message: "Please enter your baby's birthday!" }
              ]}
              name="Dob"
              className='mb-8'
            >
              <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                Date of birthday
              </label>
              <DatePicker
                name="Dob"
                className='w-full p-4 rounded-lg'
                onChange={handleDateChange}
                defaultValue={dayjs()} />
            </Form.Item>
            <div className="flex mt-4 space-x-1 rounded-lg hover:border-none active:border-none">
              <div className="relative">
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter baby's height!" }
                  ]}
                  name="height"
                  className='mb-8'
                >
                  {formData.height.length > 0 && (
                    <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                      Height (cm)
                    </label>
                  )}
                  <Input
                    type="number"
                    min={0}
                    name="height"
                    placeholder="Enter baby's Height"
                    className="p-4 w-[300px] mr-2 rounded-lg"
                    value={formData.height}
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>

              <div className="relative">
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter baby's Weight!" }
                  ]}
                  name="weight"
                  className='mb-8'
                >
                  {formData.weight.length > 0 && (
                    <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                      Weight (kg)
                    </label>
                  )}
                  <Input
                    type="number"
                    name="weight"
                    min={0}
                    placeholder="Enter baby's Weight"
                    className="p-4 w-[300px] mr-2 rounded-lg"
                    value={formData.weight}
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex mt-4 space-x-1 rounded-lg hover:border-none active:border-none">
              <div className="relative">
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter baby's Head circumference!" }
                  ]}
                  name="headCircumference"
                  className='mb-8'
                >
                  {formData.headCircumference.length > 0 && (
                    <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                      Head circumference(cm)
                    </label>
                  )}
                  <Input
                    type="number"
                    name="headCircumference"
                    min={0}
                    placeholder="Enter baby's head circumference"
                    className="p-4 w-[300px] mr-2 rounded-lg"
                    value={formData.headCircumference}
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>

              <div className="relative">
                <Form.Item
                  name="bmi"
                  className='mb-8'
                >
                  {formData.bmi.length > 0 && (
                    <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 px-1" style={{ zIndex: 100 }}>
                      BMI (kg/m2)
                    </label>
                  )}
                  <Input
                    type="text"
                    name="bmi"
                    readOnly
                    placeholder="BMI (Kg/cm2)"
                    className="p-4 w-[300px] mr-2 rounded-lg"
                    value={formData.bmi}
                  />
                </Form.Item>
              </div>
            </div>
            <button className="mt-4 w-full bg-pinkColor text-white py-2 px-4 rounded-full">
              Calculate
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CaculatorGrowth;
