const weeksData = [
  {
    week: 1,
    image:  require("../image/40weekspregnant/1-3-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week1-2.png"),
    information: "You are not really pregnant yet.",
    description: {
     "height": null,
      "weight": null,
    },
    describe: null
  },
  {
    week: 2,
    image: require("../image/40weekspregnant/1-3-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week1-2.png"),
    information: "Ovulation is about to occur.",
    description: {
      "height": null,
      "weight": null,
    },
    describe: null
  },
  {
    week: 3,
    image: require("../image/40weekspregnant/1-3-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week3.png"),
    information: "Fertilization.",
    description: {
      "height": null,
      "weight": null,
    },
    describe: null
  },
  {
    week: 4,
    image: require("../image/40weekspregnant/4-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week4.png"),
    information: "Implantation of the embryo.",
    description: {
      "height": null,
      "weight": null,
    },
    describe: null
  },
  {
    week: 5,
    image: require("../image/40weekspregnant/5-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week5.png"),
    information: "Major developments this week include the nose, mouth, and ears. The baby’s oversized head and dark spots where the eyes and nostrils are beginning to form.",
    description: {
      "height": "0.1 cm",
      "weight": null,
    },
      describe: "Sesame seed"
  },
  {
    week: 6,
    image: require("../image/40weekspregnant/6-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week6.png"),
    information: "In the sixth week of pregnancy, the baby’s eyes, nose, mouth, and ears start to form, along with a tiny heart beating nearly twice as fast as the mother’s.",
    description: {
      "height": "0.2 cm",
      "weight": null,
    },
      describe: "Apple seed"
  },
  {
    week: 7,
    image: require("../image/40weekspregnant/7-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week7.png"),
    information: "The embryo is adapting to the uterus.",
    description: {
      "height": "1.3 cm",
      "weight": null,
    },
      describe: "Pea"
  },
  {
    week: 8,
    image: require("../image/40weekspregnant/8-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week8.png"),
    information: "Second prenatal visit.",
    description: {
      "height": "1.6 cm",
      "weight": null,
    },
      describe: "Blueberry"
  },
  {
    week: 9,
    image: require("../image/40weekspregnant/9-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week9.png"),
    information: "The baby begins to develop reproductive organs.",
    description: {
      "height": "2.3 cm",
      "weight": null,
    },
      describe: "Raspberry"
  },
  {
    week: 10,
    image: require("../image/40weekspregnant/10-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week10.png"),
    information: "Third prenatal visit.",
    description: {
      "height": "3.1 cm",
      "weight": null,
    },
      describe: "Cherry"
  },
  {
    week: 11,
    image: require("../image/40weekspregnant/11-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week11.png"),
    information: "The baby is still very small.",
    description: {
     "height": "4.1 cm",
      "weight": "0.007 kg",
    },
      describe: "Strawberry"
  },
  {
    week: 12,
    image: require("../image/40weekspregnant/12-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week12.png"),
    information: "End of the first trimester.",
    description: {
      "height": "5.4 cm",
      "weight": "0.014 kg",
    },
      describe: "Lime"
  },
  {
    week: 13,
    image: require("../image/40weekspregnant/13-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week13.png"),
    information: "Entering the second trimester.",
    description: {
      "height": "7.4 cm",
      "weight": "0.028 kg",
    },
      describe: "Plum"
  },
  {
    week: 14,
    image: require("../image/40weekspregnant/14-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week14.png"),
    information: "The baby is developing reproductive systems.",
    description: {
      "height": "8.7 cm",
      "weight": "0.057 kg",
    },
      describe: "Lemon"
  },
  {
    week: 15,
    image: require("../image/40weekspregnant/15-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week15.png"),
    information: "The baby is growing rapidly.",
    description: {
      "height": "10.1 cm",
      "weight": "0.071 kg",
    },
      describe: "Peach"
  },
  {
    week: 16,
    image: require("../image/40weekspregnant/16-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week16.png"),
    information: "First baby kick.",
    description: {
      "height": "11.6 cm",
      "weight": "0.1 kg",
    },
      describe: "Orange"
  },
  {
    week: 17,
    image: require("../image/40weekspregnant/17-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week17.png"),
    information: "The placenta is developing strongly.",
    description: {
      "height": "13 cm",
      "weight": "0.14 kg",
    },
      describe: "Avocado"
  },
  {
    week: 18,
    image: require("../image/40weekspregnant/18-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week18.png"),
    information: "The ears are beginning to take shape.",
    description: {
      "height": "14.2 cm",
      "weight": "0.14-0.18 kg",
    },
      describe: "Pomegranate"
  },
  {
    week: 19,
    image: require("../image/40weekspregnant/19-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week19.png"),
    information: "The kidneys start producing urine.",
    description: {
     "height": "15.3 cm",
      "weight": "0.23 kg",
    },
      describe: "Artichoke"
  },
  {
    week: 20,
    image: require("../image/40weekspregnant/20-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week20.png"),
    information: "The baby expels meconium.",
    description: {
     "height": "25.6 cm",
      "weight": "0.28 kg",
    },
      describe: "Mango"
  },
  {
    week: 21,
    image: require("../image/40weekspregnant/21-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week21.png"),
    information: "The baby moves significantly.",
    description: {
     "height": "26.7 cm",
      "weight": "0.31-0.35 kg",
    },
      describe: "Banana"
  },
  {
    week: 22,
    image: require("../image/40weekspregnant/22-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week22.png"),
    information: "The baby reacts to light.",
    description: {
      "height": "27.8 cm",
      "weight": "0.45 kg",
    },
      describe: "Endive"
  },
  {
    week: 23,
    image: require("../image/40weekspregnant/23-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week23.png"),
    information: "The baby can hear sounds from outside.",
    description: {
     "height": "28.9 cm",
      "weight": "0.54 kg",  
    },
      describe: "Coconut"
  },
  {
    week: 24,
    image: require("../image/40weekspregnant/24-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week24.png"),
    information: "Gestational diabetes test.",
    description: {
      "height": "30 cm",
      "weight": "0.59 kg",
    },
      describe: "Grapefruit"
  },
  {
    week: 25,
    image: require("../image/40weekspregnant/25-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week25.png"),
    information: "The baby is growing rapidly.",
    description: {
     "height": "34.6 cm",
      "weight": "0.68 kg",
    },
      describe: "Cantaloupe"
  },
  {
    week: 26,
    image: require("../image/40weekspregnant/26-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week26.png"),
    information: "The baby accumulates fat and builds muscle.",
    description: {
      "height": "35.6 cm",
      "weight": "0.77 kg",
    },
      describe: "Cauliflower"
  },
  {
    week: 27,
    image: require("../image/40weekspregnant/27-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week27.png"),
    information: "Entering the third trimester.",
    description: {
      "height": "36.6 cm",
      "weight": "0.86 kg",
    },
      describe: "Kale"
  },
  {
    week: 28,
    image: require("../image/40weekspregnant/28-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week28.png"),
    information: "Determine the baby's position.",
    description: {
      "height": "37.6 cm",
      "weight": "1 kg",
    },
      describe: "Napa cabbage"
  },
  {
    week: 29,
    image: require("../image/40weekspregnant/29-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week29.png"),
    information: "The baby's brain is developing rapidly.",
    description: {
     "height": "38.6 cm",
      "weight": "1.13 kg",
    },
      describe: "Eggplant"
  },
  {
    week: 30,
    image: require("../image/40weekspregnant/30-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week30.png"),
    information: "The baby is as big as a small watermelon.",
    description: {
     "height": "39.9 cm",
      "weight": "1.3 kg",
    },
      describe: "Butternut squash"
  },
  {
    week: 31,
    image: require("../image/40weekspregnant/31-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week31.png"),
    information: "The baby is urinating.",
    description: {
      "height": "41.1 cm",
      "weight": "1.5 kg",
    },
      describe: "Zucchini"
  },
  {
    week: 32,
    image: require("../image/40weekspregnant/32-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week32.png"),
    information: "The baby is almost fully developed.",
    description: {
      "height": "42.4 cm",
      "weight": "1.72 kg",
    },
      describe: "Asparagus"
  },
  {
    week: 33,
    image: require("../image/40weekspregnant/33-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week33.png"),
    information: "The baby is sleeping a lot.",
    description: {
     "height": "43.7 cm",
      "weight": "1.9 kg",
    },
      describe: "Winter melon"
  },
  {
    week: 34,
    image: require("../image/40weekspregnant/34-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week34.png"),
    information: "The baby is ready for birth position.",
    description: {
      "height": "45 cm",
      "weight": "2.13 kg",
    },
      describe: "Celery"
  },
  {
    week: 35,
    image: require("../image/40weekspregnant/35-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week35.png"),
    information: "The baby is becoming plumper.",
    description: {
      "height": "46.2 cm",
      "weight": "2.4 kg",
    },
      describe: "Kabocha squash"
  },
  {
    week: 36,
    image: require("../image/40weekspregnant/36-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week36.png"),
    information: "The baby is descending towards the uterus.",
    description: {
      "height": "47.4 cm",
      "weight": "2.63 kg",
    },
      describe: "Large pineapple"
  },
  {
    week: 37,
    image: require("../image/40weekspregnant/37-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week37.png"),
    information: "The baby is still gaining weight.",
    description: {
      "height": "48.6 cm",
      "weight": "2.85 kg",
    },
      describe: "Large papaya"
  },
  {
    week: 38, 
    image: require("../image/40weekspregnant/38-weeks.png"),
    imageSlide:  require("../image/weightofpregnant/Week38.png"),
    information: "The baby’s growth slows down.",
    description: {
      "height": "49.8 cm",
      "weight": "3.08 kg",
    },
      describe: "Romaine lettuce"
  }, 
  {
      week: 39, 
      image: require("../image/40weekspregnant/39-weeks.png"),
      imageSlide:  require("../image/weightofpregnant/Week39.png"),
      information: "No significant changes.",
      description: {
        "height": "50.7 cm",
      "weight": "3.3 kg",
      },
      describe: "Mature winter melon"
  }, 
  {
      week: 40, 
      image: require("../image/40weekspregnant/40-weeks.png"),
      imageSlide:  require("../image/weightofpregnant/Week40.png"),
      information: "The baby is ready to be born.",
      description: {
       "height": "51.2 cm",
      "weight": "3.44 kg",
      },
      describe: "Large pumpkin"
  }, 
  {
      week: 41, 
      image: require("../image/40weekspregnant/41-weeks.png"),
      imageSlide:  require("../image/weightofpregnant/Week41.png"),
      information: "The baby is ready to be born.",
      description: {
        "height": "52.4 cm",
      "weight": "3.64 kg",
      },
      describe: "Large pumpkin"
    }

]
  
export default weeksData;
