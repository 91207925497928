import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Link } from 'react-router-dom'

export const Login = () => {
    return (
        <>
            <Header />
            <div className='flex items-center justify-center mt-32'>
                <div className='container mx-auto px-7 w-full max-w-md'>
                    <div className='text-center space-y-4'>
                        <h4 className='text-4xl font-semibold mb-4'>Sign In</h4>
                        <p className='text-sm'>Enter your email or number phone to sign in</p>

                        <div className='space-y-3'>
                            <input
                                className='w-full p-3 border border-gray-300 rounded-lg'
                                type="text"
                                placeholder='Email or Number phone'
                            />
                            <input
                                className='w-full p-3 border border-gray-300 rounded-lg'
                                type="password"
                                placeholder='Password'
                            />
                            <button className='bg-pinkColor py-3 w-full text-white font-bold rounded-lg' type='button'>
                                Sign In
                            </button>
                        </div>

                        <div className='relative mt-6'>
                            <div className='flex items-center justify-center'>
                                <hr className='w-full border-gray-300' />
                                <span className='absolute bg-white px-2 text-gray-500 text-sm'>
                                    or continue with
                                </span>
                            </div>
                        </div>

                        <button className='relative bg-gray-100 py-2 w-full text-black font-bold rounded-lg flex items-center justify-center mt-4' type='button'>
                            {/* <img src='../assets/image/social/google.svg' alt='Google' className='w-5 h-5 mr-2' /> */}
                            <div className='absolute mr-[90%]'>
                                <svg className='w-5 h-5' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" 
                                height="48px"><path fill="#FFC107" 
                                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/></svg>
                            </div>
                            <span>Google</span>
                        </button>

                        <p className='text-sm mt-6'>
                            You do not have an account, <Link className='text-blue-500 font-bold' to="/register">Sign up!</Link>
                        </p>
                        <p className='text-sm'>
                            Or you <Link className='text-black font-bold' to="#">forgot your password?</Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
