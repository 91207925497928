// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
    appearance: none;
    height: 12px;
    background: #ddd;
    border-radius: 5px;
    outline: none;
}


.slider::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: #06ADEF;
    cursor: pointer;
    border-radius: 50%;
}


.slider::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #06ADEF 0%, #06ADEF var(--value), #ddd var(--value), #ddd 100%);
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #06ADEF;
    cursor: pointer;
    border-radius: 50%;

}

.slider::-ms-thumb {
    width: 20px;
    height: 20px;
    background: #06ADEF;
    cursor: pointer;
    border-radius: 50%;
}

.slider::-ms-fill-lower {
    background: #06ADEF;
}

.btn-hover-no-change {
    background-color: #ff3259;
    border: none;
    color: white;
}

.btn-hover-no-change:hover {
    background-color: #ce2947 !important;
    color: white !important;
}

.ant-slider-track{
    height: 10px !important;
    top: 1px;
    background-color: #06adef !important;
    border-radius: 50%;
}

.ant-slider-handle::after{
    box-shadow:0 0 0 2px #06adef !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Tool/WeightOfPregnant/style.css"],"names":[],"mappings":"AAAA;IAEI,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;AACjB;;;AAGA;IAEI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;;AAGA;IACI,qGAAqG;AACzG;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,kBAAkB;;AAEtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,QAAQ;IACR,oCAAoC;IACpC,kBAAkB;AACtB;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".slider {\n    -webkit-appearance: none;\n    appearance: none;\n    height: 12px;\n    background: #ddd;\n    border-radius: 5px;\n    outline: none;\n}\n\n\n.slider::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    appearance: none;\n    width: 20px;\n    height: 20px;\n    background: #06ADEF;\n    cursor: pointer;\n    border-radius: 50%;\n}\n\n\n.slider::-webkit-slider-runnable-track {\n    background: linear-gradient(to right, #06ADEF 0%, #06ADEF var(--value), #ddd var(--value), #ddd 100%);\n}\n\n.slider::-moz-range-thumb {\n    width: 20px;\n    height: 20px;\n    background: #06ADEF;\n    cursor: pointer;\n    border-radius: 50%;\n\n}\n\n.slider::-ms-thumb {\n    width: 20px;\n    height: 20px;\n    background: #06ADEF;\n    cursor: pointer;\n    border-radius: 50%;\n}\n\n.slider::-ms-fill-lower {\n    background: #06ADEF;\n}\n\n.btn-hover-no-change {\n    background-color: #ff3259;\n    border: none;\n    color: white;\n}\n\n.btn-hover-no-change:hover {\n    background-color: #ce2947 !important;\n    color: white !important;\n}\n\n.ant-slider-track{\n    height: 10px !important;\n    top: 1px;\n    background-color: #06adef !important;\n    border-radius: 50%;\n}\n\n.ant-slider-handle::after{\n    box-shadow:0 0 0 2px #06adef !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
