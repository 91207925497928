import React from 'react';
import { FaCalendarAlt, FaClipboard, FaLock, FaSignOutAlt, FaUserEdit, FaUserSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const DashboardSidebar = () => {
  return (
    <div className="p-5">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full lg:w-[250px] space-y-5">
        <div className="flex items-center space-x-2">
          <FaUserEdit className="w-6 h-6" />
          <Link to='/profile' className="text-lg">Profile</Link>
        </div>
        <div className="flex items-center space-x-2">
          <FaClipboard className="w-6 h-6" />
          <Link className="text-lg">Manage health</Link>
        </div>
        <div className="flex items-center space-x-2">
          <FaLock className="w-6 h-6" />
          <Link to='/change-pass' className="text-lg">Change password</Link>
        </div>
        <div className="flex items-center space-x-2">
          <FaCalendarAlt className="w-6 h-6" />
          <Link to='/schedule' className="text-lg">Schedule</Link>
        </div>
        <div className="flex items-center space-x-2">
          <FaUserSlash className="w-6 h-6" />
          <Link to='/account' className="text-lg">Disable account</Link>
        </div>
        <div className="flex items-center space-x-2">
          <FaSignOutAlt className="w-6 h-6" />
          <Link className="text-lg">Logout</Link>
        </div>
      </div>
    </div>
  );
}


