import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import './style.css'

const ConfirmNursing = () => {
  const navigate = useNavigate();
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const frontInputRef = useRef(null);
  const backInputRef = useRef(null);
  const [frontBlob, setFrontBlob] = useState('');
  const [backBlob, setBackBlob] = useState('');
  const [isDragEnter, setIsDragEnter] = useState(false);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (frontFile) {
      setFrontBlob(URL.createObjectURL(frontFile));
    }
    if (backFile) {
      setBackBlob(URL.createObjectURL(backFile));
    }

    return () => {
      URL.revokeObjectURL(frontBlob);
      URL.revokeObjectURL(backBlob);
    };
  }, [frontFile, backFile]);

  const onFrontFileChange = (e) => {
    const newFile = e.target.files?.[0];
    if (newFile && newFile.type.match("image.*")) {
      frontInputRef.current && (frontInputRef.current.value = null); // Reset the file input
      setFrontFile(newFile);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const onBackFileChange = (e) => {
    const newFile = e.target.files?.[0];
    if (newFile && newFile.type.match("image.*")) {
      backInputRef.current && (backInputRef.current.value = null); // Reset the file input
      setBackFile(newFile);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const onDragLeave = () => {
    setIsDragEnter(false);
  };

  const onDragEnter = () => {
    setIsDragEnter(true);
  };

  const onDrop = (e, setFileFunc) => {
    setIsDragEnter(false);
    const newFile = e.dataTransfer.files?.[0];
    if (newFile && newFile.type.match("image.*")) {
      setFileFunc(newFile);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  return (
    <>
      <Header />
      <Link className="btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
        <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
        Previous
      </Link>
      <div className="flex items-center justify-center p-8">
        <div className="container mx-auto w-full lg:px-[300px] md:px-[80px]">
          <div className="text-center space-y-2">
            <h4 className="text-4xl font-semibold mb-4">Detailed information for mother after nursing</h4>
            <p className="text-sm">Upload your ID card</p>

            <div className="flex space-x-3">
              <div
                onDrop={(e) => onDrop(e, setFrontFile)}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                style={{ "--bg": `url(${frontBlob})` }}
                onClick={() => frontInputRef.current && frontInputRef.current.click()}
                className={` ${frontBlob ? "before-bg-file" : ""} relative p-6 cursor-pointer h-[200px] w-[570px] mx-auto mt-10 border-2 border-dashed border-blueColor flex flex-col items-center text-base leading-[1.6] select-none`}
              >
                <input ref={frontInputRef} onChange={onFrontFileChange} type="file" accept="image/*" hidden />
                <p className="text-center my-3 pointer-events-none">Front of ID Card*:</p>
                <p className="text-center text-pinkColor pointer-events-none">
                {isDragEnter ? "Drop photos here" : "Drag and drop photos here, or click to select photos"}
                </p>
              </div>

              <div
                onDrop={(e) => onDrop(e, setBackFile)}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                style={{ "--bg": `url(${backBlob})` }}
                onClick={() => backInputRef.current && backInputRef.current.click()}
                className={`${frontBlob ? "before-bg-file" : ""} relative p-6 cursor-pointer h-[200px] w-[570px] mx-auto mt-10 border-2 border-dashed border-blueColor flex flex-col items-center text-base leading-[1.6] select-none`}
              >
                <input ref={backInputRef} onChange={onBackFileChange} type="file" accept="image/*" hidden />
                <p className="text-center my-3 pointer-events-none">Back of ID Card*:</p>
                <p className="text-center text-pinkColor pointer-events-none">
                  {isDragEnter ? "Drop photos here" : "Drag and drop photos here, or click to select photos"}
                </p>
              </div>
            </div>

            <button className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg" style={{marginTop:'25px'}} type="button">
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmNursing;
