
import React from "react";
import { CSSTransition } from "react-transition-group";
import './style.css';

const CustomPanel = ({ header, content, isOpen, onToggle }) => {
  return (
    <div className="custom-panel">
      <div className="custom-panel-header" onClick={onToggle}>
        {header}
        <span className="toggle-icon">{isOpen ? '-' : '+'}</span>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="custom-panel-content"
        unmountOnExit
      >
        <div className="custom-panel-content">{content}</div>
      </CSSTransition>
    </div>
  );
};

export default CustomPanel;
