// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* note bmi */
.popup-box {
    position: relative;
  }
  
  .popup-box::before {
    content: "";
    position: absolute;
    right: 40px; /* Position to the right */
    top: -14px; /* Position above the popup */
    border-width: 0 15px 15px 15px; /* Triangle dimensions */
    border-style: solid;
    border-color: transparent transparent white transparent; /* Triangle color */
  }

  /* Home mom */
 
.custom-switch .ant-switch {
  width: 85px !important;
  height: 33px !important;
  background-color: #06ADEF !important;
  border: 4px solid #fff !important;
}
.custom-switch {
  width: 80px !important;
  height: 34px !important;
}

.custom-switch .ant-switch-checked {
  background-color: #FF3259 !important;
  border: 4px solid #fff !important;
}


.custom-switch .ant-switch-checked .ant-switch-handle {
  width: 19px !important;
  height: 19px !important;
}
:where(.css-dev-only-do-not-override-14i19y2).ant-switch .ant-switch-handle::before {
  /* border-radius: 50px !important;
  padding-bottom: 20px; */
  background-color: #fff;
  
}


`, "",{"version":3,"sources":["webpack://./src/components/CalculatorBMI/style.css"],"names":[],"mappings":"AAAA,aAAa;AACb;IACI,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,WAAW,EAAE,0BAA0B;IACvC,UAAU,EAAE,6BAA6B;IACzC,8BAA8B,EAAE,wBAAwB;IACxD,mBAAmB;IACnB,uDAAuD,EAAE,mBAAmB;EAC9E;;EAEA,aAAa;;AAEf;EACE,sBAAsB;EACtB,uBAAuB;EACvB,oCAAoC;EACpC,iCAAiC;AACnC;AACA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,iCAAiC;AACnC;;;AAGA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE;yBACuB;EACvB,sBAAsB;;AAExB","sourcesContent":["/* note bmi */\n.popup-box {\n    position: relative;\n  }\n  \n  .popup-box::before {\n    content: \"\";\n    position: absolute;\n    right: 40px; /* Position to the right */\n    top: -14px; /* Position above the popup */\n    border-width: 0 15px 15px 15px; /* Triangle dimensions */\n    border-style: solid;\n    border-color: transparent transparent white transparent; /* Triangle color */\n  }\n\n  /* Home mom */\n \n.custom-switch .ant-switch {\n  width: 85px !important;\n  height: 33px !important;\n  background-color: #06ADEF !important;\n  border: 4px solid #fff !important;\n}\n.custom-switch {\n  width: 80px !important;\n  height: 34px !important;\n}\n\n.custom-switch .ant-switch-checked {\n  background-color: #FF3259 !important;\n  border: 4px solid #fff !important;\n}\n\n\n.custom-switch .ant-switch-checked .ant-switch-handle {\n  width: 19px !important;\n  height: 19px !important;\n}\n:where(.css-dev-only-do-not-override-14i19y2).ant-switch .ant-switch-handle::before {\n  /* border-radius: 50px !important;\n  padding-bottom: 20px; */\n  background-color: #fff;\n  \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
