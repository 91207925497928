import React from 'react'
import Header from '../../components/Header/Header'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const PregnantMothersRegister = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Header />
      <Link className="btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
        <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
        Previous
      </Link>


      <div className="flex items-center justify-center p-8">
        <div className="container mx-auto w-full lg:px-[300px] md:px-[80px]">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Detailed information for pregnant mother</h4>
            <p className="text-sm">
              Enter your information
            </p>
            <form className="space-y-3">
              <div className=' space-x-3'>
                <input
                  disabled
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  type="text"
                  placeholder="Full Name*: "
                />
              </div>

              <div className='flex space-x-3'>
                <input
                  disabled
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  type="email"
                  placeholder="Email*: "
                />
                <input
                  disabled
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  type="text"
                  placeholder="Number Phone*:"
                />
              </div>

              <div className='flex space-x-3'>
                <input
                  disabled
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  type="date"
                  placeholder="Date Of Birth*: "
                />
                <input
                  disabled
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  type="number"
                  placeholder="Age*:"
                />
              </div>

              <input
                className="w-full p-3 border border-gray-300 rounded-lg"
                type="text"
                placeholder="Address current*:"
              />

              <input
                className="w-full p-3 border border-gray-300 rounded-lg"
                type="text"
                placeholder="Gestational Age:"
              />

              <input
                className="w-full p-3 border border-gray-300 rounded-lg"
                type="text"
                placeholder="Estimated Due Date:"
              />

              <div className='flex space-x-3'>
                <input
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  type="text"
                  placeholder="Current weight*: "
                />
                <input
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  type="text"
                  placeholder="Current height*:"
                />
              </div>

              <button
                className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                type="button"
              >
                Sign up
              </button>
            </form>


          </div>
        </div>
      </div>

    </>
  )
}

export default PregnantMothersRegister
