import React from 'react'
import { AiOutlineClose } from "react-icons/ai";

const CalculateBMI = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  const handleClickOutside = () => {
      onClose();
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 popup-overlay">
      <div className="w-[800px] h-[230px] text-center rounded-lg relative bg-primaryColor">
      <AiOutlineClose onClick={handleClickOutside} className='ml-auto text-[25px] mr-2 mt-2' style={{cursor:'pointer'}} />
      <h2 className="text-[25px] text-black font-bold pb-3">Calculate BMI</h2>
        <div className='flex gap-4 items-center justify-center p-2 mx-8'>
          <input text="number" placeholder='Mom height*:' className='p-2 border border-gray rounded-lg  w-full'/>
          <input text="number" placeholder='Mom weight*:' className='p-2 border border-gray rounded-lg  w-full'/>
        </div>
        <button className='bg-pinkColor px-14 mt-4 py-2 rounded-lg text-[18px] text-white'>Calculate</button>
      </div>
    </div>

  )
}

export default CalculateBMI