import React, { useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Link, useLocation } from "react-router-dom";
import { BoyIcon, GirlIcon } from "../../../assets/image/gender/gender";
import dayjs from "dayjs";
import { boyWFA, girlWFA } from "../../../assets/data/WeightForAge";
import { boyHFA, girlHFA } from "../../../assets/data/HeightForAge";
import { boyHC, girlHC } from "../../../assets/data/HeadCircumference";
import { boyBMI, girlBMI } from "../../../assets/data/BMIhealthChild";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ReloadOutlined } from "@ant-design/icons";
import dataPanel from "../../../assets/data/Panel/DataPanel";
import CustomPanel from "../CustomPanel/CustomPanel";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const ChildrenGrowthPageResult = () => {
    const [openPanelIndex, setOpenPanelIndex] = useState(null);
    const information = useLocation();
    const [tabTarget, setTabTarget] = useState("Weight (Kg)")
    const { state } = information

    const calculateAge = (dob) => {
        const birthDate = dayjs(dob);
        const today = dayjs();

        let unit = " Weeks old"
        let age = today.diff(birthDate, 'week');

        if (age > 5) {
            age = today.diff(birthDate, 'month')
            unit = " Months old"
        }

        if (age > 12) {
            age = today.diff(birthDate, 'year');
            unit = " Years old"
        }

        return {
            acceptOld: age + unit,
            monthsYO: today.diff(birthDate, 'month')
        };
    };

    const handleChangeType = (tab) => {
        const dataMapping = {
            boy: {
                Weight: boyWFA,
                Height: boyHFA,
                HC: boyHC,
                BMI: boyBMI,
            },
            girl: {
                Weight: girlWFA,
                Height: girlHFA,
                HC: girlHC,
                BMI: girlBMI,
            },
            UserState: {
                Weight: state.weight,
                Height: state.height,
                HC: state.headCircumference,
                BMI: state.bmi,
            }
        };

        const data = dataMapping[state.gender]?.[tab];

        return {
            labels: data.Month || [],
            datasets: [
                { label: "97th", data: data.P97 || [], borderColor: "#E32340", fill: false },
                { label: "85th", data: data.P85 || [], borderColor: "#3E9E6B", fill: false },
                { label: "50th", data: data.P50 || [], borderColor: "#F37524", fill: false },
                { label: "15th", data: data.P15 || [], borderColor: "#3E9E6B", fill: false },
                { label: "3rd", data: data.P3 || [], borderColor: "#E32340", fill: false },
                {
                    label: 'Comparison Point',
                    data: Array(data.Month.length).fill(null).map((_, index) => {
                        const monIndex = dayjs().diff(state.Dob, 'month') > 0 ? dayjs().diff(state.Dob, 'month') - 1 : 0
                        return (
                            index === monIndex
                                ? dataMapping["UserState"]?.[tab]
                                : null)
                    }
                    ),
                    borderColor: '#ff3259',
                    backgroundColor: '#ff3259',
                    pointRadius: 10,
                    pointHoverRadius: 8,
                    fill: false,
                    type: 'scatter',
                }
            ],
        };
    };

    const [chartData, setChartData] = useState(handleChangeType("Weight"))

    const handleChangeTab = (tab) => {
        const tabSplit = tab.split(" ")[0]
        setTabTarget(tab)
        setChartData(handleChangeType(tabSplit))
    }

    const options = {
        responsive: true,
        plugins: {
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Months',
                    font: {
                        size: 16,
                    },
                    color: '#333',
                },
            },
        },
    };

    return (<>
        <Header />
        <div className='container mx-auto'>
            <div className="d-flex">
                <div className="h-[140px] d-flex bg-primaryColor rounded-bl-[300px] rounded-br-[300px] border p-4 justify-center items-center" style={{ display: "flex" }}>
                    <div className="mt-5 mr-5 space-y-6" style={{ maxWidth: "60rem" }}>
                        <h4 className="text-[28px] text-center font-bold">
                            WHO - Growth chart for <span class='text-pinkColor font-bold'>{state.nameBaby}</span>
                        </h4>
                        <p className="text-lg text-center mx-auto w-full max-w-[680px] text-gray-600">
                            Check your baby's health monthly to monitor specific changes in your baby.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div className="w-[50rem] container ml-[17rem] p-4">
            <div className="border items-center p-4 rounded-lg shadow-md" style={{ display: "flex" }}>
                <div className="w-16 h-16 bg-pink-300 rounded-full flex items-center justify-center">
                    {state.gender === 'boy' ? BoyIcon : GirlIcon}
                </div>

                <div className="flex-grow w-[50%] ml-5">
                    <h2 className="text-xl font-semibold w-100">{state.nameBaby}</h2>
                    <p className="text-gray-500 w-100">
                        {state.gender === 'boy' ? "♂ Boy" : "♀ Girl"} - {calculateAge(state.Dob).acceptOld}
                    </p>
                </div>

                <Link to='/tool/growth-child' className='pr-2'>
                    <div className="flex items-center justifyContent-center bg-pinkColor text-white p-3 rounded-full">
                        <ReloadOutlined className="mr-2" />Recalculate
                    </div>
                </Link>
            </div>

            {/* Metrics Section */}
            <div className="mt-4 flex justify-around">
                {["Weight (Kg)", "Height (cm)", "HC (cm)", "BMI (Kg/cm2)"].map((tab) => (
                    <span
                        key={tab}
                        onClick={() => handleChangeTab(tab)}
                        className={`${tabTarget.split(" ")[0] === tab.split(" ")[0] ? "text-pink-500 font-bold" : "text-gray-900"} cursor-pointer`}
                    >
                        {tab.split(" ")[0] === "HC" ? "Head circumference" : tab.split(" ")[0]}
                    </span>
                ))}
            </div>

            <p className="absolute" style={{ left: "15rem" }}>{tabTarget === "HC" ? "Head circumference" : tabTarget}</p>
        </div>

        <div className="lg:px-[100px] md:px-[100px] grid grid-cols-10 lg:grid-cols-10 container mx-auto mb-5">
            <div className="col-span-8">
                <Line className="mt-2 w-[100%]" data={chartData} options={options} />
            </div>
            <div className="col-span-2">
                <div className='flex items-center space-x-3'>
                    <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                        <AiOutlineExclamationCircle className="text-[40px]" />
                    </div>
                    <h5>Disclaimer</h5>
                </div>
                <div className=" mt-4">
                    <div className="flex items-center mr-4">
                        <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: "#E32340" }}></div>
                        <span>3rd / 97th</span>
                    </div>
                    <div className="flex items-center mr-4">
                        <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: "#F37524" }}></div>
                        <span>15th / 85th</span>
                    </div>
                    <div className="flex items-center mr-4">
                        <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: "#3E9E6B" }}></div>
                        <span>50th</span>
                    </div>
                </div>
                <p className="font-bold mt-3">
                    The 97th percentile means that out of 100 children the same age as your child, 97 are shorter or equal to your child's height, and only 3 are taller than your child.
                </p>
                <p className="font-bold mt-3">
                    A percentile of 0-3 means that out of 100 children the same age as your child, only 3 weigh less than or equal to your child's weight, and 97 weigh more than your child.
                </p>
            </div>
        </div>
        <div className='space-y-4 container mx-auto'>
            <div className='flex space-x-3'>
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                    <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <h5>Information</h5>
            </div>

            <div>
                {dataPanel.childrenGrowth.result.map((panel, index) => (
                    <CustomPanel
                        key={index}
                        isOpen={openPanelIndex === index}
                        onToggle={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}
                        header={panel.header}
                        content={panel.content}
                    />
                ))}
            </div>
        </div>
        <Footer />
    </>)
}

export default ChildrenGrowthPageResult