export const boyBMI = {
    Month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
    P1: [10.8, 12, 13.3, 13.9, 14.1, 14.3, 14.4, 14.4, 14.4, 14.3, 14.2, 14.1, 14, 13.9, 13.9, 13.8, 13.7, 13.6, 13.6, 13.5, 13.4, 13.4, 13.3, 13.3, 13.3, 13.5, 13.4, 13.4, 13.3, 13.3, 13.3, 13.2, 13.2, 13.1, 13.1, 13.1, 13, 13, 13, 12.9, 12.9, 12.9, 12.9, 12.8, 12.8, 12.8, 12.8, 12.8, 12.7, 12.7, 12.7, 12.7, 12.7, 12.7, 12.6, 12.6, 12.6, 12.6, 12.6, 12.6, 12.6],
    P3: [11.3, 12.6, 13.8, 14.4, 14.7, 14.8, 14.9, 14.9, 14.9, 14.8, 14.7, 14.6, 14.5, 14.4, 14.3, 14.2, 14.2, 14.1, 14, 13.9, 13.9, 13.8, 13.8, 13.7, 13.7, 13.9, 13.8, 13.8, 13.8, 13.7, 13.7, 13.7, 13.6, 13.6, 13.5, 13.5, 13.5, 13.5, 13.4, 13.4, 13.4, 13.3, 13.3, 13.3, 13.3, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13, 13, 13, 13],
    P5: [11.5, 12.8, 14.1, 14.7, 15, 15.1, 15.2, 15.2, 15.1, 15.1, 15, 14.9, 14.8, 14.7, 14.6, 14.5, 14.4, 14.3, 14.2, 14.2, 14.1, 14.1, 14, 14, 13.9, 14.1, 14.1, 14, 14, 14, 13.9, 13.9, 13.9, 13.8, 13.8, 13.8, 13.7, 13.7, 13.7, 13.6, 13.6, 13.6, 13.6, 13.5, 13.5, 13.5, 13.5, 13.5, 13.4, 13.4, 13.4, 13.4, 13.4, 13.3, 13.3, 13.3, 13.3, 13.3, 13.3, 13.3, 13.3],
    P10: [11.9, 13.3, 14.6, 15.2, 15.4, 15.6, 15.6, 15.6, 15.6, 15.5, 15.4, 15.3, 15.2, 15.1, 15, 14.9, 14.8, 14.7, 14.6, 14.6, 14.5, 14.4, 14.4, 14.3, 14.3, 14.5, 14.5, 14.4, 14.4, 14.4, 14.3, 14.3, 14.2, 14.2, 14.2, 14.1, 14.1, 14.1, 14.1, 14, 14, 14, 13.9, 13.9, 13.9, 13.9, 13.9, 13.8, 13.8, 13.8, 13.8, 13.8, 13.8, 13.7, 13.7, 13.7, 13.7, 13.7, 13.7, 13.7, 13.6],
    P15: [12.2, 13.6, 14.9, 15.5, 15.7, 15.9, 15.9, 15.9, 15.9, 15.8, 15.7, 15.6, 15.5, 15.4, 15.3, 15.2, 15.1, 15, 14.9, 14.8, 14.8, 14.7, 14.6, 14.6, 14.5, 14.8, 14.7, 14.7, 14.7, 14.6, 14.6, 14.5, 14.5, 14.5, 14.4, 14.4, 14.4, 14.4, 14.3, 14.3, 14.3, 14.2, 14.2, 14.2, 14.2, 14.2, 14.1, 14.1, 14.1, 14.1, 14.1, 14, 14, 14, 14, 14, 14, 14, 13.9, 13.9, 13.9],
    P25: [12.6, 14.1, 15.4, 16, 16.2, 16.4, 16.4, 16.4, 16.3, 16.3, 16.2, 16, 15.9, 15.8, 15.7, 15.6, 15.5, 15.4, 15.3, 15.2, 15.2, 15.1, 15, 15, 14.9, 15.2, 15.1, 15.1, 15.1, 15, 15, 15, 14.9, 14.9, 14.9, 14.8, 14.8, 14.8, 14.7, 14.7, 14.7, 14.7, 14.6, 14.6, 14.6, 14.6, 14.5, 14.5, 14.5, 14.5, 14.5, 14.5, 14.4, 14.4, 14.4, 14.4, 14.4, 14.4, 14.4, 14.4, 14.3],
    P50: [13.4, 14.9, 16.3, 16.9, 17.2, 17.3, 17.3, 17.3, 17.3, 17.2, 17, 16.9, 16.8, 16.7, 16.6, 16.4, 16.3, 16.2, 16.1, 16.1, 16, 15.9, 15.8, 15.8, 15.7, 16, 15.9, 15.9, 15.9, 15.8, 15.8, 15.8, 15.7, 15.7, 15.7, 15.6, 15.6, 15.6, 15.5, 15.5, 15.5, 15.5, 15.4, 15.4, 15.4, 15.4, 15.4, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.2, 15.2, 15.2, 15.2, 15.2, 15.2],
    P75: [14.3, 15.9, 17.3, 17.9, 18.2, 18.3, 18.3, 18.3, 18.2, 18.1, 18, 17.9, 17.7, 17.6, 17.5, 17.4, 17.2, 17.1, 17, 16.9, 16.9, 16.8, 16.7, 16.7, 16.6, 16.9, 16.8, 16.8, 16.7, 16.7, 16.7, 16.6, 16.6, 16.6, 16.5, 16.5, 16.5, 16.4, 16.4, 16.4, 16.4, 16.3, 16.3, 16.3, 16.3, 16.3, 16.2, 16.2, 16.2, 16.2, 16.2, 16.2, 16.2, 16.2, 16.2, 16.2, 16.1, 16.1, 16.1, 16.1, 16.1],
    P85: [14.8, 16.4, 17.8, 18.5, 18.7, 18.9, 18.9, 18.9, 18.8, 18.7, 18.6, 18.4, 18.3, 18.1, 18, 17.9, 17.8, 17.6, 17.5, 17.4, 17.4, 17.3, 17.2, 17.1, 17.1, 17.4, 17.3, 17.3, 17.2, 17.2, 17.2, 17.1, 17.1, 17, 17, 17, 17, 16.9, 16.9, 16.9, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7],
    P90: [15.2, 16.7, 18.2, 18.8, 19.1, 19.2, 19.3, 19.3, 19.2, 19.1, 18.9, 18.8, 18.7, 18.5, 18.4, 18.2, 18.1, 18, 17.9, 17.8, 17.7, 17.6, 17.6, 17.5, 17.4, 17.7, 17.7, 17.6, 17.6, 17.5, 17.5, 17.5, 17.4, 17.4, 17.4, 17.3, 17.3, 17.3, 17.2, 17.2, 17.2, 17.2, 17.1, 17.1, 17.1, 17.1, 17.1, 17.1, 17.1, 17.1, 17.1, 17.1, 17.1, 17.1, 17, 17, 17, 17.1, 17.1, 17.1, 17.1],
    P95: [15.8, 17.3, 18.8, 19.4, 19.7, 19.8, 19.9, 19.9, 19.8, 19.7, 19.5, 19.4, 19.2, 19.1, 18.9, 18.8, 18.7, 18.6, 18.5, 18.4, 18.3, 18.2, 18.1, 18, 18, 18.3, 18.2, 18.2, 18.1, 18.1, 18, 18, 18, 17.9, 17.9, 17.9, 17.8, 17.8, 17.8, 17.7, 17.7, 17.7, 17.7, 17.7, 17.7, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.6, 17.7, 17.7],
    P97: [16.1, 17.6, 19.2, 19.8, 20.1, 20.2, 20.3, 20.3, 20.2, 20.1, 19.9, 19.8, 19.6, 19.5, 19.3, 19.2, 19.1, 18.9, 18.8, 18.7, 18.6, 18.6, 18.5, 18.4, 18.3, 18.6, 18.6, 18.5, 18.5, 18.4, 18.4, 18.4, 18.3, 18.3, 18.2, 18.2, 18.2, 18.1, 18.1, 18.1, 18.1, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18.1, 18.1],
    P99: [16.9, 18.3, 19.9, 20.6, 20.9, 21, 21.1, 21.1, 21, 20.8, 20.7, 20.5, 20.4, 20.2, 20.1, 19.9, 19.8, 19.7, 19.6, 19.5, 19.4, 19.3, 19.2, 19.1, 19.1, 19.4, 19.3, 19.2, 19.2, 19.1, 19.1, 19.1, 19, 19, 18.9, 18.9, 18.9, 18.8, 18.8, 18.8, 18.8, 18.7, 18.7, 18.7, 18.7, 18.7, 18.7, 18.7, 18.7, 18.7, 18.7, 18.7, 18.7, 18.7, 18.8, 18.8, 18.8, 18.8, 18.8, 18.9, 18.9],
    P999: [18.3, 19.6, 21.3, 22, 22.3, 22.4, 22.5, 22.5, 22.4, 22.3, 22.1, 22, 21.8, 21.6, 21.5, 21.3, 21.2, 21.1, 21, 20.8, 20.7, 20.6, 20.6, 20.5, 20.4, 20.7, 20.6, 20.6, 20.5, 20.5, 20.4, 20.3, 20.3, 20.2, 20.2, 20.2, 20.1, 20.1, 20.1, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20.1, 20.1, 20.1, 20.1, 20.2, 20.2, 20.3, 20.3, 20.3, 20.4, 20.5]
}

export const girlBMI = {
    Month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
    P1: [10.8, 11.6, 12.6, 13.2, 13.5, 13.7, 13.7, 13.8, 13.7, 13.7, 13.6, 13.5, 13.4, 13.3, 13.3, 13.2, 13.1, 13, 13, 12.9, 12.9, 12.8, 12.8, 12.8, 12.8, 13, 13, 13, 12.9, 12.9, 12.9, 12.9, 12.8, 12.8, 12.8, 12.8, 12.8, 12.7, 12.7, 12.7, 12.7, 12.6, 12.6, 12.6, 12.6, 12.5, 12.5, 12.5, 12.5, 12.5, 12.4, 12.4, 12.4, 12.4, 12.4, 12.4, 12.4, 12.4, 12.3, 12.3, 12.3],
    P3: [11.2, 12.1, 13.2, 13.7, 14, 14.2, 14.3, 14.3, 14.3, 14.2, 14.1, 14, 13.9, 13.8, 13.7, 13.7, 13.6, 13.5, 13.4, 13.4, 13.3, 13.3, 13.3, 13.2, 13.2, 13.4, 13.4, 13.4, 13.4, 13.4, 13.3, 13.3, 13.3, 13.3, 13.2, 13.2, 13.2, 13.2, 13.2, 13.1, 13.1, 13.1, 13.1, 13, 13, 13, 13, 13, 12.9, 12.9, 12.9, 12.9, 12.9, 12.9, 12.9, 12.9, 12.8, 12.8, 12.8, 12.8, 12.8],
    P5: [11.5, 12.4, 13.5, 14, 14.3, 14.5, 14.6, 14.6, 14.6, 14.5, 14.4, 14.3, 14.2, 14.1, 14, 13.9, 13.8, 13.8, 13.7, 13.6, 13.6, 13.6, 13.5, 13.5, 13.5, 13.7, 13.7, 13.7, 13.6, 13.6, 13.6, 13.6, 13.5, 13.5, 13.5, 13.5, 13.5, 13.4, 13.4, 13.4, 13.4, 13.3, 13.3, 13.3, 13.3, 13.3, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1],
    P10: [11.8, 12.9, 14, 14.5, 14.8, 15, 15.1, 15.1, 15, 15, 14.9, 14.8, 14.6, 14.5, 14.4, 14.3, 14.3, 14.2, 14.1, 14.1, 14, 14, 13.9, 13.9, 13.9, 14.1, 14.1, 14, 14, 14, 14, 14, 13.9, 13.9, 13.9, 13.9, 13.8, 13.8, 13.8, 13.8, 13.8, 13.7, 13.7, 13.7, 13.7, 13.7, 13.7, 13.6, 13.6, 13.6, 13.6, 13.6, 13.6, 13.6, 13.6, 13.5, 13.5, 13.5, 13.5, 13.5, 13.5],
    P15: [12.1, 13.2, 14.3, 14.9, 15.2, 15.3, 15.4, 15.4, 15.4, 15.3, 15.2, 15.1, 15, 14.8, 14.7, 14.6, 14.6, 14.5, 14.4, 14.3, 14.3, 14.2, 14.2, 14.2, 14.1, 14.4, 14.4, 14.3, 14.3, 14.3, 14.3, 14.2, 14.2, 14.2, 14.2, 14.1, 14.1, 14.1, 14.1, 14.1, 14, 14, 14, 14, 14, 14, 13.9, 13.9, 13.9, 13.9, 13.9, 13.9, 13.9, 13.9, 13.9, 13.9, 13.8, 13.8, 13.8, 13.8, 13.8],
    P25: [12.5, 13.6, 14.8, 15.4, 15.7, 15.8, 15.9, 15.9, 15.9, 15.8, 15.7, 15.5, 15.4, 15.3, 15.2, 15.1, 15, 14.9, 14.8, 14.8, 14.7, 14.7, 14.6, 14.6, 14.6, 14.8, 14.8, 14.8, 14.7, 14.7, 14.7, 14.7, 14.6, 14.6, 14.6, 14.6, 14.5, 14.5, 14.5, 14.5, 14.5, 14.5, 14.4, 14.4, 14.4, 14.4, 14.4, 14.4, 14.4, 14.4, 14.3, 14.3, 14.3, 14.3, 14.3, 14.3, 14.3, 14.3, 14.3, 14.3, 14.3],
    P50: [13.3, 14.6, 15.8, 16.4, 16.7, 16.8, 16.9, 16.9, 16.8, 16.7, 16.6, 16.5, 16.4, 16.2, 16.1, 16, 15.9, 15.8, 15.7, 15.7, 15.6, 15.5, 15.5, 15.4, 15.4, 15.7, 15.6, 15.6, 15.6, 15.6, 15.5, 15.5, 15.5, 15.5, 15.4, 15.4, 15.4, 15.4, 15.4, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.2, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3, 15.3],
    P75: [14.2, 15.5, 16.8, 17.4, 17.7, 17.9, 18, 18, 17.9, 17.8, 17.7, 17.5, 17.4, 17.2, 17.1, 17, 16.9, 16.8, 16.7, 16.6, 16.5, 16.5, 16.4, 16.4, 16.3, 16.6, 16.6, 16.5, 16.5, 16.5, 16.5, 16.4, 16.4, 16.4, 16.4, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3, 16.3],
    P85: [14.7, 16.1, 17.4, 18, 18.3, 18.5, 18.6, 18.6, 18.5, 18.4, 18.2, 18.1, 17.9, 17.8, 17.7, 17.5, 17.4, 17.3, 17.2, 17.2, 17.1, 17, 17, 16.9, 16.9, 17.1, 17.1, 17.1, 17, 17, 17, 17, 16.9, 16.9, 16.9, 16.9, 16.9, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.9, 16.9, 16.9, 16.9, 16.9, 16.9, 16.9, 16.9, 17],
    P90: [15, 16.4, 17.8, 18.4, 18.8, 18.9, 19, 19, 18.9, 18.8, 18.7, 18.5, 18.4, 18.2, 18.1, 17.9, 17.8, 17.7, 17.6, 17.5, 17.5, 17.4, 17.3, 17.3, 17.3, 17.5, 17.5, 17.4, 17.4, 17.4, 17.4, 17.3, 17.3, 17.3, 17.3, 17.3, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.2, 17.3, 17.3, 17.3, 17.3, 17.3, 17.3, 17.3, 17.4, 17.4, 17.4, 17.4],
    P95: [15.5, 17, 18.4, 19, 19.4, 19.6, 19.6, 19.6, 19.6, 19.4, 19.3, 19.1, 19, 18.8, 18.7, 18.6, 18.4, 18.3, 18.2, 18.1, 18.1, 18, 17.9, 17.9, 17.8, 18.1, 18.1, 18, 18, 18, 17.9, 17.9, 17.9, 17.9, 17.9, 17.8, 17.8, 17.8, 17.8, 17.8, 17.8, 17.8, 17.8, 17.8, 17.8, 17.8, 17.8, 17.9, 17.9, 17.9, 17.9, 17.9, 17.9, 17.9, 18, 18, 18, 18, 18, 18.1, 18.1],
    P97: [15.9, 17.3, 18.8, 19.4, 19.8, 20, 20.1, 20.1, 20, 19.9, 19.7, 19.6, 19.4, 19.2, 19.1, 19, 18.8, 18.7, 18.6, 18.5, 18.5, 18.4, 18.3, 18.3, 18.2, 18.5, 18.5, 18.4, 18.4, 18.4, 18.3, 18.3, 18.3, 18.3, 18.2, 18.2, 18.2, 18.2, 18.2, 18.2, 18.2, 18.2, 18.2, 18.2, 18.2, 18.3, 18.3, 18.3, 18.3, 18.3, 18.3, 18.4, 18.4, 18.4, 18.4, 18.4, 18.5, 18.5, 18.5, 18.5, 18.6],
    P99: [16.6, 18, 19.5, 20.3, 20.6, 20.8, 20.9, 20.9, 20.8, 20.7, 20.6, 20.4, 20.2, 20.1, 19.9, 19.8, 19.7, 19.5, 19.4, 19.3, 19.3, 19.2, 19.1, 19.1, 19, 19.3, 19.3, 19.2, 19.2, 19.2, 19.1, 19.1, 19.1, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19.1, 19.1, 19.1, 19.1, 19.1, 19.2, 19.2, 19.2, 19.2, 19.3, 19.3, 19.3, 19.4, 19.4, 19.4, 19.4, 19.5, 19.5],
    P999: [17.8, 19.3, 20.9, 21.7, 22.1, 22.4, 22.5, 22.5, 22.4, 22.3, 22.1, 22, 21.8, 21.6, 21.5, 21.3, 21.2, 21.1, 21, 20.9, 20.8, 20.7, 20.6, 20.6, 20.5, 20.8, 20.7, 20.7, 20.7, 20.6, 20.6, 20.6, 20.5, 20.5, 20.5, 20.5, 20.5, 20.5, 20.5, 20.5, 20.5, 20.6, 20.6, 20.6, 20.6, 20.7, 20.7, 20.7, 20.8, 20.8, 20.9, 20.9, 21, 21, 21, 21.1, 21.1, 21.2, 21.2, 21.3, 21.3]
}