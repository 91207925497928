import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { FaRuler, FaWeight } from "react-icons/fa";
import { Switch } from "antd";
import MenuMom from "../../components/CalculatorBMI/MenuMom";
import { PregnancyWeek } from "../../components/Pregnancy/PregnancyWeek/PregnancyWeek";
import CalculateMomBMI from "../../components/CalculatorBMI/CalculateMomBMI";
import "../../components/CalculatorBMI/style.css";

const HomeMom = () => {

  const days = [
    { color: "#ff4c874f", title: "Day 1" },
    { color: "#89f0ea6b", title: "Day 2" },
    { color: "#a7f77675", title: "Day 3" },
    { color: "#6efa95a1", title: "Day 4" },
    { color: "#fab16cc2", title: "Day 5" },
    { color: "#fe6666e0", title: "Day 6" },
    { color: "#5cabe596", title: "Day 7" },
  ];
  
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 mb-8">
        <div className="h-[250px] bg-gradient-to-r from-[#8BDCF9] to-[#FCC4E7] rounded-[30px] lg:rounded-[50px] my-2 shadow-lg">
          <div className="text-center pt-6 lg:pt-9">
            {/* code nút chuyển trạng thái */}
            <div className="relative flex justify-center items-center text-center w-full">
              <p className="text-pinkColor font-bold text-lg lg:text-xl">
                Hello, User_Name
              </p>

              <div className="absolute right-4 flex flex-col items-start gap-2">
                <div className="flex gap-5">
                  <p className="font-bold">Pregnancy</p>
                  <p className="font-bold">Postpartum</p>
                </div>
                <div className="custom-switch ">

                <Switch  className="ml-[50px]" />
                </div>
              </div>
            </div>

            <p className="font-bold text-lg lg:text-xl">
              Expected date of birth: dd/mm/yyyy
            </p>

            <div className="flex justify-center mt-3 text-center">
              <div className="w-[80%] lg:w-[60%] h-4 mb-4 mt-1 bg-gray-200 rounded-full dark:bg-gray-700">
                <div
                  className="h-4 bg-[#E55C8A] rounded-full dark:bg-[#E55C8A]"
                  style={{ width: "30%" }}
                ></div>
              </div>
            </div>

            <div className="flex justify-center mt-3 text-center bg-white h-[98px] mx-[50px] sm:mx-[100px] lg:mx-[200px] rounded-tr-[20px] rounded-tl-[20px] lg:rounded-tr-[30px] lg:rounded-tl-[30px]">
              <div className="flex flex-col sm:flex-row justify-between items-center w-full sm:w-[80%] space-y-2 sm:space-y-0">
                <p className="text-blueColor text-[16px] sm:text-[18px] lg:text-[20px]">
                  30 weeks 6 days
                </p>

                <div className="text-center px-2 sm:px-4">
                  <FaRuler className="ml-2 sm:ml-3 text-[24px] sm:text-[30px]" />
                  12.0 cm
                </div>

                <div className="text-center px-2 sm:px-4">
                  <FaWeight className="ml-2 sm:ml-4 text-[24px] sm:text-[30px]" />
                  11 gram
                </div>

                <div className="text-center px-2 sm:px-4">
                  <span className="font-bold text-[16px] sm:text-[18px] lg:text-[20px]">
                    BMI
                  </span>
                  <br />
                  ---
                </div>

                <p className="text-pinkColor text-[16px] sm:text-[18px] lg:text-[20px]">
                  15 days left
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Phần 40 weeks pregnant */}
        <PregnancyWeek />
        {/* Calculate mom BMI */}
        <CalculateMomBMI />

        {/* menu for mom */}
        <div className="bg-[#f2fcf7] h-auto mt-7 rounded-[40px] shadow-lg mx-6 py-6 px-12">
          <h3 className="text-2xl font-bold py-4 text-center">
            Menu For Mom In Week 1
          </h3>

          <div className="relative overflow-x-scroll scroll-smooth hide-scrollbar whitespace-nowrap">
            <div className="scrollbar-hidden">
              {days.map((day, index) => (
                <MenuMom
                  key={index}
                  color={day.color}
                  title={day.title}
                  isFirst={index === 0}
                  isLast={index === days.length - 1}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeMom;
