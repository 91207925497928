import React from "react";

const Banner = ({header, content}) => {
  return (
    <div className="h-[240px] bg-primaryColor rounded-bl-[300px] rounded-br-[300px] border p-4">
      <div className="mt-5 space-y-6">
        <h4 className="text-[28px] text-center font-bold">
          {header}
        </h4>
        <p className="text-lg text-center mx-auto w-full max-w-[680px] text-gray-600">
         {content}
        </p>
      </div>
    </div>
  );
};

export default Banner;
