import React, { useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Banner from "../ToolBanner/Banner";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPanel from "../CustomPanel/CustomPanel";
import CalculatorGrowth from "./CalculatorGrowth";
import dataPanel from "../../../assets/data/Panel/DataPanel";

export const ChildrenGrowthPage = () => {
  const [openPanelIndex, setOpenPanelIndex] = useState(null);

  return (
    <>
      <Header />
      <div className="container mx-auto pb-4">
        <Banner
          header="Children's growth chart: Height, weight"
          content={
            <>
              This growth chart helps assess your child's body mass index (BMI),
              which represents your child's growth based on percentiles
              recommended by the World Health Organization (WHO).
            </>
          }
        />
        <div className="lg:px-[100px] md:px-[100px] grid grid-cols-1 lg:grid-cols-2 pt-6">
          {/* calculator */}
          <div className="flex justify-end">
            <CalculatorGrowth />
          </div>

          {/* info */}
          <div className="ml-4">
            <div className="">
              <div className="flex items-center">
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                  <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <p className="ml-2 text-gray-500">Disclaimer</p>
              </div>
              <p className="text-[15px] pt-4">
                The Child Growth Chart tool measures a child's height and weight
                based on the median percentiles established by the World Health
                Organization (WHO), the Centers for Disease Control and
                Prevention (CDC), and the figures recommended by the Vietnam
                Ministry of Health. This tool is for informational purposes only
                and does not take into account other factors such as genetics or
                the specific health conditions of each child. Therefore, the
                results cannot replace the assessment and diagnosis of a medical
                professional. We recommend that you consult your child's
                pediatrician or local healthcare provider for a more accurate
                assessment.
              </p>
            </div>

            <div className="flex items-center mb-4 mt-4">
              <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                <AiOutlineExclamationCircle className="text-[35px]" />
              </div>
              <p className="ml-2 text-gray-500">Information</p>
            </div>
            {dataPanel.childrenGrowth.toolPage.map((panel, index) => (
              <CustomPanel
                key={index}
                isOpen={openPanelIndex === index}
                onToggle={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}
                header={panel.header}
                content={panel.content}
              />
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ChildrenGrowthPage;
