import dayjs from 'dayjs';

// Hàm tính ngày dự sinh và làm tròn lên số tuần đã qua dựa trên kỳ kinh nguyệt cuối và chu kỳ kinh nguyệt
export function DueDateFromLMPWithCycle(lastMenstrualPeriod, cycleLength = 28) {
    const lmpDate = dayjs(lastMenstrualPeriod);
    const today = dayjs();
    const cycleAdjustment = cycleLength - 28;

    const dueDateRoot = lmpDate.subtract(3, 'month').add(7 + cycleAdjustment, 'day').add(1, 'year');

    const dueDateStart = dueDateRoot.subtract(3,'day').format('D MMMM, YYYY');
    const dueDateEnd = dueDateRoot.add(3,'day').format('D MMMM, YYYY');

    const weeksPassed = Math.ceil(today.diff(lmpDate, 'day') / 7);

    return {
        dueDateRoot,
        dueDateStart,
        dueDateEnd,
        weeksPassed,
        leftWeek: (40 - weeksPassed)
    };
}

// Hàm tính ngày dự sinh và làm tròn lên số tuần đã qua dựa trên ngày thụ thai
export function DueDateFromConception(conceptionDate) {
    const conception = dayjs(conceptionDate);
    const today = dayjs();

    const dueDateRoot = conception.add(266, 'day');
    
    const dueDateStart = dueDateRoot.subtract(3,'day').format('D MMMM, YYYY');
    const dueDateEnd = dueDateRoot.add(3,'day').format('D MMMM, YYYY');

    const weeksPassed = Math.ceil(today.diff(conception, 'day') / 7);

    return {
        dueDateRoot,
        dueDateStart,
        dueDateEnd,
        weeksPassed,
        leftWeek: (40 - weeksPassed)
    };
}

// Hàm tính ngày dự sinh và làm tròn lên số tuần đã qua cho thụ tinh trong ống nghiệm (IVF)
export function DueDateFromIVF(fertilizationDate, embryoAgeDays) {
    const fertilization = dayjs(fertilizationDate);
    const today = dayjs();

    const dueDateRoot = fertilization.add(266, 'day').subtract(embryoAgeDays, 'day');

    const dueDateStart = dueDateRoot.subtract(3,'day').format('D MMMM, YYYY');
    const dueDateEnd = dueDateRoot.add(3,'day').format('D MMMM, YYYY');
    
    const weeksPassed = Math.ceil(today.diff(fertilization, 'day') / 7);

    return {
        dueDateRoot,
        dueDateStart,
        dueDateEnd,
        weeksPassed,
        leftWeek: (40 - weeksPassed)
    };
}
