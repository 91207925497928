import React,{useState} from 'react'
import Header from '../../components/Header/Header'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";  

const OptionRole = () => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectRole, setSelectRole] = useState('');

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
    const goBack = () => {
      navigate(-1);
    };
    const handleSelectRole = (role) => {
        setSelectRole(role);
        setIsDropdownOpen(false);
    }
    const handleNextClick = () =>{
        if(selectRole === 'Pregnant mothers'){
          navigate('/pregnant-mothers')
        }else if(selectRole === 'After childbirth'){
          navigate('/after-childbirth')
        }else if(selectRole === 'Nursing'){
          navigate('/nursing')
        }else{
          alert('Please select a role first');
        }
    }
  return (
    <>
      <Header/>
        <Link className='btn btn-light my-3 flex font-bold m-3' onClick={goBack}>
            <AiOutlineArrowLeft className='mr-1 font-bold text-[22px] mt-[1px]'/>
            Previous
        </Link>

        <div className="flex items-center justify-center mt-32">
        <div className="container mx-auto px-7 w-full max-w-md">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Your Position</h4>
            <p className="text-sm">
              Select a role for your account.
            </p>

            <div className="space-y-3">
            <div className="relative">
            <button
              id="dropdownUsersButton"
              data-dropdown-toggle="dropdownUsers"
              onClick={toggleDropdown}
              className="w-full text-black bg-white px-5 py-4 text-center flex items-center justify-between rounded-lg border"
              type="button"
            >
              <span className='text-gray-500'>{selectRole ? selectRole : 'Your position'}</span>
              <svg
                className="w-2.5 h-2.5 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            {isDropdownOpen && (
              <div
                id="dropdownUsers"
                className="absolute left-0 z-10 mt-2 bg-white rounded-lg shadow dark:bg-gray-700 w-full"
              >
                <ul
                  className=" overflow-y-auto text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownUsersButton"
                >
                  <li>
                    <p className="flex items-center px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" 
                    onClick={()=>handleSelectRole ('Pregnant mothers')}>
                      Pregnant mothers 
                    </p>
                  </li>
                  <li>
                    <p className="flex items-center px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={()=>handleSelectRole ('After childbirth')}>
                      After childbirth
                    </p>
                  </li>
                  <li>
                    <p className="flex items-center px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={()=>handleSelectRole ('Nursing')}>
                      Nursing
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <button
                className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                type="button"
                onClick={handleNextClick}
              >
                Next
              </button>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default OptionRole
