import React, { useState } from "react";
import {
  FaRegUser,
  FaBars,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./style.css";
import Notifications from "./Notifications";
import UserDropdown from "./UserDropdown";
import DropDownTools from "./DropDownTools";
import DropdownHealthInformation from "./DropdownHealthInformation";
import CustomModal from "../Common/CustomModalConfirm";

export default function Header() {
  const [isOpenModalLogout, setOpenModalLogout] = useState(false);
  const handleCancelLogout = () => {
    setOpenModalLogout(false)
  }

  const handleConfirmLogout = () => {
    console.log("OK")
    setOpenModalLogout(false)
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <CustomModal isOpenModal={isOpenModalLogout} handleConfirm={handleConfirmLogout} handleCancel={handleCancelLogout}
        textConfirm="Logout"
        header="Logout"
        content={(<><h2 className="text-[20px] text-black pb-3">Are you sure want to log out?</h2></>)}
      />
      <header className="bg-white border-b border-gray-400 h-[80px] md:px-10 shadow">
        <div className="container mx-auto flex justify-between sm:px-3">
          {/* Icon FaBars */}
          <div className="lg:hidden flex items-center">
            <FaBars onClick={toggleMenu} className="w-6 h-6 cursor-pointer" />
          </div>

          {/* <!-- Logo and Navigation --> */}
          <div className="flex items-center space-x-8">
            <nav className="hidden lg:flex items-baseline font-bold text-gray-800 space-x-6 space-y-8">
              <Link to="/home" className="mt-8">
                Logo here
              </Link>

              <DropDownTools />
              <DropdownHealthInformation />

              <Link to="/contact">Contact</Link>
              <Link to="/support">Supporting</Link>
            </nav>
          </div>

          <div className="flex items-center space-x-2 space-y-4">
            <div className="text-gray-600 mr-4 mt-8">
            </div>
            {/* <!-- Đã login --> */}
            <Notifications />
            <UserDropdown handleOpenMedalLogout={() => setOpenModalLogout(true)}/>
            {/* Chưa login */}
            <div className="flex items-center justifyContent-center bg-pinkColor text-white p-3 rounded-full">
              <Link to='/login' className='pr-2'>Sign in</Link>
              <FaRegUser className='w-4 h-4' />
            </div>
          </div>

        </div>

        {isMenuOpen && (
          <div className="lg:hidden font-bold bg-white shadow-md mt-2 p-4 space-y-2 z-50 relative">
            <Link to="/home" className=" text-gray-800">
              Logo here
            </Link>
            <DropDownTools />
            <DropdownHealthInformation />
            <Link to="/contact" className="block ">
              Contact
            </Link>
            <Link to="/support" className="block ">
              Supporting
            </Link>
          </div>
        )}
      </header>
    </>
  );
}
