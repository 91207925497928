import React, { useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { EstimateDate } from "./EstimateDate";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPanel from "../CustomPanel/CustomPanel";
import Banner from "../ToolBanner/Banner";
import dataPanel from "../../../assets/data/Panel/DataPanel";

export const EstimateDatePage = () => {

  const [openPanelIndex, setOpenPanelIndex] = useState(null);

  return (
    <>
      <Header />
      <div className="container mx-auto pb-4">
        {/* Banner */}
        <Banner header=" Estimated Delivery Date (EDD)" content={<> Use this tool to calculate your due date. Please note that this is
          an estimate only. Most women give birth within a week before or
          after this date.</>} />
        {/* Body */}
        <div className="lg:px-[100px] md:px-[100px] grid grid-cols-1 lg:grid-cols-2 pt-6">
          <EstimateDate />
          {/* Info */}
          <div className="ml-4">
            <div className="flex items-center mb-4">
              <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                <AiOutlineExclamationCircle className="text-[35px]" />
              </div>
              <p className="ml-2 text-gray-500">Information</p>
            </div>
            {dataPanel.estimateDate.map((panel, index) => (
              <CustomPanel
                key={index}
                isOpen={openPanelIndex === index}
                onToggle={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}
                header={panel.header}
                content={panel.content}
              />
            ))}

            <div className="mt-4">
              <div className="flex items-center mt-4">
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                  <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <p className="ml-2 text-gray-500">Disclaimer</p>
              </div>
              <p className="text-[15px] pt-4">
                The due date calculator is for informational purposes only and
                is not a substitute for medical advice, diagnosis, or treatment.
                Please consult your doctor before searching for treatment on
                MomChildCare.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EstimateDatePage;
