import React, { useEffect, useRef, useState } from 'react';
import avaImg from '../../assets/image/other/avatar.png';
import { ProfileLayout } from './ProfileLayout';


export const ProfileInformation = () => {
    const [isEmail, setIsEmail] = useState(false);
    const [isPhonenumber, setIsPhonenumber] = useState(false);

    const emailInputRef = useRef(null);
    const phoneInputRef = useRef(null);

    useEffect(() => {
        if (isEmail && emailInputRef.current) {
          emailInputRef.current.focus();
        }
      }, [isEmail])

      useEffect(() => {
        if (isPhonenumber && phoneInputRef.current) {
          phoneInputRef.current.focus();
        }
      }, [isPhonenumber]);


    return (
        <ProfileLayout>
        <div className="p-5 lg:w-3/4 md:w-full mx-auto">
            <h2 className="text-2xl font-bold mb-6">UPDATE INFORMATION</h2>
            <div className="flex items-center space-x-6 mb-6">
                <div className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] rounded-full overflow-hidden bg-white flex justify-center items-center">
                    <img className="w-full h-full object-cover" src={avaImg} alt="" />
                </div>
                <button className="border-pinkColor text-pinkColor border-solid border-2 hover:bg-pinkColor hover:text-white py-2 px-4 rounded-full font-medium">
                    Change image
                </button>
            </div>

            <div className="space-y-5">
                <div>
                    <label className="block font-medium">User name</label>
                    <div>
                        <input
                            className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full"
                            readOnly
                            type="text"
                            placeholder="User_Name" />
                    </div>
                </div>
                <div>
                    <label className="block font-medium">Full name</label>
                    <div>
                        <input
                            className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full"
                            readOnly
                            type="text"
                            placeholder="Full_Name" />
                    </div>
                </div>
                <div>
                    <label className="block font-medium">Day of birth</label>
                    <div>
                        <input
                            className="border-gray-400 border-solid border-b-[1px] focus:outline-none w-full"
                            readOnly
                            type="date"
                            placeholder="DOB" />
                    </div>
                </div>
                <div>
                    <label className="block font-medium">Email</label>
                    <div className="flex space-x-2 items-center">
                        <input
                            className={`border-gray-400 border-solid border-b-[1px] focus:outline-none ${isEmail ? 'focus:outline-none focus:border-black focus:border-b-2' : ''} w-full`}
                            type="text"
                            placeholder="Email"
                            ref={emailInputRef}
                            readOnly={!isEmail} />
                        <button
                            className="border-solid border-pinkColor border-2 px-4 py-1 rounded-full text-pinkColor font-medium hover:bg-pinkColor hover:text-white"
                            onClick={() => setIsEmail(!isEmail)}
                        >
                             {isEmail ? 'Save' : 'Edit'}
                        </button>
                    </div>
                </div>
                <div>
                    <label className="block font-medium">Phone number</label>
                    <div className="flex space-x-2 items-center">
                        <input
                            className={`border-gray-400 border-solid border-b-[1px] ${isPhonenumber ? 'focus:outline-none focus:border-black focus:border-b-2' : ''} w-full`}
                            type="text"
                            placeholder="Number_Phone"
                            ref={phoneInputRef}
                            readOnly={!isPhonenumber}
                            />
                        <button className="border-solid border-pinkColor border-2 px-4 py-1 rounded-full text-pinkColor font-medium hover:bg-pinkColor hover:text-white"
                            onClick={() => setIsPhonenumber(!isPhonenumber)}
                        >
                            {isPhonenumber ? 'Save' : 'Edit'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </ProfileLayout>
    );
}


