import React from "react";
import bannerImg from "../../assets/image/baby/image 2.png";
import blogImg from "../../assets/image/fruid/grapes.png";
import content1 from "../../assets/image/baby/image10.png";
import content2 from "../../assets/image/baby/image11.png";
import content3 from "../../assets/image/baby/image12.png";
import { PregnancyWeek } from "../../components/Pregnancy/PregnancyWeek/PregnancyWeek";
import { EstimateDate } from "../../components/Tool/EstimateDate/EstimateDate";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export const Home = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 mt-10">
        {/* Phần Banner */}
        <div className="flex flex-col lg:flex-row items-center justify-center  py-10">
          <div className="lg:w-[30%]">
            <img
              src={bannerImg}
              alt="Mother and Baby"
              className="w-full rounded-lg"
            />
          </div>
          <div className="lg:w-[60%] lg:h-[400px] bg-[#D8F4FF] mt-6 lg:mt-44 lg:text-left rounded-tr-[230px] px-2">
            <div className="mt-20 space-y-8">
              <h2 className="text-pinkColor text-[40px] text-center font-bold ">
                MCCare
              </h2>
              <p className="text-lg  text-center">
                Provides information on health care services and support for
                mothers and babies after birth, mothers during pregnancy and
                before pregnancy, bringing reliable satisfaction in information
                and news about the health of mothers and children.
              </p>
            </div>
          </div>
        </div>

        {/* Phần Blog và Estimated Delivery Date */}
        <div className="mt-10 p-6 grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Latest Blog */}
          <div>
            <h3 className="text-3xl text-pinkColor text-center font-bold mb-4">
              Latest blog
            </h3>
            <div className="space-y-4">
              <div className="space-y-4">
                <div className="bg-white p-4 rounded-3xl border-gray-400 border-solid border">
                  <div className="flex">
                    <img
                      src={blogImg}
                      alt="Blog image"
                      className="w-16 h-16 rounded-full"
                    />
                    <div className="ml-4 text-center">
                      <h4 className="text-lg font-semibold">Title</h4>
                      <p className="text-sm ">
                        Conent Conent Conent Conent Conent Conent Conent Conent
                        Conent Conent Conent Conent Conent Conent Conent Conent
                        Conent
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between px-12">
                    <p className="text-sm text-gray-500">By Admin on Date</p>
                    <p className="text-sm text-gray-500">Date: 9/6/24</p>
                  </div>
                </div>

                <div className="bg-white p-4 rounded-3xl border-gray-400 border-solid border ">
                  <div className="flex">
                    <img
                      src={blogImg}
                      alt="Blog image"
                      className="w-16 h-16 rounded-full"
                    />
                    <div className="ml-4 text-center">
                      <h4 className="text-lg font-semibold">Title</h4>
                      <p className="text-sm">
                        Conent Conent Conent Conent Conent Conent Conent Conent
                        Conent Conent Conent Conent Conent Conent Conent Conent
                        Conent
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between px-12">
                    <p className="text-sm text-gray-500">By Admin on Date</p>
                    <p className="text-sm text-gray-500">Date: 9/6/24</p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-3xl border-gray-400 border-solid border ">
                  <div className="flex">
                    <img
                      src={blogImg}
                      alt="Blog image"
                      className="w-16 h-16 rounded-full"
                    />
                    <div className="ml-4 text-center">
                      <h4 className="text-lg font-semibold">Title</h4>
                      <p className="text-sm">
                        Conent Conent Conent Conent Conent Conent Conent Conent
                        Conent Conent Conent Conent Conent Conent Conent Conent
                        Conent
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between px-12">
                    <p className="text-sm text-gray-500">By Admin on Date</p>
                    <p className="text-sm text-gray-500">Date: 9/6/24</p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button className="mt-4 px-28 bg-pinkColor text-white py-2 rounded-lg">
                  View more
                </button>
              </div>
            </div>
          </div>

          {/* Estimated Delivery Date */}
          <div>
            <h3 className="text-3xl text-center font-bold mb-4">
              Estimated Delivery Date (EDD)
            </h3>
            <EstimateDate />
          </div>
        </div>

        {/* Phần 40 weeks pregnant */}
        <PregnancyWeek />

        {/* Phần Services */}
        <div className="my-8">
          <div className="text-center px-4">
            <h3 className="text-xl sm:text-2xl font-semibold">
              MCCare provides you with information on mother, baby and family
              health.
            </h3>
            <p className="text-base sm:text-lg mt-4">
              MCCare is committed to providing useful, easy-to-read, and
              trustworthy articles on <br className="hidden sm:block" />{" "}
              maternal and child health care.
            </p>
          </div>

          <div className="mt-10 flex flex-col lg:flex-row items-center justify-center space-y-6 lg:space-y-0 lg:space-x-10 px-6 lg:px-40">
            <div className="text-center h-[450px] w-full sm:w-[80%] lg:w-[70%] bg-[#F2FCF7] p-6 rounded-3xl shadow-lg">
              <div>
                <img src={content1} alt="" />
              </div>
              <h4 className="text-xl font-bold mb-2">Authentic Sources</h4>
              <p className="text-sm sm:text-base">
                All MCCare articles are written based on research - scientific
                reports, news from leading educational institutions and medical
                agencies.
              </p>
            </div>

            <div className="text-center h-[450px] w-full sm:w-[80%] lg:w-[70%] bg-[#D8F4FF] p-6 rounded-3xl shadow-lg">
              <div>
                <img src={content2} alt="" />
              </div>
              <h4 className="text-xl font-bold mb-2">Regular Updates</h4>
              <p className="text-sm sm:text-base">
                MCCare works with doctors and medical experts to continually
                update articles to ensure the highest level of accuracy.
              </p>
            </div>

            <div className="text-center h-[450px] w-full sm:w-[80%] lg:w-[70%] bg-[#FFEAF1] p-6 rounded-3xl shadow-lg">
              <div>
                <img src={content3} alt="" />
              </div>
              <h4 className="text-xl font-bold mb-2">Reliable</h4>
              <p className="text-sm sm:text-base">
                MCCare - the leading mother and baby health information site on
                the market, is committed to providing accurate, easy-to-read,
                and constantly updated articles, helping you make the best
                decisions for your health and your family's.
              </p>
            </div>
          </div>
        </div>
        
      </div>
      <Footer />
    </>
  );
};
