
const childrenGrowth = {
    toolPage: [
        {
            header: "What is a Child Growth Chart?",
            content: (
                <>
                    Growth charts help parents and pediatricians track a child's growth over time. A child's height, weight, head circumference, and BMI can be compared with those of children of the same age and gender to determine whether the child is growing appropriately.
                </>
            ),
        },
        {
            header: "How to read a child's growth chart?",
            content: (
                <>
                    A percentile is a measurement that shows where a child stands in relation to other children. On a growth chart, percentiles are shown as graph lines. When you plot a child's weight and height on this chart, you will see the child's measurements in the corresponding percentile group. A higher percentile number can be interpreted as a larger/taller child compared to other children of the same age and sex, in terms of height and weight. A tower percentile number means a smaller/shorter child compared to other children of the same age and sex. For example, a child at the 5th percentile is considered to be lighter than 95% of other children of the same age. On the other hand, a child at the 90th percentile is considered to be heavier than 90% of other children of the same age.
                </>
            ),
        },
        {
            header: "Why is it important to track your child's growth?",
            content: (
                <>
                    Tracking your child's height, weight, and head circumference on a growth chart over time allows your pediatrician and parents to see if your child is growing too slowly or too quickly — which could indicate other nutritional or health concerns.
                    <br /> <br />
                    However, don't take the percentiles too seriously. They simply show that your pediatrician is tracking your child's growth over time. Is your child growing within his or her percentile, which means he or she is growing well? Or are there any changes in his or her growth trajectory, which could indicate a health problem? This is important because it helps detect changes early in a child's life.
                </>
            ),
        },
        {
            header: "Where do these standards come from?",
            content: (
                <>
                    These standards were developed using data collected in the World Health Organization (WHO) Multicenter Growth Reference Study. <br />
                    The WHO Multicenter Growth Reference Study (MGRS) was conducted from 1997 to 2003 to create model growth zones for assessing the growth and development of infants and young children around the world. The MGRS collected primary growth data and related information from approximately 8,500 children from diverse cultural and ethnic backgrounds (such as Brazil, Ghana, India, Norway, Oman, and the United States).
                    <br />
                    The study was expected to provide a uniform international standard that best represents physiological growth for all children from birth to 5 years of age, and thereby establish breastfeeding as the standard model for child growth and development.
                </>
            ),
        },
        {
            header: "References",
            content: (
                <>
                    <a className="underline" href="https://www.cdc.gov/growthcharts/growthchart_faq.htm">
                        https://www.cdc.gov/growthcharts/growthchart_faq.htm
                    </a>
                    <br />
                    <a className="underline" href="https://www.researchgate.net/publication/324165249_A_Comparative_Study_on_Feeding_Practice_and_Nutrition_Status_among_6-23_Months_Age_Children">
                        https://www.researchgate.net/publication/324165249_A_Comparative_Study_on_Feeding_Practice_and_Nutrition_Status_among_6-23_Months_Age_Children
                    </a>
                    <br />
                    <a className="underline" href="https://kidshealth.org/en/parents/growth-charts.html ">
                        https://kidshealth.org/en/parents/growth-charts.html
                    </a>
                    <br />
                    <a className="underline" href="https://www.who.int/tools/child-growth-standards/who-multicentre-growth-reference-study#:~:text=">
                        https://www.who.int/tools/child-growth-standards/who-multicentre-growth-reference-study#:~:text=
                    </a>
                    <br />
                </>
            ),
        },
    ],
    result: [
        {
            header: "Can a baby’s percentile decrease?",
            content: (
                <>
                    A baby’s percentile can decrease over time due to several factors. Sometimes, children will shift percentiles during the first two or three years due to their genetics. Growth patterns also depend on the baby’s nutrition. For example, breastfed babies grow faster than formula-fed babies in the first 6 months, while formula-fed babies grow faster after 6 months.
                    <br />
                    A child transitioning from the WHO growth chart to the CDC growth chart after 24 months may also be classified differently due to differences in format and values between the two charts.
                    <br />
                    If your child’s percentile decreases, it doesn’t necessarily mean you should worry. Consult your pediatrician or healthcare provider to determine the cause and receive appropriate advice.
                </>
            ),
        },
        {
            header: "Are boys’ head circumferences larger than girls’?",
            content: (
                <>
                    Boys are usually born with larger heads and bodies compared to girls. Based on CDC and WHO charts, boys’ head circumferences are typically about 1 cm larger than girls’.
                </>
            ),
        },
        {
            header: "Do preterm babies have a lower percentile than full-term babies?",
            content: (
                <>
                    This will depend on the health of the baby at birth. Preterm or premature babies who are otherwise healthy often have lower percentiles than full-term babies from birth until 8 years old, but they may catch up in growth in the following years. Moreover, premature babies are expected to reach reasonable body indices by the time they are 4 years old.
                    <br />
                    Growth chart results can also be influenced if babies were smaller than their gestational age (when in the womb) or had health issues at birth.
                </>
            ),
        },
        {
            header: "My child is below the average percentile. Should I be worried?",
            content: (
                <>
                    There is no single ideal percentile for your child. Healthy children can be in the 95th percentile or even the 4th percentile. Doctors usually focus on whether your child’s data aligns with normal growth patterns, including whether the height and weight growth rates are consistent.
                    <br />
                    If growth rate or percentile changes, that’s when doctors take a closer look—but this doesn’t always indicate a cause for concern. Sometimes, children’s percentiles change during growth phases such as infancy or puberty.
                </>
            ),
        },
        {
            header: "Why is measuring a child’s head circumference important?",
            content: (
                <>
                    Measuring a child’s head circumference helps determine if the brain is developing at a normal rate. This provides insight for parents and doctors into any potential growth and developmental issues.
                    <br />
                    For example, if a baby’s head is much smaller than average, it could indicate that the brain isn’t growing normally. On the other hand, if the head is significantly larger, it could be a sign of hydrocephalus (fluid buildup around the brain), which would require medical intervention.
                </>
            ),
        },
        {
            header: "Is children’s BMI assessed differently than adults’ BMI?",
            content: (
                <>
                    Although the formula for calculating BMI is the same, children’s BMI is interpreted differently from adults’. For children and teens, BMI can be used to screen for potential weight or health issues relative to the child’s age and gender. For adults, BMI interpretation depends solely on weight and height.
                </>
            ),
        },
        {
            header: "References",
            content: (
                <>
                    Transitioning from WHO to CDC Growth Charts <br />
                    <a className="underline" href="https://www.cdc.gov/nccdphp/dnpao/growthcharts/who/using/transitioning.htm">
                        https://www.cdc.gov/nccdphp/dnpao/growthcharts/who/using/transitioning.htm
                    </a>
                    <br />
                    Accessed: 18.11.2022 <br />
                    <br />
                    PMC Articles <br />
                    <a className="underline" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3474389/">
                        https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3474389/
                    </a>
                    <br />
                    Accessed: 18.11.2022 <br />
                    <br />
                    PMC Articles <br />
                    <a className="underline" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2821086/">
                        https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2821086/
                    </a>
                    <br />
                    Accessed: 18.11.2022
                </>
            ),
        }
    ]
};

const estimateDate = [
    {
        header: "Last menstrual period",
        content: (
            <>
                Most pregnancies last about 40 weeks (or 38 weeks from conception). So the best way to estimate your due date is to count 40 weeks, or 280 days, from the first day of your last period. Another way is to take the first day of your last period, subtract three months, and add seven days. The only conditions for calculating your due date based on your last period are that your menstrual cycle is regular, has a 28-day cycle, and knows the date of your last period.
                <br /> <br />
                So, if your last period started on April 11, 2020, you would subtract three months back, which is January 11, 2020, then add seven days, which means your due date would be January 18, 2021. This is how doctors estimate your due date. But remember that it is normal for you to give birth a week early or late.
            </>
        ),
    },
    {
        header: "Date of conception",
        content: (
            <>
                The method of calculating gestational age based on the date of intercourse applies to couples who remember the exact date of intercourse, the woman has a regular menstrual cycle and can determine the date of ovulation. The reason is that sperm can live in the female body for 5 days, but the egg only lives for 1 day after ovulation. Sperm can only fertilize the egg during this period.
                <br /> <br />
                According to this method, the first day of gestational age will be calculated starting from the date of intercourse with ovulation and then adding 38 weeks (i.e. 266 days).
            </>
        ),
    },
    {
        header: "In vitro fertilization - IVF",
        content: (
            <>
                Calculating the gestational week and due date with artificial insemination will be more accurate than with natural conception. This is because the exact date of embryo implantation or ovulation has been determined.
                <br /> <br />
                The due date with in vitro fertilization will be calculated by adding 38 weeks (i.e. 266 days) from the time the egg is fertilized. Another way of calculating is to still add 38 weeks but subtract the number of days when the embryo is implanted (fertilized, i.e. at the time the embryo is placed in the uterus). If the embryo is 3 days old, subtract 3 days, and if it is 5 days old, subtract 5 days.
            </>
        ),
    }
];

const weightOfPregnant = {
    toolPage: [
        {
            header: "Why do you gain weight during pregnancy?",
            content: (
                <>
                    During pregnancy, a woman's body changes and adds weight to ensure that the
                    fetus receives enough nutrients throughout the pregnancy.
                    The amount of weight gained will depend on the mother's pre-pregnancy
                    weight; petite and underweight women will need to gain more weight than
                    those who are overweight before pregnancy.
                </>
            ),
        },
        {
            header: "Changes in your body during pregnancy",
            content: (
                <>
                    Along with weight gain, some of the changes your body will experience during pregnancy include:
                    <br />
                    - Increased blood circulation, which can make your skin look rosier and feel refreshed.
                    <br />
                    - Hormonal changes that cause dark spots, melasma, and acne.
                    <br />
                    - Enlarged uterus leading to a growing belly.
                    <br />
                    - Changes in your breasts to accommodate milk production.
                    <br />
                    - Constipation and heartburn.
                    <br />
                    Your body is working harder to ensure the safe and healthy development of your baby.
                </>
            ),
        },
        {
            header: "How much weight should I gain during pregnancy?",
            content: (
                <>
                    To figure out how much weight you should gain during pregnancy, it's important to start with your BMI
                    (body mass index).
                    <br />
                    Underweight: gain about 12-18kg
                    <br />
                    Normal weight: gain about 11-12kg
                    <br />
                    Overweight: gain about 7-12kg
                    <br />
                    Obese: gain about 6-10kg
                </>
            ),
        },
        {
            header: "Which trimester will you gain the most weight?",
            content: (
                <>
                    Many women gain the most weight around the third trimester. This is when the
                    fetus usually accumulates the most weight during this time.
                </>
            ),
        },
        {
            header: "How much weight should you gain each week during pregnancy?",
            content: (
                <>
                    Typically, you should gain 0.9 kg - 1.3 kg in the first trimester;
                    and 0.45 kg per week in the remaining two trimesters.
                </>
            ),
        },
        {
            header: "References",
            content: (
                <>
                    Weight gain in pregnancy <br />
                    <a className="underline" href="https://www.nhs.uk/pregnancy/related-conditions/common-symptoms/weight-gain/#:~:text=">https://www.nhs.uk/pregnancy/related-conditions/common-symptoms/weight-gain/#:~:text=</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                    <br />
                    Weight Gain During Pregnancy
                    <br />
                    <a className="underline" href="https://www.cdc.gov/reproductivehealth/maternalinfanthealth/pregnancy-weight-gain.htm">https://www.cdc.gov/reproductivehealth/maternalinfanthealth/pregnancy-weight-gain.htm</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                    <br />
                    Weight gain in pregnancy
                    <br />
                    <a className="underline" href="https://www.pregnancybirthbaby.org.au/weight-gain-in-pregnancy">https://www.pregnancybirthbaby.org.au/weight-gain-in-pregnancy</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                    <br />
                    Stages of pregnancy
                    <br />
                    <a className="underline" href="https://www.womenshealth.gov/pregnancy/youre-pregnant-now-what/stages-pregnancy">https://www.womenshealth.gov/pregnancy/youre-pregnant-now-what/stages-pregnancy</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                    <br />
                    Weight Gain During Pregnancy
                    <br />
                    <a className="underline" href="https://www.acog.org/clinical/clinical-guidance/committee-opinion/articles/2013/01/weight-gain-during-pregnancy#:-:text=">https://www.acog.org/clinical/clinical-guidance/committee-opinion/articles/2013/01/weight-gain-during-pregnancy#:-:text=</a>
                    <br />
                    Ngày truy cập: 18.11.2022
                </>
            ),
        }
    ],
    result: [
        {
            header: "How much weight should I gain during pregnancy?",
            content: (
                <>
                    The Institute of Medicine has developed guidelines for healthy weight gain during pregnancy that are followed by health professionals around the world. For women who are overweight before pregnancy (BMI 25 to 29.9), they should gain between 15 and 25 pounds during pregnancy.
                </>
            ),
        },
        {
            header: "How does being overweight affect pregnancy?",
            content: (
                <>
                    Being overweight during pregnancy can put you at risk for health complications such as high blood pressure, preeclampsia, and gestational diabetes. It can also increase your risk of developing type 2 diabetes and high blood pressure later in life.
                    <br />
                    Babies born to women with these health problems may also be at increased risk of birth defects, premature birth, or stillbirth. Babies born weighing more than 9 pounds are also at risk of developing diabetes later in life.
                </>
            ),
        },
        {
            header: "How can I ensure a healthy pregnancy if I am overweight?",
            content: (
                <>
                    You can still have a healthy pregnancy even if you are overweight.
                    <br />
                    Control your weight with diet and exercise, follow up with regular prenatal care, and see your doctor regularly to monitor any complications.
                </>
            ),
        },
        {
            header: "References",
            content: (
                <>
                    Pregnancy and obesity: Know the risks <br />
                    <a className="underline" href="https://www.mayoclinic.org/healthy-lifestyle/pregnancy-week-by-week/in-depth/pregnancy-and-obesity/art-20044409#:~:text=">
                        https://www.mayoclinic.org/healthy-lifestyle/pregnancy-week-by-week/in-depth/pregnancy-and-obesity/art-20044409#:~:text=
                    </a>
                    <br />
                    Accessed: 18.11.2022 <br />
                    Obesity and pregnancy <br />
                    <a className="underline" href="https://www.nhs.uk/pregnancy/related-conditions/existing-health-conditions/overweight/">
                        https://www.nhs.uk/pregnancy/related-conditions/existing-health-conditions/overweight/
                    </a>
                    <br />
                    Accessed: 18.11.2022 <br />
                    Healthy pregnancy for women who are overweight <br />
                    <a className="underline" href="https://raisingchildren.net.au/pregnancy/health-wellbeing/healthy-lifestyle/healthy-pregnancy-overweight">
                        https://raisingchildren.net.au/pregnancy/health-wellbeing/healthy-lifestyle/healthy-pregnancy-overweight
                    </a>
                    <br />
                    Accessed: 18.11.2022
                </>
            ),
        }
    ]
};

const dataPanel = {
    childrenGrowth,
    estimateDate,
    weightOfPregnant,
}

export default dataPanel