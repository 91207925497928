
import React from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineLogout,
  AiFillQuestionCircle,
  AiOutlineSchedule,
  AiOutlineProfile,
  AiOutlineFund,
} from "react-icons/ai";
import { Dropdown, Menu } from "antd";
import { FaRegUser } from "react-icons/fa";

const UserDropdown = ({handleOpenMedalLogout}) => {
  const itemsUserDropdown = [
    {
      label: (
        <div className="flex text-center py-2 w-full">
          <Link
            to="/"
            className="text-[14px] flex text-center items-center hover:text-pinkColor"
          >
            <AiOutlineProfile className="w-4 h-4 mr-2" />
            View Profile
          </Link>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="flex text-center w-full">
          <Link
            to="/"
            className="text-[14px] flex text-center items-center hover:text-pinkColor"
          >
            <AiOutlineFund className="w-4 h-4 mr-2" />
            Manage Health
          </Link>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div className="flex text-center py-2 w-full">
          <Link
            to="/"
            className="text-[14px] flex text-center items-center hover:text-pinkColor"
          >
            <AiOutlineSchedule className="w-4 h-4 mr-2" />
            Schedule
          </Link>
        </div>
      ),
      key: "3",
    },
    {
      label: (
        <div className="flex text-center pb-2 w-full">
          <Link
            to="/"
            className="text-[14px] flex text-center items-center hover:text-pinkColor"
          >
            <FaRegUser className="w-4 h-4 mr-2" />
            Account
          </Link>
        </div>
      ),
      key: "4",
    },
    {
      label: (
        <>
          <div className="border-t border-gray-300"></div>

          <div className="flex text-center py-2 w-full">
            <Link
              to="/"
              className="text-[14px] flex text-center items-center hover:text-pinkColor"
            >
              <AiFillQuestionCircle className="w-4 h-4 mr-2" />
              Help
            </Link>
          </div>
        </>
      ),
      key: "5",
    },
    {
      label: (
        <div className="flex text-center w-full">
          <button
            className="text-[14px] flex text-center items-center hover:text-pinkColor"
            onClick={handleOpenMedalLogout}
          >
            <AiOutlineLogout className="w-4 h-4 mr-2" />
            Logout
          </button>
        </div>
      ),
      key: "6",
    },
  ];

  const menuDrop = (
    <Menu
      items={itemsUserDropdown}
      className="w-[90vw] sm:w-[250px] rounded-lg bg-white shadow-lg pointer-events-auto mt-2 border border-gray-300 p-4"
    />
  );

  return (
    <>
      <Dropdown
        overlay={menuDrop}
        trigger={["click"]}
        arrow={{ pointAtLeft: true }}
        className="cursor-pointer "
      >
        <Link
          onClick={(e) => e.preventDefault()}
          className="flex items-center"
        >
          <div className="bg-pinkColor text-white p-3 rounded-full cursor-pointer">
            <FaRegUser className="w-4 h-4" />
          </div>
        </Link>
      </Dropdown>
    </>
  );
};

export default UserDropdown;
