import React from "react";
import pregnancy from "../../assets/image/informationHealth/pregnancy.png";
import postpartum from "../../assets/image/informationHealth/postpartum-mother-care.png";
import baby from "../../assets/image/informationHealth/baby.png";
import preparing from "../../assets/image/informationHealth/chuan-bi-mang-thai@3x.png";
import { Link } from "react-router-dom";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { Dropdown, Menu } from "antd";

const DropdownHealthInformation = () => {
  const itemsDropDownHealthInformation = [
    {
      label: (
        <>
          <Link to="/" className="text-[14px] flex text-center items-center hover:text-pinkColor">
            <img
              src={preparing}
              alt="Preparing for pregnancy"
              className="h-[40px] w-[40px]"
            />
            <p className="ml-3">Preparing for pregnancy</p>
          </Link>
        </>
      ),
      key: "1",
    },
    {
      label: (
        <>
          <Link to="/" className="text-[14px] flex text-center items-center hover:text-pinkColor">
            <img
              src={pregnancy}
              alt="Preparing for pregnancy"
              className="h-[40px] w-[40px]"
            />
            <p className="ml-3">Pregnant mother</p>
          </Link>
        </>
      ),
      key: "2",
    },
    {
      label: (
        <>
          <Link to="/" className="text-[14px] flex text-center items-center hover:text-pinkColor">
            <img
              src={postpartum}
              alt="Preparing for pregnancy"
              className="h-[40px] w-[40px]"
            />
            <p className="ml-3">Postpartum mother</p>
          </Link>
        </>
      ),
      key: "3",
    },
    {
      label: (
        <>
          <Link to="/" className="text-[14px] flex text-center items-center hover:text-pinkColor">
            <img
              src={baby}
              alt="Preparing for pregnancy"
              className="h-[40px] w-[40px]"
            />
            <p className="ml-3">Newborn baby</p>
          </Link>
        </>
      ),
      key: "4",
    },
    {
      label: (
        <>
          <Link
            to="/"
            className="flex items-center bg-white border border-pinkColor text-pinkColor hover:text-white hover:bg-pinkColor border-solid rounded-lg py-2 px-4"
          >
            View all
            <FaAngleRight className="ml-3" />
          </Link>
        </>
      ),
      key: "5",
    },
  ];

  const menuDrop = (
    <Menu
      items={itemsDropDownHealthInformation}
      className="w-[500px] h-[270px] bg-white shadow-lg border rounded-b-lg border-gray-200"
    />
  );

  return (
    <div className="group relative">
      <Dropdown
        overlay={menuDrop}
        trigger={["hover"]}
        arrow={{ pointAtCenter: true }}
        className="cursor-pointer "
      >
        <Link
          onClick={(e) => e.preventDefault()}
          className="flex items-center"
        >
          Health Information <FaAngleDown className="w-4 h-4 ml-1 inline" />
        </Link>
      </Dropdown>
    </div>
  );
};


export default DropdownHealthInformation;
