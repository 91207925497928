import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './style.css'; 

const localizer = momentLocalizer(moment);

export const MyCalendar = ({ events, onSelectEvent }) => {
  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500, width: '80%', marginLeft: '5%' }}
      onSelectEvent={onSelectEvent}
    />
  );
};

