import React from "react";
import fruitImg from "../../assets/image/fruid/grapes.png";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { FaBell } from "react-icons/fa";

const Notifications = () => {
  const itemsNotification = [
    {
      label: (
        <div className="flex items-center bg-primaryColor p-2 border border-gray-300 shadow">
          <img
            src={fruitImg}
            alt="NoticeImg"
            className="h-[40px] w-[40px] mr-4"
          />

          <div className="flex-1 flex flex-col justify-center items-center">
            <h4 className="underline text-[18px]">Title</h4>
            <p className="line-clamp-1">Remind! - Notifications </p>
          </div>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="flex items-center bg-primaryColor p-2 border border-gray-300 shadow">
          <img
            src={fruitImg}
            alt="NoticeImg"
            className="h-[40px] w-[40px] mr-4"
          />

          <div className="flex-1 flex flex-col justify-center items-center">
            <h4 className="underline text-[18px]">Title</h4>
            <p className="line-clamp-1">Remind! - Notifications </p>
          </div>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div className="flex items-center bg-primaryColor p-2 border border-gray-300 shadow">
          <img
            src={fruitImg}
            alt="NoticeImg"
            className="h-[40px] w-[40px] mr-4"
          />

          <div className="flex-1 flex flex-col justify-center items-center">
            <h4 className="underline text-[18px]">Title</h4>
            <p className="line-clamp-1">Remind! - Notifications </p>
          </div>
        </div>
      ),
      key: "3",
    },
    {
      label: (
        <div className="flex items-center bg-primaryColor p-2 border border-gray-300 shadow">
          <img
            src={fruitImg}
            alt="NoticeImg"
            className="h-[40px] w-[40px] mr-4"
          />

          <div className="flex-1 flex flex-col justify-center items-center">
            <h4 className="underline text-[18px]">Title</h4>
            <p className="line-clamp-1">Remind! - Notifications </p>
          </div>
        </div>
      ),
      key: "4",
    },

    {
      label: (
        <div className="flex items-center bg-primaryColor p-2 border border-gray-300 shadow">
          <img
            src={fruitImg}
            alt="NoticeImg"
            className="h-[40px] w-[40px] mr-4"
          />

          <div className="flex-1 flex flex-col justify-center items-center">
            <h4 className="underline text-[18px]">Title</h4>
            <p className="line-clamp-1">Remind! - Notifications </p>
          </div>
        </div>
      ),
      key: "5",
    },
  ];

  const menuDrop = (
    <Menu
      items={itemsNotification}
      className="overflow-y-auto max-h-[250px] w-[30rem] scroll-smooth hide-scrollbar"
    />
  );

  return (
    <div>
      <Dropdown
        overlay={menuDrop}
        trigger={["click"]}
        arrow={{ pointAtLeft: true }}
        className="cursor-pointer "
      >
        <Link
          onClick={(e) => e.preventDefault()}
          className="flex items-center"
        >
          <button className="relative bg-pinkColor text-white p-3 rounded-full">
            <span className="absolute top-0 right-0 h-3 w-3 bg-red-600 rounded-full border border-white"></span>
            <FaBell className="w-4 h-4" />
          </button>
        </Link>
      </Dropdown>
    </div>
  );
};

export default Notifications;
