import React, { useState } from 'react'
import { ProfileLayout } from './ProfileLayout'
import avaImg from '../../assets/image/other/avatar.png';

export const DisableAccount = () => {
    const [reason, setReason] = useState('');

    const handleSubmit = () => {
        if (reason.trim() === '') {
            alert('Please provide a reason for disabling your account.');
        } else {
            alert(`Your reason: ${reason}`);
        }
    };

    return (
        <div>
            <ProfileLayout>
                <div className='p-5 lg:w-3/4 md:w-full mx-auto'>
                    <h2 className="text-2xl font-bold mb-6">DISABLE ACCOUNT</h2>
                    <div className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] ml-[40%] mb-5 rounded-full overflow-hidden">
                        <img className="w-full h-full object-cover" src={avaImg} alt="" />
                    </div>
                    <div className='space-y-5'>
                        <div>
                        <p>
                            Dear thongtmde160489-tPd, <br/> <br/>
                             We are sorry that your account has been disabled.
                            Our mission is to provide you with a convenient healthcare journey.
                            We always try our best to support you, and hope that we will have the opportunity
                            to resolve any problems or difficulties you are having with your account.
                        </p>
                        </div>
                        <div>
                            <textarea 
                                id="reason" 
                                name="reason" rows="4" cols="50"
                                placeholder='Please let us know the reason for disabling your account.'
                                className='border-gray-200 border border-solid h-[120px] w-full rounded-2xl px-7 py-3'    
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </div>
                        <button  
                            type="submit"
                            className="w-full bg-pinkColor text-white py-2 px-4 rounded-lg text-lg focus:outline-none"
                            onClick={handleSubmit} 
                        >
                            Disable Account 
                        </button>
                    </div>
                </div>
            </ProfileLayout>
        </div>
    )
}
