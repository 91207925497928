import { useLocation } from "react-router-dom";
import Footer from "../../Footer/Footer"
import Header from "../../Header/Header"
import imageBanner from "../../../assets/image/tool/pwg-result-overweight.png"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import weeksData from "../../../assets/data/weekData";
import { WeightOfMom, WeightOfMomTwin } from "../../../assets/data/WeightOfMom";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomPanel from "../CustomPanel/CustomPanel";
import dataPanel from "../../../assets/data/Panel/DataPanel";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

export const WeightOfPregnantResult = () => {
    const [openPanelIndex, setOpenPanelIndex] = useState(null);
    const information = useLocation();
    const { state } = information

    const recommendWeightLower = useRef([])
    const recommendWeightUpper = useRef([])
    const [recommendWeight, setRecommendWeight] = useState("")

    const resultMess = useRef("");

    const stepLoop = 7;
    const enhancedWeeksData = [];
    for (let i = 0; i < stepLoop; i++) {
        enhancedWeeksData.push(...weeksData);
    }

    const [selectedWeek, setSelectedWeek] = useState(enhancedWeeksData[weeksData.length * Math.floor(stepLoop / 2) + state.weeksPregnant - 1]);

    const indexWeekSelected = useRef(weeksData.length * Math.floor(stepLoop / 2) + state.weeksPregnant - 1)
    const scrollRef = useRef(null);

    useEffect(() => {
        if (state.isTwins) {
            recommendWeightLower.current = WeightOfMomTwin.suggestLower.map(value => value + state.beforeWeight);
            recommendWeightUpper.current = WeightOfMomTwin.suggestUpper.map(value => value + state.beforeWeight);
        } else {
            recommendWeightLower.current = WeightOfMom.suggestLower.map(value => value + state.beforeWeight);
            recommendWeightUpper.current = WeightOfMom.suggestUpper.map(value => value + state.beforeWeight);
        }

        const upperW = recommendWeightUpper.current[state.weeksPregnant - 1]
        const lowerW = recommendWeightLower.current[state.weeksPregnant - 1]

        if (state.currentWeight > upperW) {
            resultMess.current = (`You are <span class='text-pinkColor font-bold'>${state.currentWeight - upperW} kg overweight</span> compared to your healthy weight`)
        } else if (state.currentWeight < lowerW) {
            resultMess.current = (`You are <span class='text-pinkColor font-bold'>${lowerW - state.currentWeight} kg underweight</span> compared to your healthy weight`)
        } else {
            resultMess.current = ("You are at a <span class='text-pinkColor font-bold'>healthy</span> weight")
        }

    }, [])

    useEffect(() => {
        const currentIndex = indexWeekSelected.current;

        if (currentIndex !== -1) {
            scrollToCenter(currentIndex);
        }

        setRecommendWeight(`${recommendWeightLower.current[selectedWeek.week - 1]} - ${recommendWeightUpper.current[selectedWeek.week - 1]}`);
    }, [selectedWeek]);

    const scrollToCenter = (index) => {
        if (!scrollRef.current) return;
    
        const container = scrollRef.current;
        const containerWidth = container.clientWidth;
        
        const item = container.children[index];
        if (!item) return;
    
        const itemWidth = item.clientWidth;
        const itemLeft = item.offsetLeft;
    
        const scrollPosition = itemLeft - (containerWidth / 1.35) + (itemWidth / 1.35);
        container.scrollTo({ left: scrollPosition, behavior: "smooth" });
    };

    const scroll = (direction) => {
        if (direction === "left") {
            scrollRef.current.scrollBy({ left: -150, behavior: "smooth" });
        } else {
            scrollRef.current.scrollBy({ left: 150, behavior: "smooth" });
        }
        getNextWeek(direction);
    };

    const getNextWeek = (direction) => {
        const currentIndex = indexWeekSelected.current

        let nextIndex = direction === "left" ? (currentIndex - 1 + enhancedWeeksData.length) % enhancedWeeksData.length : (currentIndex + 1) % enhancedWeeksData.length;
        indexWeekSelected.current = nextIndex;
        setRecommendWeight(`${recommendWeightLower.current[selectedWeek.week - 1]} - ${recommendWeightUpper.current[selectedWeek.week - 1]}`);
        setSelectedWeek(enhancedWeeksData[nextIndex]);
    };

    const chartData = {
        labels: WeightOfMom.Week,
        datasets: [
            {
                label: 'void value',
                data: WeightOfMom.nullValue,
                borderColor: 'rgba(255,255,255,0)',
                fill: false,
            },
            {
                label: 'Weight upper',
                data: recommendWeightUpper.current,
                borderColor: 'pink',
                fill: false,
            },
            {
                label: 'Weight lower',
                data: recommendWeightLower.current,
                borderColor: 'pink',
                fill: false,
            },
            {
                label: 'Comparison Point',
                data: Array(WeightOfMom.Week.length).fill(null).map((_, index) => index === state.weeksPregnant - 1 ? state.currentWeight : null),
                borderColor: 'rgba(255,0,0,1)',
                backgroundColor: 'rgba(255,0,0,1)',
                pointRadius: 10,
                pointHoverRadius: 8,
                fill: false,
                type: 'scatter',
            }
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context) {
                        return `Weight recommend ${context.dataset.label}: ${context.raw} Kg`;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Week',
                    font: {
                        size: 16,
                    },
                    color: '#333',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Weight',
                    font: {
                        size: 14,
                    },
                    color: '#333',
                },
                grid: {
                    color: '#ccc',
                },
            },
        },
    };
    return (
        <>
            <Header />
            <div className='container mx-auto'>
                <div className="d-flex">
                    <div className="h-[240px] d-flex bg-primaryColor rounded-bl-[300px] rounded-br-[300px] border p-4 justify-center items-center" style={{ display: "flex" }}>
                        <div className="mt-5 mr-5 space-y-6" style={{ maxWidth: "60rem" }}>
                            <h4 className="text-[28px] text-center font-bold">
                                <div dangerouslySetInnerHTML={{ __html: resultMess.current }} />
                            </h4>
                            <p className="text-lg text-center mx-auto w-full max-w-[680px] text-gray-600">
                                Check your weight every week, to track your pregnancy weight gain.
                            </p>
                        </div>
                        <div className="w-[50px]"></div>
                        <img src={imageBanner} alt="Banner" className="ml-5 w-[14%] object-cover" />
                    </div>
                </div>
            </div>
            <div className="relative flex items-center justify-center mb-6">
                <button
                    className="absolute z-10 p-4 rounded-full shadow-md hover:bg-gray-50 transition-colors"
                    style={{ left: "10vw" }}
                    onClick={() => scroll("left")}
                >
                    <FaAngleLeft />
                </button>
                <div
                    ref={scrollRef}
                    className="flex gap-4 m-3 overflow-x-scroll scroll-smooth hide-scrollbar p-4"
                    style={{ width: "100%", maxWidth: "70vw" }}
                >
                    {enhancedWeeksData.map((weekData, index) => (
                        <div
                            key={index}
                            className={`flex flex-col items-center bg-white rounded-[40px] shadow-lg cursor-pointer px-2 py-3 ${selectedWeek.week === weekData.week
                                ? "ring-2 ring-pink-500 scale-110"
                                : ""
                                } transition-transform duration-600 ease-in-out`}
                            style={{ minWidth: "85px" }}
                            onClick={() => {
                                setSelectedWeek(weekData);
                                indexWeekSelected.current = index;
                            }}
                        >
                            <img
                                src={weekData.imageSlide}
                                className="w-16 h-16 md:w-15 md:h-15 rounded-3xl object-cover"
                                alt={`Week ${weekData.week}`}
                            />
                            <h4 className="text-sm font-semibold mt-2">
                                Week {weekData.week}
                            </h4>
                        </div>
                    ))}
                </div>

                <button
                    className="absolute z-10 p-4 rounded-full shadow-md hover:bg-gray-50 transition-colors"
                    style={{ right: "10vw" }}
                    onClick={() => scroll("right")}
                >
                    <FaAngleRight />
                </button>
            </div>
            <div className="flex justify-center mt-4">
                <div className="flex items-center mr-4">
                    <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: "pink" }}></div>
                    Recommend Weight <strong className="ml-1 mr-1">({recommendWeight})</strong> Kg - week <strong className="ml-1"> {selectedWeek.week} </strong>
                </div>
                <div className="flex items-center mr-4">
                    <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: "rgba(255,0,0,1)" }}></div>
                    <span>Your weight</span>
                </div>
            </div>
            <div className="flex justify-center items-center w-[50%] mx-auto">
                <Line className="mt-2 w-[100%]" data={chartData} options={options} />
            </div>
            <div className='space-y-4 container mx-auto'>
                <div className='flex space-x-3'>
                    <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                        <AiOutlineExclamationCircle className="text-[40px]" />
                    </div>
                    <h5>Information</h5>
                </div>

                <div>
                    {dataPanel.weightOfPregnant.result.map((panel, index) => (
                        <CustomPanel
                            key={index}
                            isOpen={openPanelIndex === index}
                            onToggle={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}
                            header={panel.header}
                            content={panel.content}
                        />
                    ))}
                </div>
            </div>
            <Footer />
        </>)
}

