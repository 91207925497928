import React, { useState } from 'react';
import { MyCalendar } from './Calender/MyCalender';
import { Modal } from './Modal';
import { EventForm } from './EventForm';
import { ProfileLayout } from '../Profile/ProfileLayout';

export const Schedule = () => {
    const [events, setEvents] = useState([
        {
            start: new Date(),
            end: new Date(),
            title: 'Sample Event',
            date: '2024-08-28',
            startTime: '12:00',
            endTime: '14:00',
            content: 'Sample Content',
            status: 'Pending',
        },
    ]);

    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setShowModal(true);
    };

    const handleAddEvent = () => {
        setSelectedEvent(null);
        setShowModal(true);
    };

    const handleFormSubmit = (newEvent) => {
        if (selectedEvent) {
            setEvents(events.map(event =>
                event === selectedEvent ? { ...selectedEvent, ...newEvent } : event
            ));
            alert('Updated event')
        } else {
            setEvents([...events, newEvent]);
            alert('Added event')
        }
        
        setShowModal(false);
    };

    return (
        <ProfileLayout>
            <div className="relative">
                <MyCalendar events={events} onSelectEvent={handleSelectEvent} />
                <button
                    className="absolute top-0 right-3 bg-pinkColor text-white px-4 py-2 rounded-lg"
                    onClick={handleAddEvent}
                >
                    Add new action
                </button>

                <Modal show={showModal} onClose={() => setShowModal(false)}>
                    <EventForm onSubmit={handleFormSubmit} selectedEvent={selectedEvent} />
                </Modal>
            </div>
        </ProfileLayout>
    );
};


// import React, { useState } from 'react';
// import { MyCalendar } from './Calender/MyCalender';
// import { Modal } from './Modal';
// import { EventForm } from './EventForm';
// import { ProfileLayout } from '../Profile/ProfileLayout';

// export const Schedule = () => {
//     const [events, setEvents] = useState([
//         {
//             start: new Date(),
//             end: new Date(),
//             title: 'Sample Event',
//             date: '2024-08-28',
//             startTime: '12:00',
//             endTime: '14:00',
//             content: 'Sample Content',
//             status: 'Pending',
//         },
//     ]);

//     const [showModal, setShowModal] = useState(false);
//     const [selectedEvent, setSelectedEvent] = useState(null);

//     const handleSelectEvent = (event) => {
//         setSelectedEvent(event);
//         setShowModal(true);
//     };

//     const handleAddEvent = () => {
//         setSelectedEvent(null);
//         setShowModal(true);
//     };

//     const handleFormSubmit = (newEvent) => {
//         if (selectedEvent) {
//             setEvents(events.map(event =>
//                 event === selectedEvent ? { ...selectedEvent, ...newEvent } : event
//             ));
//         } else {
//             setEvents([...events, newEvent]);
//         }
//         setShowModal(false);
//     };

//     return (
//         <ProfileLayout>
//             <div className="relative">
//                 <MyCalendar events={events} onSelectEvent={handleSelectEvent} />
//                 <button
//                     className="absolute top-0 right-3 bg-pinkColor text-white px-4 py-2 rounded-lg"
//                     onClick={handleAddEvent}
//                 >
//                     Add new action
//                 </button>

//                 <Modal show={showModal} onClose={() => setShowModal(false)}>
//                     <EventForm onSubmit={handleFormSubmit} selectedEvent={selectedEvent} />
//                 </Modal>
//             </div>
//         </ProfileLayout>

//     );
// };
