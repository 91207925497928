import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ProfileLayout } from "../ProfileLayout";

export const ChangePassword = () => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTogglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Kiểm tra form ở đây
    if (formData.newPassword !== formData.confirmPassword) {
      alert("New password and confirm password not match");
      return;
    }else{
      alert("Change password successfully !!!")
    }
    
    console.log("Form submitted", formData);
  };

  return (
    <>
      <ProfileLayout>
        <div className="p-5 lg:w-auto md:w-full mx-auto">
          <h2 className="text-2xl font-bold text-center mb-8">
            CHANGE PASSWORD
          </h2>
          <form onSubmit={handleSubmit} className="space-y-5 w-[80%] mx-[10%]">
            {/** Input for old password */}
            <div className="relative">
              <input
                type={showPassword.oldPassword ? "text" : "password"}
                id="oldPassword"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleInputChange}
                className="peer h-12 w-full px-4 pt-2 border border-gray-400 rounded-md outline-none focus:border-pinkColor"
                placeholder=" "
              />
              <label
                htmlFor="oldPassword"
                className="absolute left-4 top-2 text-gray-500 transition-all duration-300 transform
            -translate-y-4 scale-75 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
            peer-focus:scale-75 peer-focus:-translate-y-4 bg-white px-1"
              >
                Old Password
              </label>
              <span
                className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={() =>
                  handleTogglePasswordVisibility("oldPassword")
                }
              >
                {showPassword.oldPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            {/** Input for new password */}
            <div className="relative mt-6">
          <input
            type={showPassword.newPassword ? "text" : "password"}
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            className="peer h-12 w-full px-4 pt-2 border border-gray-400 rounded-md outline-none focus:border-pinkColor"
            placeholder=" "
          />
          <label
            htmlFor="newPassword"
            className="absolute left-4 top-2 text-gray-500 transition-all duration-300 transform
            -translate-y-4 scale-75 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
            peer-focus:scale-75 peer-focus:-translate-y-4 bg-white px-1"
          >
            New Password
          </label>
          <span
                className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={() =>
                  handleTogglePasswordVisibility("newPassword")
                }
              >
                {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
        </div>

            {/** Input for confirm password */}
            <div className="relative">
              <input
                type={showPassword.confirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder=" "
                className=" peer h-12 w-full px-4 pt-2 border border-gray-400 rounded-md outline-none focus:border-pinkColor"
              />
              <label
                className="absolute left-4 top-2 text-gray-500 transition-all duration-300 transform
            -translate-y-4 scale-75 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 
            peer-focus:scale-75 peer-focus:-translate-y-4 bg-white px-1"
              >
                Confirm Password
              </label>
              <span
                className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={() =>
                  handleTogglePasswordVisibility("confirmPassword")
                }
              >
                {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <button
              type="submit"
              className=" lg:w-[20%] lg:ml-[40%] md:w-full bg-pinkColor text-white font-medium py-2 px-4 rounded-lg hover:bg-pinkColor focus:outline-none"
              onClick={handleSubmit}
            >
              Save change
            </button>
          </form>
        </div>
      </ProfileLayout>
    </>
  );
};
