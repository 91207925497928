import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Col, Row } from "antd";
import { Button, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import banner from "../assets/image/baby/image 2.png";

export const Contact = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto mt-10 px-4 lg:px-[200px] py-4">
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <h1 className="text-[40px] font-bold">Contact us</h1>
            <p className="text-[18px] text-gray-500 py-4">
              Subheading for description or instructions
            </p>
            <Form name="" layout="vertical">
              <div className="flex">
                <FormItem
                  name="firstName"
                  label="First Name"
                  style={{ marginRight: "20px", width: "100%" }}
                  labelCol={{ style: { fontWeight: "bold" } }}
                >
                  <Input placeholder="First Name" type="text" />
                </FormItem>
                <FormItem
                  name="lastName"
                  label="Last Name"
                  style={{ width: "100%" }}
                  labelCol={{ style: { fontWeight: "bold" } }}
                >
                  <Input placeholder="Last Name" type="text" />
                </FormItem>
              </div>
              <FormItem
                name="email"
                label="Email Address"
                style={{ marginRight: "20px", width: "100%" }}
                labelCol={{ style: { fontWeight: "bold" } }}
              >
                <Input placeholder="email@domain.net" type="email" />
              </FormItem>
              <FormItem
                label="Your message"
                style={{ width: "100%" }}
                labelCol={{ style: { fontWeight: "bold" } }}
              >
                <TextArea
                  placeholder="Enter your question or message"
                  type="text"
                  rows={6}
                />
              </FormItem>

              <button className="custom-button mt-4 px-[45%] bg-pinkColor text-white py-2 px-4 rounded-lg text-[18px]">
                Submit
              </button>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="py-4 px-4 flex justify-end lg:justify-end sm:justify-center">
              <img src={banner} alt="Mother and Baby"  className="w-full lg:w-[400px] lg:h-[500px] sm:w-[300px] sm:h-[400px] rounded-lg" />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};
