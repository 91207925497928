
import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { Routers } from './routes/Routers';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      {/* <Header/> */}
       <Routers/>
       {/* <Footer/> */}
    </>
  );
}

export default App;
