import React from 'react';
import { FaFacebook, FaLinkedin, FaYoutube, FaInstagram } from "react-icons/fa";

export default function Footer() {
    return (
        <footer className="bg-gray-50 py-8 md:px-10">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
                    
                    {/* Mom Child Care Section */}
                    <div className="md:col-span-2">
                        <h3 className="font-bold text-lg mb-4">Mom Child Care</h3>
                        <p className="text-sm mb-6">
                            Mom Child Care is an information site for Mothers and Babies, specializing in providing useful knowledge and experience in Conception, 
                            Pregnancy and Child-rearing. To help readers make good decisions in health care, building family life and raising children better.
                        </p>
                        <div className="flex space-x-3">
                            <a href="#"><FaFacebook className="w-6 h-6 text-gray-700 hover:text-gray-900" /></a>
                            <a href="#"><FaLinkedin className="w-6 h-6 text-gray-700 hover:text-gray-900" /></a>
                            <a href="#"><FaYoutube className="w-6 h-6 text-gray-700 hover:text-gray-900" /></a>
                            <a href="#"><FaInstagram className="w-6 h-6 text-gray-700 hover:text-gray-900" /></a>
                        </div>
                    </div>

                    {/* Contact Section */}
                    <div className="md:col-span-1">
                        <h3 className="font-bold text-lg mb-4">Contact</h3>
                        <p className="text-sm mb-2">Number phone: 0123456789</p>
                        <p className="text-sm mb-2">Email: Email@gmail.com</p>
                        <p className="text-sm">Address: Address</p>
                    </div>

                    {/* Main Content Section */}
                    <div className="md:col-span-1">
                        <h3 className="font-bold text-lg mb-4">Main Content</h3>
                        <p className="text-sm mb-2">Check health</p>
                        <p className="text-sm mb-2">Supporting</p>
                        <p className="text-sm">Community</p>
                    </div>

                    {/* Information Section */}
                    <div className="md:col-span-1">
                        <h3 className="font-bold text-lg mb-4">Information</h3>
                        <p className="text-sm mb-2">Terms of Use</p>
                        <p className="text-sm mb-2">Privacy Policy</p>
                        <p className="text-sm mb-2">Editorial and Modification Policy</p>
                        <p className="text-sm mb-2">FAQs</p>
                        <p className="text-sm">Community Standards</p>
                    </div>

                    {/* About Mom Child Care Section */}
                    <div className="md:col-span-1">
                        <h3 className="font-bold text-lg mb-4">About Mom Child Care</h3>
                        <p className="text-sm mb-2">About Us</p>
                        <p className="text-sm mb-2">Teammate</p>
                        <p className="text-sm">Page</p>
                    </div>

                </div>
            </div>
        </footer>
    )
}
