import React, { useEffect, useState } from 'react';

export const EventForm = ({ onSubmit, selectedEvent }) => {
  const [formData, setFormData] = useState({
    date: '',
    startTime: '',
    endTime: '',
    content: '',
    status: 'Pending',
  });

  useEffect(() => {
    if (selectedEvent) {
        setFormData({
            date: selectedEvent.date,
            startTime: selectedEvent.startTime,
            endTime: selectedEvent.endTime,
            content: selectedEvent.content,
            status: selectedEvent.status || 'Pending',
        });
    } else {
        setFormData({
            date: '',
            startTime: '',
            endTime: '',
            content: '',
            status: 'Pending',
        });
    }
}, [selectedEvent]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
        <h2 className='text-center text-[24px] font-bold'>{selectedEvent ? 'Update action' : 'Add new action'} </h2>
      <div>
        <label>Date:</label>
        <input 
          type="date" 
          name="date" 
          value={formData.date} 
          onChange={handleChange} 
          className="w-full border border-gray-300 rounded-lg p-2"
        />
      </div>
      <div className="flex space-x-2">
        <div>
          <label>Time start:</label>
          <input 
            type="time" 
            name="startTime" 
            value={formData.startTime} 
            onChange={handleChange} 
            className="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
        <div>
          <label>Time end:</label>
          <input 
            type="time" 
            name="endTime" 
            value={formData.endTime} 
            onChange={handleChange} 
            className="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
      </div>
      <div>
        <label>Content of work:</label>
        <textarea
          name="content"
          value={formData.content}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-lg p-2"
          rows="4"
        />
      </div>
      {selectedEvent && (
                <div>
                    <label>Status: </label><br/>
                    <select 
                        name="status"
                        value={formData.status} 
                        onChange={handleChange}
                        className='w-full h-10 border border-gray-300 rounded-lg p-2"'
                    >
                        <option value="Pending">Pending</option>
                        <option value="Completed">Completed</option>
                    </select>
                </div>
            )}
            
      <button 
        type="submit" 
        className="w-full bg-pinkColor text-white py-2 px-4 rounded-lg"
      >
        {selectedEvent ? 'Save change' : 'Create'}
      </button>
    </form>
  );
};
