import React from 'react';
import Header from '../Header/Header';
import { DashboardSidebar } from './DashboardSidebar';
import Footer from '../Footer/Footer';

export const ProfileLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="container mx-auto px-3 py-12">
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
          <DashboardSidebar />
          <div className="flex-1">
            {children} 
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}


