export const WeightOfMom = {
    Week: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
    suggestLower: [0, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    suggestUpper: [0.5, 0.5, 0.5, 0.5, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 7, 7, 0],
    nullValue: [35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35]
}

export const WeightOfMomTwin = {
    Week: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
    suggestLower: [0, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 1, 1.5, 2.5, 3, 4, 5, 6.2, 7, 7, 8, 8, 9, 10, 10, 11, 11, 12, 12, 12, 12, 13, 13, 0],
    suggestUpper: [0.5, 0.5, 0.5, 0.5, 2, 2, 2, 2, 2, 7, 7, 7, 7, 7.2, 7.5, 8, 8.5, 9, 10, 10.5, 11.5, 13, 13.5, 14.5, 15.5, 16.5, 17.5, 18, 18.5, 19.5, 20, 21.2, 22.5, 23, 23.5, 25, 26, 27, 28, 29, 0]
}