import React, { useState, useEffect } from 'react';
import toolImg1 from '../../../assets/image/tool/Edd_IVF.png';
import toolImg2 from '../../../assets/image/tool/EDD_Last Menstrual Period.png';
import toolImg3 from '../../../assets/image/tool/EDD_Date of Conception.png';
import PopupEstimate from './PopupEstimate'
import dayjs from 'dayjs';
import './style.css'
import { DatePicker, Select, Radio, Space } from 'antd';
import { DueDateFromLMPWithCycle, DueDateFromConception, DueDateFromIVF } from "./EstimateCalculators";

export const EstimateDate = () => {
    const [activeTool, setActiveTool] = useState('LMP');
    const [lmpDate, setLmpDate] = useState('');
    const [cycleLength, setCycleLength] = useState(0);

    const [docDate, setDocDate] = useState('');

    const [ivfDate, setIvfDate] = useState('');
    const [embryoOption, setEmbryoOption] = useState(3);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [resultCalcullator, setResultCalcullator] = useState({})

    // Update state with today's date when component mounts
    useEffect(() => {
        setLmpDate(dayjs().format('YYYY-MM-DD'));
        setDocDate(dayjs().format('YYYY-MM-DD'));
        setIvfDate(dayjs().format('YYYY-MM-DD'));
    }, []);

    const optionscycleLength = [];
    for (let i = 20; i <= 40; i++) {
        optionscycleLength.push({
            value: i,
            label: i + " Days",
        });
    }

    const handleCycleLength = (value) => {
        setCycleLength(value)
    }

    const handleToolSelect = (tool) => {
        setActiveTool(tool);
    };

    const handleLmpDateChange = (date) => {
        if (date !== null) {
            setLmpDate(date.format('YYYY-MM-DD'));
        } else {
            setLmpDate('');
        }
    }

    const handleDocDateChange = (date) => {
        if (date !== null) {
            setDocDate(date.format('YYYY-MM-DD'));
        } else {
            setDocDate('');
        }
    };

    const handleIvfDateChange = (date) => {
        if (date !== null) {
            setIvfDate(date.format('YYYY-MM-DD'));
        } else {
            setIvfDate('');
        }
    };

    const handleEmbryoOptionChange = (e) => {
        setEmbryoOption(e.target.value);
    };

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleLmpCalculate = () => {
        switch (activeTool) {
            case "LMP":
                setResultCalcullator(DueDateFromLMPWithCycle(lmpDate, cycleLength))
                break;
            case "DOC":
                setResultCalcullator(DueDateFromConception(docDate))
                break;
            case "IVF":
                setResultCalcullator(DueDateFromIVF(ivfDate, embryoOption))
                break;
        }
        setIsPopupOpen(true);
    }
    const handleClosePopup = () => {
        setIsPopupOpen(false);
    }

    return (
        <div>
            <PopupEstimate isOpen={isPopupOpen} onClose={handleClosePopup} resultCalcullator={resultCalcullator} />
            <div>
                {/* <h3 className='text-3xl text-center font-bold mb-4'>Estimated Delivery Date (EDD)</h3> */}
                <div className='bg-gray-100 p-6 rounded-[50px]'>
                    <div className='space-y-5'>
                        <div className='flex justify-between grid sm:grid-cols-2 lg:grid-cols-3 sm:px-2 lg:px-4 '>
                            <div className={`bg-white h-52 sm:h-30 rounded-3xl p-4 w-44 sm:w-25 sm:my-2 md:w-25 md:my-2 lg:my-4 ${activeTool === 'LMP' ? 'ring-2 ring-pinkColor' : ''}`}>
                                <button
                                    onClick={() => handleToolSelect('LMP')} // Last Menstrual Period
                                    className={`${activeTool === 'LMP' ? '' : 'opacity-50'}`}
                                >
                                    <div className="p-3">
                                        <img className='ml-5' src={toolImg2} alt="Last Menstrual Period" />
                                    </div>
                                    <h5 className='text-base text-center'>Last Menstrual Period</h5>
                                </button>
                            </div>

                            <div className={`bg-white h-52 sm:h-50 rounded-3xl p-4 w-44 sm:w-25 md:w-25 md:my-2 sm:my-2 lg:my-4  ${activeTool === 'DOC' ? 'ring-2 ring-pinkColor' : ''}`}>
                                <button
                                    onClick={() => handleToolSelect('DOC')} // Date of Conception
                                    className={`${activeTool === 'DOC' ? '' : 'opacity-50'}`}
                                >
                                    <div className="p-3">
                                        <img className='ml-5' src={toolImg3} alt="Date of Conception" />
                                    </div>
                                    <h5 className='text-base text-center'>Date of Conception</h5>
                                </button>
                            </div>

                            <div className={`bg-white h-52 sm:h-30 rounded-3xl p-4 w-44 sm:w-25 md:w-25 md:my-2 sm:my-2 lg:my-4 ${activeTool === 'IVF' ? 'ring-2 ring-pinkColor' : ''}`}>
                                <button
                                    onClick={() => handleToolSelect('IVF')} // In Vitro Fertilization
                                    className={`${activeTool === 'IVF' ? '' : 'opacity-50'}`}
                                >
                                    <div className="p-3">
                                        <img className='ml-5' src={toolImg1} alt="IVF" />
                                    </div>
                                    <h5 className='text-base text-center'>In vitro fertilization - IVF</h5>
                                </button>
                            </div>
                        </div>

                        {/* Conditionally render forms based on active tool */}
                        {activeTool === 'LMP' && (
                            <div className='space-y-5'>
                                <div className='mt-2'>
                                    <p>First day of most recent period</p>
                                    <DatePicker className='w-full p-2 border border-gray-300 rounded-full'
                                        defaultValue={dayjs()}
                                        onChange={handleLmpDateChange} />
                                </div>
                                <div>
                                    <p>Menstrual cycle length</p>
                                    <Select
                                        size="large"
                                        defaultValue="28 Days"
                                        onChange={handleCycleLength}
                                        style={{
                                            width: '100%',
                                        }}
                                        options={optionscycleLength}
                                    />
                                </div>
                                <button onClick={handleLmpCalculate} className='mt-4  px-[45%] bg-pinkColor text-white py-2 px-4 rounded-full'>Calculate</button>
                            </div>
                        )}

                        {activeTool === 'DOC' && (
                            <div className='space-y-5'>
                                <div className='mt-2'>
                                    <p>Date of conception</p>
                                    <DatePicker className='w-full p-2 border border-gray-300 rounded-full'
                                        defaultValue={dayjs()}
                                        onChange={handleDocDateChange} />
                                </div>
                                <button onClick={handleLmpCalculate} className='mt-4  px-[45%] bg-pinkColor text-white py-2 px-4 rounded-full'>Calculate</button>
                            </div>
                        )}

                        {activeTool === 'IVF' && (
                            <div className='space-y-5'>
                                <div className='mt-2'>
                                    <p>Embryo transfer date</p>
                                    <DatePicker className='w-full p-2 border border-gray-300 rounded-full'
                                        defaultValue={dayjs()}
                                        onChange={handleIvfDateChange} />
                                </div>
                                <div>
                                    <Radio.Group className="custom-radio" onChange={handleEmbryoOptionChange} value={embryoOption}>
                                        <Space direction="vertical">
                                            <Radio value={3}>3 - Embryo transfer date</Radio>
                                            <Radio value={5}>5 - Embryo transfer date</Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <button onClick={handleLmpCalculate} className='mt-4  px-[45%] bg-pinkColor text-white py-2 px-4 rounded-full'>Calculate</button>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};
