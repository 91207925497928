import React from "react";

const MenuMom = ({ color, title, isFirst, isLast }) => {
  return (
    <div
    className={`w-[250px] sm:w-[300px] h-[600px] sm:h-[700px] px-3 sm:px-4 py-4 flex-shrink-0 inline-block mr-2 ${
      isFirst ? "rounded-tl-[50px] rounded-bl-[50px] mr-2" : ""
    } ${isLast ? "rounded-br-[50px] rounded-tr-[50px]" : ""}`}
    style={{ backgroundColor: color }}
  >
    <h5 className="underline text-center text-[20px] sm:text-[25px] font-bold">{title}</h5>
    <div className="ml-3 sm:ml-4 text-[16px] sm:text-[20px]">
      <p className="font-bold py-2">Breakfast</p>
      <ul className="px-3 sm:px-4">
        <li>&#8226; food 1</li>
      </ul>
      <p className="font-bold py-2">Morning snack</p>
      <ul className="px-3 sm:px-4">
        <li>&#8226; food 1</li>
        <li>&#8226; food 1</li>
      </ul>
      <p className="font-bold py-2">Lunch</p>
      <ul className="px-3 sm:px-4">
        <li>&#8226; food 1</li>
        <li>&#8226; food 1</li>
        <li>&#8226; food 1</li>
      </ul>
      <p className="font-bold py-2">Afternoon snack</p>
      <ul className="px-3 sm:px-4">
        <li>&#8226; food 1</li>
        <li>&#8226; food 1</li>
      </ul>
      <p className="font-bold py-2">Dinner</p>
      <ul className="px-3 sm:px-4">
        <li>&#8226; food 1</li>
        <li>&#8226; food 1</li>
        <li>&#8226; food 1</li>
      </ul>
    </div>
  </div>
  );
};

export default MenuMom;
