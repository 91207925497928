export const boyHC = {
    Month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
    P1: [31.5, 34.6, 36.4, 37.8, 38.9, 39.7, 40.5, 41.1, 41.6, 42.1, 42.5, 42.8, 43.1, 43.3, 43.6, 43.8, 44, 44.1, 44.3, 44.4, 44.6, 44.7, 44.8, 45, 45.1, 45.2, 45.3, 45.4, 45.5, 45.6, 45.7, 45.8, 45.9, 45.9, 46, 46.1, 46.2, 46.2, 46.3, 46.3, 46.4, 46.5, 46.5, 46.6, 46.6, 46.7, 46.7, 46.8, 46.8, 46.9, 46.9, 46.9, 47, 47, 47.1, 47.1, 47.1, 47.2, 47.2, 47.2, 47.3],
    P3: [32.1, 35.1, 36.9, 38.3, 39.4, 40.3, 41, 41.7, 42.2, 42.6, 43, 43.4, 43.6, 43.9, 44.1, 44.3, 44.5, 44.7, 44.9, 45, 45.2, 45.3, 45.4, 45.6, 45.7, 45.8, 45.9, 46, 46.1, 46.2, 46.3, 46.4, 46.5, 46.6, 46.6, 46.7, 46.8, 46.9, 46.9, 47, 47, 47.1, 47.2, 47.2, 47.3, 47.3, 47.4, 47.4, 47.5, 47.5, 47.5, 47.6, 47.6, 47.7, 47.7, 47.7, 47.8, 47.8, 47.9, 47.9, 47.9],
    P5: [32.4, 35.4, 37.2, 38.6, 39.7, 40.6, 41.3, 42, 42.5, 42.9, 43.3, 43.7, 44, 44.2, 44.4, 44.7, 44.8, 45, 45.2, 45.3, 45.5, 45.6, 45.8, 45.9, 46, 46.1, 46.2, 46.3, 46.5, 46.6, 46.6, 46.7, 46.8, 46.9, 47, 47.1, 47.1, 47.2, 47.3, 47.3, 47.4, 47.4, 47.5, 47.6, 47.6, 47.7, 47.7, 47.8, 47.8, 47.9, 47.9, 47.9, 48, 48, 48.1, 48.1, 48.1, 48.2, 48.2, 48.2, 48.3],
    P10: [32.8, 35.8, 37.6, 39, 40.1, 41, 41.8, 42.4, 42.9, 43.4, 43.8, 44.1, 44.4, 44.7, 44.9, 45.1, 45.3, 45.5, 45.7, 45.8, 46, 46.1, 46.3, 46.4, 46.5, 46.6, 46.7, 46.8, 47, 47.1, 47.1, 47.2, 47.3, 47.4, 47.5, 47.6, 47.6, 47.7, 47.8, 47.8, 47.9, 48, 48, 48.1, 48.1, 48.2, 48.2, 48.3, 48.3, 48.4, 48.4, 48.5, 48.5, 48.6, 48.6, 48.6, 48.7, 48.7, 48.8, 48.8, 48.8],
    P15: [33.1, 36.1, 37.9, 39.3, 40.4, 41.3, 42.1, 42.7, 43.2, 43.7, 44.1, 44.4, 44.7, 45, 45.2, 45.5, 45.6, 45.8, 46, 46.2, 46.3, 46.4, 46.6, 46.7, 46.8, 47, 47.1, 47.2, 47.3, 47.4, 47.5, 47.6, 47.7, 47.8, 47.8, 47.9, 48, 48.1, 48.1, 48.2, 48.3, 48.3, 48.4, 48.4, 48.5, 48.5, 48.6, 48.6, 48.7, 48.7, 48.8, 48.8, 48.9, 48.9, 49, 49, 49, 49.1, 49.1, 49.2, 49.2],
    P25: [33.6, 36.5, 38.3, 39.7, 40.8, 41.7, 42.5, 43.1, 43.7, 44.2, 44.6, 44.9, 45.2, 45.5, 45.7, 45.9, 46.1, 46.3, 46.5, 46.6, 46.8, 46.9, 47.1, 47.2, 47.3, 47.5, 47.6, 47.7, 47.8, 47.9, 48, 48.1, 48.2, 48.3, 48.3, 48.4, 48.5, 48.6, 48.6, 48.7, 48.8, 48.8, 48.9, 49, 49, 49.1, 49.1, 49.2, 49.2, 49.3, 49.3, 49.4, 49.4, 49.5, 49.5, 49.5, 49.6, 49.6, 49.7, 49.7, 49.7],
    P50: [34.5, 37.3, 39.1, 40.5, 41.6, 42.6, 43.3, 44, 44.5, 45, 45.4, 45.8, 46.1, 46.3, 46.6, 46.8, 47, 47.2, 47.4, 47.5, 47.7, 47.8, 48, 48.1, 48.3, 48.4, 48.5, 48.6, 48.7, 48.8, 48.9, 49, 49.1, 49.2, 49.3, 49.4, 49.5, 49.5, 49.6, 49.7, 49.7, 49.8, 49.9, 49.9, 50, 50.1, 50.1, 50.2, 50.2, 50.3, 50.3, 50.4, 50.4, 50.4, 50.5, 50.5, 50.6, 50.6, 50.7, 50.7, 50.7],
    P75: [35.3, 38.1, 39.9, 41.3, 42.4, 43.4, 44.2, 44.8, 45.4, 45.8, 46.3, 46.6, 46.9, 47.2, 47.5, 47.7, 47.9, 48.1, 48.3, 48.4, 48.6, 48.7, 48.9, 49, 49.2, 49.3, 49.4, 49.5, 49.7, 49.8, 49.9, 50, 50.1, 50.2, 50.3, 50.3, 50.4, 50.5, 50.6, 50.6, 50.7, 50.8, 50.8, 50.9, 51, 51, 51.1, 51.1, 51.2, 51.2, 51.3, 51.3, 51.4, 51.4, 51.5, 51.5, 51.6, 51.6, 51.7, 51.7, 51.7],
    P85: [35.8, 38.5, 40.3, 41.7, 42.9, 43.8, 44.6, 45.3, 45.8, 46.3, 46.7, 47.1, 47.4, 47.7, 47.9, 48.2, 48.4, 48.6, 48.7, 48.9, 49.1, 49.2, 49.4, 49.5, 49.7, 49.8, 49.9, 50, 50.2, 50.3, 50.4, 50.5, 50.6, 50.7, 50.8, 50.8, 50.9, 51, 51.1, 51.2, 51.2, 51.3, 51.4, 51.4, 51.5, 51.6, 51.6, 51.7, 51.7, 51.8, 51.8, 51.9, 51.9, 52, 52, 52.1, 52.1, 52.2, 52.2, 52.2, 52.3],
    P90: [36.1, 38.8, 40.6, 42, 43.2, 44.1, 44.9, 45.6, 46.1, 46.6, 47, 47.4, 47.7, 48, 48.3, 48.5, 48.7, 48.9, 49.1, 49.2, 49.4, 49.6, 49.7, 49.9, 50, 50.1, 50.3, 50.4, 50.5, 50.6, 50.7, 50.8, 50.9, 51, 51.1, 51.2, 51.3, 51.4, 51.4, 51.5, 51.6, 51.7, 51.7, 51.8, 51.9, 51.9, 52, 52, 52.1, 52.1, 52.2, 52.2, 52.3, 52.3, 52.4, 52.4, 52.5, 52.5, 52.6, 52.6, 52.7],
    P95: [36.6, 39.2, 41.1, 42.5, 43.6, 44.5, 45.3, 46, 46.6, 47.1, 47.5, 47.9, 48.2, 48.5, 48.7, 49, 49.2, 49.4, 49.6, 49.7, 49.9, 50.1, 50.2, 50.3, 50.5, 50.6, 50.8, 50.9, 51, 51.1, 51.2, 51.3, 51.4, 51.5, 51.6, 51.7, 51.8, 51.9, 52, 52, 52.1, 52.2, 52.2, 52.3, 52.4, 52.4, 52.5, 52.6, 52.6, 52.7, 52.7, 52.8, 52.8, 52.9, 52.9, 53, 53, 53.1, 53.1, 53.2, 53.2],
    P97: [36.9, 39.5, 41.3, 42.7, 43.9, 44.8, 45.6, 46.3, 46.9, 47.4, 47.8, 48.2, 48.5, 48.8, 49, 49.3, 49.5, 49.7, 49.9, 50, 50.2, 50.4, 50.5, 50.7, 50.8, 50.9, 51.1, 51.2, 51.3, 51.4, 51.6, 51.7, 51.8, 51.9, 52, 52, 52.1, 52.2, 52.3, 52.4, 52.4, 52.5, 52.6, 52.7, 52.7, 52.8, 52.8, 52.9, 53, 53, 53.1, 53.1, 53.2, 53.2, 53.3, 53.3, 53.4, 53.4, 53.5, 53.5, 53.5],
    P99: [37.4, 40, 41.9, 43.3, 44.4, 45.4, 46.2, 46.8, 47.4, 47.9, 48.4, 48.7, 49.1, 49.3, 49.6, 49.8, 50.1, 50.3, 50.5, 50.6, 50.8, 51, 51.1, 51.3, 51.4, 51.6, 51.7, 51.8, 51.9, 52.1, 52.2, 52.3, 52.4, 52.5, 52.6, 52.7, 52.8, 52.8, 52.9, 53, 53.1, 53.2, 53.2, 53.3, 53.4, 53.4, 53.5, 53.6, 53.6, 53.7, 53.7, 53.8, 53.8, 53.9, 53.9, 54, 54, 54.1, 54.1, 54.2, 54.2],
    P999: [38.4, 40.9, 42.8, 44.2, 45.3, 46.3, 47.1, 47.8, 48.4, 48.9, 49.3, 49.7, 50, 50.3, 50.6, 50.8, 51.1, 51.3, 51.5, 51.7, 51.8, 52, 52.2, 52.3, 52.5, 52.6, 52.7, 52.9, 53, 53.1, 53.2, 53.4, 53.5, 53.6, 53.7, 53.8, 53.8, 53.9, 54, 54.1, 54.2, 54.3, 54.3, 54.4, 54.5, 54.5, 54.6, 54.7, 54.7, 54.8, 54.8, 54.9, 55, 55, 55.1, 55.1, 55.2, 55.2, 55.3, 55.3, 55.4]
}

export const girlHC = {
    Month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
    P1: [31.1, 33.8, 35.4, 36.6, 37.6, 38.5, 39.2, 39.8, 40.3, 40.7, 41.1, 41.4, 41.7, 42, 42.2, 42.5, 42.7, 42.9, 43, 43.2, 43.4, 43.5, 43.7, 43.8, 43.9, 44.1, 44.2, 44.3, 44.4, 44.6, 44.7, 44.8, 44.9, 45, 45.1, 45.1, 45.2, 45.3, 45.4, 45.5, 45.5, 45.6, 45.7, 45.7, 45.8, 45.9, 45.9, 46, 46, 46.1, 46.1, 46.2, 46.2, 46.3, 46.3, 46.4, 46.4, 46.5, 46.5, 46.6, 46.6],
    P3: [31.7, 34.3, 36, 37.2, 38.2, 39, 39.7, 40.4, 40.9, 41.3, 41.7, 42, 42.3, 42.6, 42.9, 43.1, 43.3, 43.5, 43.6, 43.8, 44, 44.1, 44.3, 44.4, 44.6, 44.7, 44.8, 44.9, 45.1, 45.2, 45.3, 45.4, 45.5, 45.6, 45.7, 45.8, 45.9, 45.9, 46, 46.1, 46.2, 46.2, 46.3, 46.4, 46.4, 46.5, 46.5, 46.6, 46.7, 46.7, 46.8, 46.8, 46.9, 46.9, 47, 47, 47.1, 47.1, 47.2, 47.2, 47.2],
    P5: [31.9, 34.6, 36.3, 37.5, 38.5, 39.3, 40.1, 40.7, 41.2, 41.6, 42, 42.4, 42.7, 42.9, 43.2, 43.4, 43.6, 43.8, 44, 44.1, 44.3, 44.5, 44.6, 44.7, 44.9, 45, 45.2, 45.3, 45.4, 45.5, 45.6, 45.7, 45.8, 45.9, 46, 46.1, 46.2, 46.3, 46.3, 46.4, 46.5, 46.6, 46.6, 46.7, 46.8, 46.8, 46.9, 46.9, 47, 47.1, 47.1, 47.2, 47.2, 47.3, 47.3, 47.4, 47.4, 47.4, 47.5, 47.5, 47.6],
    P10: [32.4, 35, 36.7, 37.9, 39, 39.8, 40.5, 41.1, 41.7, 42.1, 42.5, 42.9, 43.2, 43.4, 43.7, 43.9, 44.1, 44.3, 44.5, 44.6, 44.8, 45, 45.1, 45.3, 45.4, 45.5, 45.7, 45.8, 45.9, 46, 46.1, 46.2, 46.3, 46.4, 46.5, 46.6, 46.7, 46.8, 46.9, 46.9, 47, 47.1, 47.1, 47.2, 47.3, 47.3, 47.4, 47.5, 47.5, 47.6, 47.6, 47.7, 47.7, 47.8, 47.8, 47.9, 47.9, 48, 48, 48.1, 48.1],
    P15: [32.7, 35.3, 37, 38.2, 39.3, 40.1, 40.8, 41.5, 42, 42.4, 42.8, 43.2, 43.5, 43.8, 44, 44.2, 44.4, 44.6, 44.8, 45, 45.1, 45.3, 45.4, 45.6, 45.7, 45.9, 46, 46.1, 46.3, 46.4, 46.5, 46.6, 46.7, 46.8, 46.9, 47, 47, 47.1, 47.2, 47.3, 47.4, 47.4, 47.5, 47.6, 47.6, 47.7, 47.7, 47.8, 47.9, 47.9, 48, 48, 48.1, 48.1, 48.2, 48.2, 48.3, 48.3, 48.4, 48.4, 48.4],
    P25: [33.1, 35.8, 37.4, 38.7, 39.7, 40.6, 41.3, 41.9, 42.5, 42.9, 43.3, 43.7, 44, 44.3, 44.5, 44.7, 44.9, 45.1, 45.3, 45.5, 45.6, 45.8, 46, 46.1, 46.2, 46.4, 46.5, 46.6, 46.8, 46.9, 47, 47.1, 47.2, 47.3, 47.4, 47.5, 47.6, 47.6, 47.7, 47.8, 47.9, 47.9, 48, 48.1, 48.1, 48.2, 48.3, 48.3, 48.4, 48.4, 48.5, 48.5, 48.6, 48.6, 48.7, 48.7, 48.8, 48.8, 48.9, 48.9, 49],
    P50: [33.9, 36.5, 38.3, 39.5, 40.6, 41.5, 42.2, 42.8, 43.4, 43.8, 44.2, 44.6, 44.9, 45.2, 45.4, 45.7, 45.9, 46.1, 46.2, 46.4, 46.6, 46.7, 46.9, 47, 47.2, 47.3, 47.5, 47.6, 47.7, 47.8, 47.9, 48, 48.1, 48.2, 48.3, 48.4, 48.5, 48.6, 48.7, 48.7, 48.8, 48.9, 49, 49, 49.1, 49.2, 49.2, 49.3, 49.3, 49.4, 49.4, 49.5, 49.5, 49.6, 49.6, 49.7, 49.7, 49.8, 49.8, 49.9, 49.9],
    P75: [34.7, 37.3, 39.1, 40.4, 41.4, 42.3, 43.1, 43.7, 44.3, 44.7, 45.1, 45.5, 45.8, 46.1, 46.3, 46.6, 46.8, 47, 47.2, 47.3, 47.5, 47.7, 47.8, 48, 48.1, 48.3, 48.4, 48.5, 48.7, 48.8, 48.9, 49, 49.1, 49.2, 49.3, 49.4, 49.5, 49.5, 49.6, 49.7, 49.8, 49.8, 49.9, 50, 50.1, 50.1, 50.2, 50.2, 50.3, 50.3, 50.4, 50.5, 50.5, 50.6, 50.6, 50.7, 50.7, 50.7, 50.8, 50.8, 50.9],
    P85: [35.1, 37.8, 39.5, 40.8, 41.9, 42.8, 43.5, 44.2, 44.7, 45.2, 45.6, 46, 46.3, 46.6, 46.8, 47.1, 47.3, 47.5, 47.7, 47.8, 48, 48.2, 48.3, 48.5, 48.6, 48.8, 48.9, 49, 49.2, 49.3, 49.4, 49.5, 49.6, 49.7, 49.8, 49.9, 50, 50.1, 50.1, 50.2, 50.3, 50.4, 50.4, 50.5, 50.6, 50.6, 50.7, 50.7, 50.8, 50.9, 50.9, 51, 51, 51.1, 51.1, 51.2, 51.2, 51.3, 51.3, 51.4, 51.4],
    P90: [35.4, 38, 39.8, 41.1, 42.2, 43.1, 43.9, 44.5, 45.1, 45.5, 46, 46.3, 46.6, 46.9, 47.2, 47.4, 47.6, 47.8, 48, 48.2, 48.4, 48.5, 48.7, 48.8, 49, 49.1, 49.2, 49.4, 49.5, 49.6, 49.7, 49.8, 49.9, 50, 50.1, 50.2, 50.3, 50.4, 50.5, 50.6, 50.6, 50.7, 50.8, 50.8, 50.9, 51, 51, 51.1, 51.2, 51.2, 51.3, 51.3, 51.4, 51.4, 51.5, 51.5, 51.6, 51.6, 51.7, 51.7, 51.7],
    P95: [35.8, 38.5, 40.2, 41.6, 42.7, 43.6, 44.3, 45, 45.6, 46, 46.4, 46.8, 47.1, 47.4, 47.7, 47.9, 48.1, 48.3, 48.5, 48.7, 48.9, 49, 49.2, 49.3, 49.5, 49.6, 49.8, 49.9, 50, 50.1, 50.2, 50.4, 50.5, 50.6, 50.7, 50.7, 50.8, 50.9, 51, 51.1, 51.2, 51.2, 51.3, 51.4, 51.4, 51.5, 51.6, 51.6, 51.7, 51.7, 51.8, 51.8, 51.9, 51.9, 52, 52, 52.1, 52.1, 52.2, 52.2, 52.3],
    P97: [36.1, 38.8, 40.5, 41.9, 43, 43.9, 44.6, 45.3, 45.9, 46.3, 46.8, 47.1, 47.5, 47.7, 48, 48.2, 48.5, 48.7, 48.8, 49, 49.2, 49.4, 49.5, 49.7, 49.8, 49.9, 50.1, 50.2, 50.3, 50.5, 50.6, 50.7, 50.8, 50.9, 51, 51.1, 51.2, 51.3, 51.3, 51.4, 51.5, 51.6, 51.6, 51.7, 51.8, 51.8, 51.9, 51.9, 52, 52.1, 52.1, 52.2, 52.2, 52.3, 52.3, 52.4, 52.4, 52.5, 52.5, 52.6, 52.6],
    P99: [36.6, 39.3, 41.1, 42.4, 43.5, 44.5, 45.2, 45.9, 46.5, 46.9, 47.4, 47.7, 48.1, 48.3, 48.6, 48.8, 49.1, 49.3, 49.5, 49.6, 49.8, 50, 50.1, 50.3, 50.4, 50.6, 50.7, 50.8, 51, 51.1, 51.2, 51.3, 51.4, 51.5, 51.6, 51.7, 51.8, 51.9, 52, 52, 52.1, 52.2, 52.3, 52.3, 52.4, 52.5, 52.5, 52.6, 52.6, 52.7, 52.7, 52.8, 52.9, 52.9, 53, 53, 53.1, 53.1, 53.1, 53.2, 53.2],
    P999: [37.5, 40.2, 42, 43.4, 44.5, 45.4, 46.2, 46.9, 47.5, 48, 48.4, 48.8, 49.1, 49.4, 49.7, 49.9, 50.1, 50.3, 50.5, 50.7, 50.9, 51, 51.2, 51.3, 51.5, 51.6, 51.8, 51.9, 52, 52.2, 52.3, 52.4, 52.5, 52.6, 52.7, 52.8, 52.9, 53, 53, 53.1, 53.2, 53.3, 53.3, 53.4, 53.5, 53.5, 53.6, 53.7, 53.7, 53.8, 53.8, 53.9, 53.9, 54, 54, 54.1, 54.1, 54.2, 54.2, 54.3, 54.3]
}