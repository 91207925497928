import React from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';

export const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-10 rounded-lg shadow-lg relative">
        <button className="absolute top-2 right-2" onClick={onClose}>
          <FaRegTimesCircle className='w-6 h-6 m-2' />
        </button>
        {children}
      </div>
    </div>
  );
};
