// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.rbc-toolbar button {
    background-color: #007BFF; 
    color: white;              
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .rbc-toolbar button:active {
    background-color: #0062CC;
    color: white;
}
  
  .rbc-toolbar button:hover {
    background-color: #038FC7; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Schedule/Calender/style.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;AAChB;;EAEE;IACE,yBAAyB;EAC3B","sourcesContent":["\n.rbc-toolbar button {\n    background-color: #007BFF; \n    color: white;              \n    border: none;\n    padding: 5px 10px;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n\n  .rbc-toolbar button:active {\n    background-color: #0062CC;\n    color: white;\n}\n  \n  .rbc-toolbar button:hover {\n    background-color: #038FC7; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
