import React, { useState } from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import CustomPanel from '../CustomPanel/CustomPanel';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import Banner from '../ToolBanner/Banner';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form, Input, Slider } from 'antd';
import dataPanel from '../../../assets/data/Panel/DataPanel';

export const WeightOfPregnant = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [openPanelIndex, setOpenPanelIndex] = useState(null);
  const [formData, setFormData] = useState({
    beforeWeight: null,
    height: null,
    currentWeight: null,
    weeksPregnant: 16,
    isTwins: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    const filteredValue = type === 'checkbox' ? checked : value !== null ? value.replace(/[^0-9.]/g, '') : null;

    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : filteredValue === '' ? '' : Number(filteredValue),
    }));
  };

  const handleSubmit = (e) => {
    navigate("/tool/weight/result", { state: formData });
  };

  return (
    <>
      <Header />
      <div className='container mx-auto'>
        <Banner
          header="Weight gain during pregnancy"
          content={
            <>
              <div className='space-y-4'>
                <p className='text-lg hidden sm:block'>
                  This tool is specifically for women who want to know what their healthy weight
                  <br className='hidden sm:block' />
                  gain range is during pregnancy based on their pre-pregnancy weight.
                </p>
                <p className='text-base lg:text-xl hidden sm:block font-semibold text-blueColor'>
                  Medical consultation: Doctor Ta Trung Kien September 24, 2021
                </p>
              </div>
            </>
          }
        />

        <div className='grid lg:grid-cols-2 ms:grid-cols-1 lg:flex-row my-10 px-8 sm:my-16 space-y-10 lg:space-y-0 lg:space-x-10'>
          {/** Input information */}
          <div className='bg-gray-100 h-[590px] lg:w-full p-6 rounded-[30px] sm:rounded-[50px]'>
            <Form form={form} className='space-y-5' onFinish={handleSubmit} initialValues={formData}>
              {/* Weeks Pregnant */}
              <div className='mt-2 h-20 py-2 px-6 bg-white rounded-2xl space-y-4'>
                <h5>How many weeks pregnant are you?</h5>
                <div className='flex items-center'>
                  {formData.weeksPregnant}
                  <Slider
                    min={1}
                    max={40}
                    value={formData.weeksPregnant}
                    onChange={(value) => setFormData(prev => ({ ...prev, weeksPregnant: value }))}
                    className='w-full ml-2'
                  />
                </div>
              </div>

              {/* Weight Before Pregnancy */}
              <div className="relative">
                <Form.Item
                  rules={[{ required: true, message: 'Please enter your weight before pregnancy!' }]}
                  className='mb-8'
                >
                  {formData.beforeWeight > 0 && (
                    <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 rounded px-1" style={{ zIndex: '100' }}>
                      What was your weight before pregnancy? (kg)
                    </label>
                  )}
                  <Input
                    type='text'
                    autoFocus="autoFocus"
                    inputMode="numeric"
                    pattern="\d*"
                    min={1}
                    name="beforeWeight"
                    className="w-full h-16 sm:h-20 rounded-2xl px-4 border"
                    onChange={handleInputChange}
                    value={formData.beforeWeight}
                    placeholder='What was your weight before pregnancy? (kg)'
                  />
                </Form.Item>
              </div>

              {/* Height */}
              <div className="relative">
                <Form.Item
                  rules={[{ required: true, message: 'Please enter your height!' }]}
                  className='mb-8'
                >
                  {formData.height > 0 && (
                    <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 rounded px-1" style={{ zIndex: '100' }}>
                      How tall are you? (cm)
                    </label>
                  )}
                  <Input
                    type='text'
                    inputMode="numeric"
                    autoFocus="autoFocus"
                    pattern="\d*"
                    min={1}
                    name="height"
                    className="w-full h-16 sm:h-20 rounded-2xl px-4 border"
                    onChange={handleInputChange}
                    value={formData.height}
                    placeholder='How tall are you? (cm)'
                  />
                </Form.Item>
              </div>

              {/* Current Weight */}
              <div className="relative">
                <Form.Item
                  rules={[{ required: true, message: 'Please enter your current weight!' }]}
                  className='mb-8'
                >
                  {formData.currentWeight > 0 && (
                    <label className="absolute text-xs text-gray-600 -top-2 left-4 bg-gray-100 rounded px-1" style={{ zIndex: '100' }}>
                      What is your current weight? (kg)
                    </label>
                  )}
                  <Input
                    type='text'
                    inputMode="numeric"
                    autoFocus="autoFocus"
                    pattern="\d*"
                    min={1}
                    name="currentWeight"
                    className="w-full h-16 sm:h-20 rounded-2xl px-4 border"
                    onChange={handleInputChange}
                    value={formData.currentWeight}
                    placeholder='What is your current weight? (kg)'
                  />
                </Form.Item>
              </div>

              {/* Pregnancy with twins */}
              <div className='px-2'>
                <Form.Item
                  name="isTwins"
                  valuePropName="checked"
                >
                  <Checkbox name="isTwins" onChange={handleInputChange} checked={formData.isTwins}>
                    Are you pregnant with twins?
                  </Checkbox>
                </Form.Item>
              </div>

              {/* Calculate Button */}
              <Form.Item>
                <Button className='btn-hover-no-change bg-pinkColor text-white py-2 w-full rounded-full' htmlType='submit'>
                  Calculate
                </Button>
              </Form.Item>
            </Form>
          </div>


          {/** Show Information */}
          <div className='space-y-8'>
            <div className='space-y-5'>
              <div className='flex items-center space-x-3'>
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                  <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <h5>Disclaimer</h5>
              </div>

              <div>
                <p className='font-bold'>
                  This tool does not provide medical advice and is for informational purposes only. Never disregard professional medical advice when seeking treatment because of information you have read on the MarryBaby website. Remember that every pregnancy is different.
                </p>
              </div>
            </div>

            <div className='space-y-4'>
              <div className='flex space-x-3'>
                <div className="bg-pinkColor text-white rounded-full w-8 h-8 flex justify-center items-center">
                  <AiOutlineExclamationCircle className="text-[40px]" />
                </div>
                <h5>Information</h5>
              </div>

              <div>
                {dataPanel.weightOfPregnant.toolPage.map((panel, index) => (
                  <CustomPanel
                    key={index}
                    isOpen={openPanelIndex === index}
                    onToggle={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}
                    header={panel.header}
                    content={panel.content}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
