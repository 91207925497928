import React from 'react'
import Header from '../../components/Header/Header'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const NursingRegister = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Header/>
      <Link className="btn btn-light my-3 flex font-bold m-3" onClick={goBack}>
        <AiOutlineArrowLeft className="mr-1 font-bold text-[22px] mt-[1px]" />
        Previous
      </Link>
      
      
      <div className="flex items-center justify-center p-8">
        <div className="container mx-auto w-full lg:px-[300px] md:px-[80px]">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">Detailed information for nursing</h4>
            <p className="text-sm">
              Enter your information
            </p>
            <form className="space-y-3">
             <div className=' space-x-3'>
                <input
                disabled
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    type="text"
                    placeholder="Full Name*: "
                />
             </div>

             <div className='flex space-x-3'>
                <input
                  disabled
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    type="email"
                    placeholder="Email*: "
                />
                <input
                disabled
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    type="text"
                    placeholder="Number Phone*:"
                />
             </div>

                <input
                disabled
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    type="date"
                    placeholder="Date Of Birth*: "
                />

             <input
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    type="text"
                    placeholder="Address*:"
                />

            <textarea
                className="w-full p-3 border border-gray-300 rounded-lg"
                type="text"
                placeholder="Introduce yourself*:"
                rows={5}
            />  


              <button
                className="bg-pinkColor py-3 w-full text-white font-bold rounded-lg"
                type="button"
              >
                Next
              </button>
            </form>


          </div>
        </div>
      </div>
    </>
  )
}

export default NursingRegister
