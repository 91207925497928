import React, { useRef, useState, useEffect } from "react";
import weeksData from "../../../assets/data/weekData";
import "./style.css";
import { FaAngleLeft, FaAngleRight, FaRuler, FaWeight } from "react-icons/fa";

export const PregnancyWeek = () => {
  const stepLoop = 7;
  const enhancedWeeksData = [];
  for (let i = 0; i < stepLoop; i++) {
    enhancedWeeksData.push(...weeksData);
  }

  const [selectedWeek, setSelectedWeek] = useState(enhancedWeeksData[weeksData.length * Math.floor(stepLoop/2)]);

  const indexWeekSelected = useRef(weeksData.length * Math.floor(stepLoop/2))
  const scrollRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDragging.current = false;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;

    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX.current) * 2; // Scroll speed multiplier
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const scrollToCenter = (index) => {
    const containerWidth = scrollRef.current.clientWidth;
    const itemWidth = scrollRef.current.children[index].clientWidth;

    const adjustedIndex = Math.max(Math.min(index - 2, enhancedWeeksData.length), 0);

    const item = scrollRef.current.children[adjustedIndex];

    if (!item) return;

    const itemLeft = item.offsetLeft;
    const scrollPosition = itemLeft - containerWidth / 2 + itemWidth / 2;

    scrollRef.current.scrollTo({ left: scrollPosition, behavior: "smooth" });
  };

  const getNextWeek = (direction) => {
    const currentIndex = indexWeekSelected.current

    let nextIndex = direction === "left" ? (currentIndex - 1 + enhancedWeeksData.length) % enhancedWeeksData.length : (currentIndex + 1) % enhancedWeeksData.length;
    indexWeekSelected.current = nextIndex;
    setSelectedWeek(enhancedWeeksData[nextIndex]);
  };

  const handleScroll = () => {
    const maxScrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;

    if (scrollRef.current.scrollLeft <= 0) {
      scrollRef.current.scrollLeft = maxScrollLeft / 3;
    } else if (scrollRef.current.scrollLeft > maxScrollLeft) {
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth / 3;
    }
  };

  const scroll = (direction) => {
    if (direction === "left") {
      scrollRef.current.scrollBy({ left: -150, behavior: "smooth" });
    } else {
      scrollRef.current.scrollBy({ left: 150, behavior: "smooth" });
    }
    getNextWeek(direction);
  };

  useEffect(() => {
    const currentIndex = indexWeekSelected.current;

    if (currentIndex !== -1) {
      scrollToCenter(currentIndex);
    }
  }, [selectedWeek]);

  return (
    <div>
      <div className="mt-10 mx-6 bg-[#F1F6FF] p-6 rounded-[40px] shadow-lg text-center">
        <h3 className="text-2xl font-bold mb-4">40 Weeks Pregnant</h3>

        <div className="relative flex items-center justify-center mb-6">
          <button
            className="absolute z-10 p-4 rounded-full shadow-md hover:bg-gray-50 transition-colors"
            style={{ left: "5rem" }}
            onClick={() => scroll("left")}
          >
            <FaAngleLeft />
          </button>
          <div
            ref={scrollRef}
            className="flex gap-4 m-3 overflow-x-scroll scroll-smooth hide-scrollbar p-4"
            style={{ width: "100%", maxWidth: "1000px" }}
            onMouseMove={handleMouseMove}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
          >
            {enhancedWeeksData.map((weekData, index) => (
              <div
                key={index}
                className={`flex flex-col items-center bg-white rounded-[40px] shadow-lg cursor-pointer px-2 py-3 ${selectedWeek.week === weekData.week
                  ? "ring-2 ring-pink-500 scale-110"
                  : ""
                  } transition-transform duration-600 ease-in-out`}
                style={{ minWidth: "85px" }}
                onClick={() => {
                  setSelectedWeek(weekData);
                  indexWeekSelected.current = index;
                }}
              >
                <img
                  src={weekData.imageSlide}
                  className="w-16 h-16 md:w-15 md:h-15 rounded-3xl object-cover"
                  alt={`Week ${weekData.week}`}
                />
                <h4 className="text-sm font-semibold mt-2">
                  Week {weekData.week}
                </h4>
              </div>
            ))}
          </div>

          <button
            className="absolute z-10 p-4 rounded-full shadow-md hover:bg-gray-50 transition-colors"
            style={{ right: "5rem" }}
            onClick={() => scroll("right")}
          >
            <FaAngleRight />
          </button>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center mt-6">

          <img
            src={selectedWeek.image}
            alt={`Week ${selectedWeek.week}`}
            className="w-40 h-50 md:w-60 md:h-60 object-cover"
          />
          <div>
            <h4 className="text-2xl font-bold">Week {selectedWeek.week}</h4>
            <p
              className="text-md mb-2"
              style={{ width: "350px", whiteSpace: "wrap" }}
            >
              {selectedWeek.information}
            </p>
            <div className="bg-[#06ADEF] py-8 px-6 w-72 text-start rounded-3xl shadow-lg ml-0 md:ml-6 mt-4 md:mt-0 space-y-2">
              <div>
                <div className="flex space-x-1">
                  <FaRuler />
                  <p className="text-sm font-medium">Height:</p>
                </div>
                <p className="text-sm font-semibold text-gray-200">
                  {selectedWeek.description.height
                    ? `${selectedWeek.description.height}`
                    : "_cm"}
                </p>
              </div>
              <div>
                <div className="flex space-x-1">
                  <FaWeight />
                  <p className="text-sm font-medium">Weight: </p>
                </div>
                <p className="text-sm font-semibold text-gray-200">
                  {selectedWeek.description.weight
                    ? `${selectedWeek.description.weight}`
                    : "_kg"}
                  {selectedWeek.describe ? ` - ${selectedWeek.describe}` : ""}
                </p>
              </div>
            </div>
            <a
              href="#"
              className="mt-3 text-blueColor underline decoration-transparent hover:decoration-blueColor hover:underline-offset-2 hover:decoration-solid"
            >
              More information
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};