export const boyHFA = {
    Month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
    P1: [45.5, 50.2, 53.8, 56.7, 59, 61, 62.6, 64.1, 65.5, 66.8, 68, 69.1, 70.2, 71.3, 72.3, 73.3, 74.2, 75.1, 76, 76.8, 77.7, 78.4, 79.2, 80, 80.7, 80.7, 81.4, 82.1, 82.8, 83.4, 84, 84.6, 85.2, 85.8, 86.4, 86.9, 87.5, 88, 88.5, 89.1, 89.6, 90.1, 90.6, 91.1, 91.6, 92.1, 92.6, 93.1, 93.6, 94, 94.5, 95, 95.5, 95.9, 96.4, 96.9, 97.3, 97.8, 98.3, 98.7, 99.2],
    P3: [46.3, 51.1, 54.7, 57.6, 60, 61.9, 63.6, 65.1, 66.5, 67.7, 69, 70.2, 71.3, 72.4, 73.4, 74.4, 75.4, 76.3, 77.2, 78.1, 78.9, 79.7, 80.5, 81.3, 82.1, 82.1, 82.8, 83.5, 84.2, 84.9, 85.5, 86.2, 86.8, 87.4, 88, 88.5, 89.1, 89.7, 90.2, 90.8, 91.3, 91.9, 92.4, 92.9, 93.4, 93.9, 94.4, 94.9, 95.4, 95.9, 96.4, 96.9, 97.4, 97.9, 98.4, 98.8, 99.3, 99.8, 100.3, 100.8, 101.2],
    P5: [46.8, 51.5, 55.1, 58.1, 60.5, 62.4, 64.1, 65.6, 67, 68.3, 69.5, 70.7, 71.8, 72.9, 74, 75, 76, 76.9, 77.8, 78.7, 79.6, 80.4, 81.2, 82, 82.8, 82.8, 83.6, 84.3, 85, 85.7, 86.3, 87, 87.6, 88.2, 88.8, 89.4, 90, 90.6, 91.1, 91.7, 92.2, 92.8, 93.3, 93.9, 94.4, 94.9, 95.4, 95.9, 96.4, 96.9, 97.4, 97.9, 98.4, 98.9, 99.4, 99.9, 100.4, 100.9, 101.4, 101.9, 102.3],
    P10: [47.5, 52.2, 55.9, 58.8, 61.2, 63.2, 64.9, 66.4, 67.8, 69.1, 70.4, 71.6, 72.7, 73.8, 74.9, 75.9, 76.9, 77.9, 78.8, 79.7, 80.6, 81.5, 82.3, 83.1, 83.9, 84, 84.7, 85.5, 86.2, 86.9, 87.6, 88.2, 88.9, 89.5, 90.1, 90.7, 91.3, 91.9, 92.5, 93.1, 93.7, 94.2, 94.8, 95.3, 95.9, 96.4, 96.9, 97.4, 98, 98.5, 99, 99.5, 100, 100.5, 101, 101.5, 102, 102.5, 103, 103.5, 104],
    P15: [47.9, 52.7, 56.4, 59.3, 61.7, 63.7, 65.4, 66.9, 68.3, 69.6, 70.9, 72.1, 73.3, 74.4, 75.5, 76.5, 77.5, 78.5, 79.5, 80.4, 81.3, 82.2, 83, 83.8, 84.6, 84.7, 85.5, 86.3, 87, 87.7, 88.4, 89.1, 89.7, 90.4, 91, 91.6, 92.2, 92.8, 93.4, 94, 94.6, 95.2, 95.7, 96.3, 96.8, 97.4, 97.9, 98.5, 99, 99.5, 100, 100.5, 101.1, 101.6, 102.1, 102.6, 103.1, 103.6, 104.1, 104.7, 105.2],
    P25: [48.6, 53.4, 57.1, 60.1, 62.5, 64.5, 66.2, 67.7, 69.1, 70.5, 71.7, 73, 74.1, 75.3, 76.4, 77.4, 78.5, 79.5, 80.4, 81.4, 82.3, 83.2, 84.1, 84.9, 85.8, 85.9, 86.7, 87.4, 88.2, 88.9, 89.6, 90.3, 91, 91.7, 92.3, 93, 93.6, 94.2, 94.8, 95.4, 96, 96.6, 97.2, 97.7, 98.3, 98.9, 99.4, 100, 100.5, 101, 101.6, 102.1, 102.6, 103.2, 103.7, 104.2, 104.7, 105.3, 105.8, 106.3, 106.8],
    P50: [49.9, 54.7, 58.4, 61.4, 63.9, 65.9, 67.6, 69.2, 70.6, 72, 73.3, 74.5, 75.7, 76.9, 78, 79.1, 80.2, 81.2, 82.3, 83.2, 84.2, 85.1, 86, 86.9, 87.8, 88, 88.8, 89.6, 90.4, 91.2, 91.9, 92.7, 93.4, 94.1, 94.8, 95.4, 96.1, 96.7, 97.4, 98, 98.6, 99.2, 99.9, 100.4, 101, 101.6, 102.2, 102.8, 103.3, 103.9, 104.4, 105, 105.6, 106.1, 106.7, 107.2, 107.8, 108.3, 108.9, 109.4, 110],
    P75: [51.2, 56, 59.8, 62.8, 65.3, 67.3, 69.1, 70.6, 72.1, 73.5, 74.8, 76.1, 77.4, 78.6, 79.7, 80.9, 82, 83, 84.1, 85.1, 86.1, 87.1, 88, 89, 89.9, 90.1, 90.9, 91.8, 92.6, 93.4, 94.2, 95, 95.7, 96.5, 97.2, 97.9, 98.6, 99.3, 99.9, 100.6, 101.3, 101.9, 102.5, 103.1, 103.8, 104.4, 105, 105.6, 106.2, 106.7, 107.3, 107.9, 108.5, 109.1, 109.6, 110.2, 110.8, 111.4, 111.9, 112.5, 113.1],
    P85: [51.8, 56.7, 60.5, 63.5, 66, 68.1, 69.8, 71.4, 72.9, 74.3, 75.6, 77, 78.2, 79.4, 80.6, 81.8, 82.9, 84, 85.1, 86.1, 87.1, 88.1, 89.1, 90, 91, 91.2, 92.1, 93, 93.8, 94.7, 95.5, 96.2, 97, 97.8, 98.5, 99.2, 99.9, 100.6, 101.3, 102, 102.7, 103.3, 104, 104.6, 105.2, 105.8, 106.5, 107.1, 107.7, 108.3, 108.9, 109.5, 110.1, 110.7, 111.2, 111.8, 112.4, 113, 113.6, 114.2, 114.8],
    P90: [52.3, 57.2, 61, 64, 66.6, 68.6, 70.4, 71.9, 73.4, 74.8, 76.2, 77.5, 78.8, 80, 81.2, 82.4, 83.5, 84.6, 85.7, 86.8, 87.8, 88.8, 89.8, 90.8, 91.7, 92, 92.9, 93.8, 94.6, 95.5, 96.3, 97.1, 97.9, 98.6, 99.4, 100.1, 100.8, 101.5, 102.2, 102.9, 103.6, 104.3, 104.9, 105.6, 106.2, 106.8, 107.5, 108.1, 108.7, 109.3, 109.9, 110.5, 111.1, 111.7, 112.3, 112.9, 113.5, 114.1, 114.7, 115.3, 115.9],
    P95: [53, 57.9, 61.7, 64.8, 67.3, 69.4, 71.1, 72.7, 74.2, 75.7, 77, 78.4, 79.7, 80.9, 82.1, 83.3, 84.5, 85.6, 86.7, 87.8, 88.8, 89.9, 90.9, 91.9, 92.8, 93.1, 94, 94.9, 95.8, 96.7, 97.5, 98.4, 99.2, 99.9, 100.7, 101.4, 102.2, 102.9, 103.6, 104.3, 105, 105.7, 106.4, 107, 107.7, 108.3, 109, 109.6, 110.2, 110.8, 111.5, 112.1, 112.7, 113.3, 113.9, 114.5, 115.2, 115.8, 116.4, 117, 117.6],
    P97: [53.4, 58.4, 62.2, 65.3, 67.8, 69.9, 71.6, 73.2, 74.7, 76.2, 77.6, 78.9, 80.2, 81.5, 82.7, 83.9, 85.1, 86.2, 87.3, 88.4, 89.5, 90.5, 91.6, 92.6, 93.6, 93.8, 94.8, 95.7, 96.6, 97.5, 98.3, 99.2, 100, 100.8, 101.5, 102.3, 103.1, 103.8, 104.5, 105.2, 105.9, 106.6, 107.3, 108, 108.6, 109.3, 109.9, 110.6, 111.2, 111.8, 112.5, 113.1, 113.7, 114.3, 115, 115.6, 116.2, 116.8, 117.4, 118.1, 118.7],
    P99: [54.3, 59.3, 63.1, 66.2, 68.7, 70.8, 72.6, 74.2, 75.7, 77.2, 78.6, 80, 81.3, 82.6, 83.8, 85, 86.2, 87.4, 88.5, 89.7, 90.7, 91.8, 92.9, 93.9, 94.9, 95.2, 96.2, 97.1, 98.1, 99, 99.9, 100.7, 101.5, 102.4, 103.2, 103.9, 104.7, 105.5, 106.2, 106.9, 107.7, 108.4, 109.1, 109.8, 110.4, 111.1, 111.8, 112.4, 113.1, 113.7, 114.4, 115, 115.7, 116.3, 116.9, 117.6, 118.2, 118.8, 119.5, 120.1, 120.7],
    P999: [55.7, 60.7, 64.6, 67.7, 70.3, 72.4, 74.2, 75.9, 77.4, 78.9, 80.3, 81.7, 83.1, 84.4, 85.7, 87, 88.2, 89.4, 90.6, 91.8, 92.9, 94, 95.1, 96.2, 97.3, 97.6, 98.6, 99.6, 100.6, 101.5, 102.5, 103.4, 104.2, 105.1, 105.9, 106.7, 107.5, 108.3, 109.1, 109.9, 110.6, 111.4, 112.1, 112.8, 113.5, 114.2, 114.9, 115.6, 116.3, 117, 117.6, 118.3, 119, 119.6, 120.3, 121, 121.6, 122.3, 123, 123.6, 124.3]
}

export const girlHFA = {
    Month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
    P1: [44.8, 49.1, 52.3, 54.9, 57.1, 58.9, 60.5, 61.9, 63.2, 64.5, 65.7, 66.9, 68, 69.1, 70.1, 71.1, 72.1, 73, 74, 74.8, 75.7, 76.5, 77.3, 78.1, 78.9, 79, 79.7, 80.4, 81.1, 81.8, 82.5, 83.1, 83.8, 84.4, 85, 85.6, 86.2, 86.8, 87.4, 87.9, 88.5, 89, 89.6, 90.1, 90.7, 91.2, 91.7, 92.2, 92.7, 93.2, 93.7, 94.2, 94.7, 95.2, 95.6, 96.1, 96.6, 97, 97.5, 97.9, 98.4],
    P3: [45.6, 50, 53.2, 55.8, 58, 59.9, 61.5, 62.9, 64.3, 65.6, 66.8, 68, 69.2, 70.3, 71.3, 72.4, 73.3, 74.3, 75.2, 76.2, 77, 77.9, 78.7, 79.6, 80.3, 80.4, 81.2, 81.9, 82.6, 83.4, 84, 84.7, 85.4, 86, 86.7, 87.3, 87.9, 88.5, 89.1, 89.7, 90.3, 90.8, 91.4, 92, 92.5, 93, 93.6, 94.1, 94.6, 95.1, 95.7, 96.2, 96.7, 97.2, 97.6, 98.1, 98.6, 99.1, 99.6, 100, 100.5],
    P5: [46.1, 50.5, 53.7, 56.3, 58.5, 60.4, 62, 63.5, 64.9, 66.2, 67.4, 68.6, 69.8, 70.9, 72, 73, 74, 75, 75.9, 76.9, 77.7, 78.6, 79.5, 80.3, 81.1, 81.2, 82, 82.7, 83.5, 84.2, 84.9, 85.6, 86.2, 86.9, 87.5, 88.2, 88.8, 89.4, 90, 90.6, 91.2, 91.8, 92.4, 92.9, 93.5, 94, 94.6, 95.1, 95.6, 96.2, 96.7, 97.2, 97.7, 98.2, 98.7, 99.2, 99.7, 100.2, 100.7, 101.1, 101.6],
    P10: [46.8, 51.2, 54.5, 57.1, 59.3, 61.2, 62.8, 64.3, 65.7, 67, 68.3, 69.5, 70.7, 71.8, 72.9, 74, 75, 76, 77, 77.9, 78.8, 79.7, 80.6, 81.5, 82.3, 82.4, 83.2, 83.9, 84.7, 85.4, 86.2, 86.9, 87.5, 88.2, 88.9, 89.5, 90.2, 90.8, 91.4, 92, 92.6, 93.2, 93.8, 94.4, 95, 95.6, 96.1, 96.7, 97.2, 97.8, 98.3, 98.8, 99.3, 99.9, 100.4, 100.9, 101.4, 101.9, 102.4, 102.8, 103.3],
    P15: [47.2, 51.7, 55, 57.6, 59.8, 61.7, 63.4, 64.9, 66.3, 67.6, 68.9, 70.2, 71.3, 72.5, 73.6, 74.7, 75.7, 76.7, 77.7, 78.7, 79.6, 80.5, 81.4, 82.2, 83.1, 83.2, 84, 84.8, 85.5, 86.3, 87, 87.7, 88.4, 89.1, 89.8, 90.5, 91.1, 91.7, 92.4, 93, 93.6, 94.2, 94.8, 95.4, 96, 96.6, 97.2, 97.7, 98.3, 98.8, 99.4, 99.9, 100.4, 101, 101.5, 102, 102.5, 103, 103.5, 104, 104.5],
    P25: [47.9, 52.4, 55.7, 58.4, 60.6, 62.5, 64.2, 65.7, 67.2, 68.5, 69.8, 71.1, 72.3, 73.4, 74.6, 75.7, 76.7, 77.7, 78.7, 79.7, 80.7, 81.6, 82.5, 83.4, 84.2, 84.4, 85.2, 86, 86.8, 87.6, 88.3, 89, 89.7, 90.4, 91.1, 91.8, 92.5, 93.1, 93.8, 94.4, 95.1, 95.7, 96.3, 96.9, 97.5, 98.1, 98.7, 99.3, 99.8, 100.4, 100.9, 101.5, 102, 102.6, 103.1, 103.6, 104.2, 104.7, 105.2, 105.7, 106.2],
    P50: [49.1, 53.7, 57.1, 59.8, 62.1, 64, 65.7, 67.3, 68.7, 70.1, 71.5, 72.8, 74, 75.2, 76.4, 77.5, 78.6, 79.7, 80.7, 81.7, 82.7, 83.7, 84.6, 85.5, 86.4, 86.6, 87.4, 88.3, 89.1, 89.9, 90.7, 91.4, 92.2, 92.9, 93.6, 94.4, 95.1, 95.7, 96.4, 97.1, 97.7, 98.4, 99, 99.7, 100.3, 100.9, 101.5, 102.1, 102.7, 103.3, 103.9, 104.5, 105, 105.6, 106.2, 106.7, 107.3, 107.8, 108.4, 108.9, 109.4],
    P75: [50.4, 55, 58.4, 61.2, 63.5, 65.5, 67.3, 68.8, 70.3, 71.8, 73.1, 74.5, 75.8, 77, 78.2, 79.4, 80.5, 81.6, 82.7, 83.7, 84.7, 85.7, 86.7, 87.7, 88.6, 88.8, 89.7, 90.6, 91.4, 92.2, 93.1, 93.9, 94.6, 95.4, 96.2, 96.9, 97.6, 98.3, 99, 99.7, 100.4, 101.1, 101.8, 102.4, 103.1, 103.7, 104.4, 105, 105.6, 106.3, 106.9, 107.5, 108.1, 108.6, 109.2, 109.8, 110.4, 111, 111.5, 112.1, 112.6],
    P85: [51.1, 55.7, 59.2, 62, 64.3, 66.3, 68.1, 69.7, 71.2, 72.6, 74, 75.4, 76.7, 77.9, 79.2, 80.3, 81.5, 82.6, 83.7, 84.8, 85.8, 86.8, 87.8, 88.8, 89.8, 90, 90.9, 91.8, 92.7, 93.5, 94.3, 95.2, 95.9, 96.7, 97.5, 98.3, 99, 99.7, 100.5, 101.2, 101.9, 102.6, 103.3, 103.9, 104.6, 105.3, 105.9, 106.6, 107.2, 107.8, 108.4, 109.1, 109.7, 110.3, 110.9, 111.5, 112.1, 112.6, 113.2, 113.8, 114.4],
    P90: [51.5, 56.2, 59.7, 62.5, 64.9, 66.9, 68.6, 70.3, 71.8, 73.2, 74.6, 76, 77.3, 78.6, 79.8, 81, 82.2, 83.3, 84.4, 85.5, 86.6, 87.6, 88.6, 89.6, 90.6, 90.8, 91.7, 92.6, 93.5, 94.4, 95.2, 96, 96.8, 97.6, 98.4, 99.2, 99.9, 100.7, 101.4, 102.1, 102.9, 103.6, 104.3, 104.9, 105.6, 106.3, 107, 107.6, 108.3, 108.9, 109.5, 110.1, 110.8, 111.4, 112, 112.6, 113.2, 113.8, 114.4, 114.9, 115.5],
    P95: [52.2, 56.9, 60.4, 63.3, 65.7, 67.7, 69.5, 71.1, 72.6, 74.1, 75.5, 76.9, 78.3, 79.5, 80.8, 82, 83.2, 84.4, 85.5, 86.6, 87.7, 88.7, 89.7, 90.7, 91.7, 92, 92.9, 93.8, 94.7, 95.6, 96.5, 97.3, 98.2, 99, 99.8, 100.5, 101.3, 102.1, 102.8, 103.6, 104.3, 105, 105.7, 106.4, 107.1, 107.8, 108.5, 109.2, 109.8, 110.5, 111.1, 111.8, 112.4, 113, 113.6, 114.3, 114.9, 115.5, 116.1, 116.7, 117.2],
    P97: [52.7, 57.4, 60.9, 63.8, 66.2, 68.2, 70, 71.6, 73.2, 74.7, 76.1, 77.5, 78.9, 80.2, 81.4, 82.7, 83.9, 85, 86.2, 87.3, 88.4, 89.4, 90.5, 91.5, 92.5, 92.8, 93.7, 94.6, 95.6, 96.4, 97.3, 98.2, 99, 99.8, 100.6, 101.4, 102.2, 103, 103.7, 104.5, 105.2, 106, 106.7, 107.4, 108.1, 108.8, 109.5, 110.2, 110.8, 111.5, 112.1, 112.8, 113.4, 114.1, 114.7, 115.3, 116, 116.6, 117.2, 117.8, 118.4],
    P99: [53.5, 58.2, 61.8, 64.7, 67.1, 69.2, 71, 72.7, 74.3, 75.8, 77.2, 78.6, 80, 81.3, 82.6, 83.9, 85.1, 86.3, 87.5, 88.6, 89.7, 90.8, 91.9, 92.9, 93.9, 94.2, 95.2, 96.1, 97.1, 98, 98.9, 99.8, 100.6, 101.5, 102.3, 103.1, 103.9, 104.7, 105.5, 106.3, 107, 107.8, 108.5, 109.2, 110, 110.7, 111.4, 112.1, 112.8, 113.4, 114.1, 114.8, 115.4, 116.1, 116.7, 117.4, 118, 118.6, 119.3, 119.9, 120.5],
    P999: [54.9, 59.7, 63.4, 66.3, 68.8, 70.9, 72.7, 74.4, 76.1, 77.6, 79.1, 80.6, 82, 83.3, 84.7, 86, 87.2, 88.5, 89.7, 90.9, 92, 93.1, 94.2, 95.3, 96.4, 96.7, 97.7, 98.7, 99.7, 100.7, 101.6, 102.5, 103.4, 104.3, 105.1, 106, 106.8, 107.6, 108.5, 109.3, 110.1, 110.8, 111.6, 112.4, 113.1, 113.9, 114.6, 115.3, 116, 116.7, 117.5, 118.1, 118.8, 119.5, 120.2, 120.9, 121.5, 122.2, 122.8, 123.5, 124.1]
}