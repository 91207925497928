// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-radio .ant-radio-inner {
    border-color: #FF3259;
}

.custom-radio .ant-radio-checked .ant-radio-inner {
    background-color: #FF3259;
    border-color: #FF3259;
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
    background-color: #FF3259;
}`, "",{"version":3,"sources":["webpack://./src/components/Tool/EstimateDate/style.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".custom-radio .ant-radio-inner {\n    border-color: #FF3259;\n}\n\n.custom-radio .ant-radio-checked .ant-radio-inner {\n    background-color: #FF3259;\n    border-color: #FF3259;\n}\n\n.custom-radio .ant-radio-checked .ant-radio-inner::after {\n    background-color: #FF3259;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
