// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-enter {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .popup-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
  }
  
  .popup-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .popup-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Header/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,4BAA4B;EAC9B;;EAEA;IACE,UAAU;IACV,wBAAwB;IACxB,0DAA0D;EAC5D;;EAEA;IACE,UAAU;IACV,wBAAwB;EAC1B;;EAEA;IACE,UAAU;IACV,4BAA4B;IAC5B,4DAA4D;EAC9D","sourcesContent":[".popup-enter {\n    opacity: 0;\n    transform: translateY(-20px);\n  }\n  \n  .popup-enter-active {\n    opacity: 1;\n    transform: translateY(0);\n    transition: opacity 300ms ease-in, transform 300ms ease-in;\n  }\n  \n  .popup-exit {\n    opacity: 1;\n    transform: translateY(0);\n  }\n  \n  .popup-exit-active {\n    opacity: 0;\n    transform: translateY(-20px);\n    transition: opacity 300ms ease-out, transform 300ms ease-out;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
