// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.before-bg-file::before{
    content: "";
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-image: var(--bg);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    opacity: 1;
    transition: opacity 0.3s;
}
.before-bg-file:hover:before{
    opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/components/AuthenRegister/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,KAAK;IACL,MAAM;IACN,OAAO;IACP,QAAQ;IACR,2BAA2B;IAC3B,sBAAsB;IACtB,wBAAwB;IACxB,4BAA4B;IAC5B,UAAU;IACV,wBAAwB;AAC5B;AACA;IACI,YAAY;AAChB","sourcesContent":[".before-bg-file::before{\n    content: \"\";\n    position: absolute;\n    top:0;\n    left:0;\n    right:0;\n    bottom:0;\n    background-image: var(--bg);\n    background-size: cover;\n    background-position: 50%;\n    background-repeat: no-repeat;\n    opacity: 1;\n    transition: opacity 0.3s;\n}\n.before-bg-file:hover:before{\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
