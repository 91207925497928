import React,{useState} from 'react'
import Header from '../../components/Header/Header'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";  

const ConfirmEmail = () => {
  const navigate = useNavigate();
    const [OTPinput, setOTPinput] = useState(['', '', '', '', '', '']);

    const goBack = () => {
      navigate(-1);
    };

  return (
    <>
        <Header/>

        <Link className='btn btn-light my-3 flex font-bold m-3' onClick={goBack}>
            <AiOutlineArrowLeft className='mr-1 font-bold text-[22px] mt-[1px]'/>
           Previous
        </Link>
        
        <div className="flex items-center justify-center mt-32">
        <div className="container mx-auto px-7 w-full max-w-md">
          <div className="text-center space-y-4">
            <h4 className="text-4xl font-semibold mb-4">OTP Confirm</h4>
            <p className="text-sm">
              OTP has been sent to your email.
            </p>

            <div className="space-y-3">
                <div className='d-flex justify-content-center pb-4'>
                {OTPinput.map((digit, index) => (
                    <input
                    key={index}
                    maxLength='1'
                    className='form-control text-center mx-1 fs-2 border w-[50px] h-[50px]'
                    type='text'
                    value={digit}
                    onChange={(e) => {
                        const newOTPinput = [...OTPinput];
                        newOTPinput[index] = e.target.value;
                        setOTPinput(newOTPinput);
                    }}
                    />
                ))}
                </div>
              <div className='flex'>
                <button
                    className="bg-pinkColor py-3 w-[60%] text-white font-bold rounded-lg mx-2"
                    type="button"
                >
                    Next
                </button>

                <button
                    className="bg-gray-400 py-3 w-[35%] text-white font-bold rounded-lg"
                    type="button"
                >
                Resend
                </button>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmEmail