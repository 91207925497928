// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
/* Contact page css */
.custom-button {
  background-color: #FF3259; /* Default background color */
  color: white;
  transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition for color and opacity */
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,kBAAkB;AACpB;AACA,qBAAqB;AACrB;EACE,yBAAyB,EAAE,6BAA6B;EACxD,YAAY;EACZ,yDAAyD,EAAE,4CAA4C;AACzG","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: \"Noto Sans\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n}\n/* Contact page css */\n.custom-button {\n  background-color: #FF3259; /* Default background color */\n  color: white;\n  transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition for color and opacity */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
