import React from 'react';
import Header from '../components/Header/Header';
import toolImg from '../assets/image/tool/image 5.png';
import { FaAngleRight, FaRegUser } from 'react-icons/fa';
import Footer from '../components/Footer/Footer';
import { Link } from 'react-router-dom';
import eddIcon from '../assets/image/tool/Due-Date.png';
import growthicon from '../assets/image/tool/Baby-Growth-Chart.png';
import weightGain from '../assets/image/tool/Pregnancy-Weight-Gain.png';

export const Tool = () => {
  return (
    <>
      <Header />
      <div className='container mx-auto mb-36'>
        {/* Header Section */}
        <div className='bg-primaryColor h-[350px] sm:h-[320px] rounded-b-[150px] sm:rounded-b-[250px]'>
          <div className='flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0 lg:space-x-10 py-10'>
            <div className='text-center lg:text-left space-y-6 lg:space-y-10'>
              <h1 className='text-[30px] sm:text-[50px] font-bold text-pinkColor'>Health Apps</h1>
              <p className='text-base sm:text-xl hidden sm:block'>
                These easy-to-use health assessment tools will help you assess <br className='hidden md:block' />
                important factors in your overall health and quality of health.
              </p>
            </div>
            <div className=''>
              <img className='w-full h-64 hidden sm:block' src={toolImg} alt="Tool" />
            </div>
          </div>
        </div>

        {/* Tools Section */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-10 lg:mx-56'>
          {/* Tool 1 */}
          <Link to='/tool/estimated' className='bg-white border-black border-[1px] h-[100px] p-4 rounded-2xl flex justify-between items-center shadow-md cursor-pointer'>
            <div className='flex items-center space-x-4'>
              <img src={eddIcon} className="rounded-full" alt="EDD icon" style={{ width: "4rem" }} />
              <div>
                <p className='text-sm sm:text-md font-medium'>Estimated Delivery Date (EDD)</p>
              </div>
            </div>
            <FaAngleRight className='w-5 h-5' />
          </Link>


          {/* Tool 2 */}
          <Link to='/tool/growth-child' className='bg-white border-black border-[1px] h-[100px] p-4 rounded-2xl flex justify-between items-center shadow-md cursor-pointer'>
            <div className='flex items-center space-x-4'>
              <img src={growthicon} className="rounded-full" alt="EDD icon" style={{ width: "4rem" }} />
              <div>
                <p className='text-sm sm:text-md font-medium'>Children's growth chart: Height, weight</p>
              </div>
            </div>
            <FaAngleRight className='w-5 h-5' />
          </Link>

          {/* Tool 3 */}
          <Link to='/tool/weight' className='bg-white border-black border-[1px] h-[100px] p-4 rounded-2xl flex justify-between items-center shadow-md cursor-pointer'>
            <div className='flex items-center space-x-4'>
              <img src={weightGain} className="rounded-full" alt="EDD icon" style={{ width: "4rem" }} />
              <div>
                <p className='text-sm sm:text-md font-medium'>Weight gain during pregnancy</p>
              </div>
            </div>
            <FaAngleRight className='w-5 h-5' />
          </Link>

        </div>
      </div>
      <Footer />
    </>
  );
};
