import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home } from '../pages/Home/Home'
import { Contact } from '../pages/Contact'
import { Support } from '../pages/Support'
import { HealthInformation } from '../pages/HealthInformation'
import { Login } from '../pages/Login'
import EstimateDatePage from '../components/Tool/EstimateDate/EstimateDatePage'
import ChildrenGrowthPage from '../components/Tool/ChildrenGrowth/ChildrenGrowthPage'
import Register from '../pages/Register'
import ConfirmEmail from '../components/AuthenRegister/ConfirmEmail'
import RegisterForm from '../components/AuthenRegister/RegisterForm'
import OptionRole from '../components/AuthenRegister/OptionRole'
import NursingRegister from '../components/AuthenRegister/NursingRegister'
import AfterChildbirthRegister from '../components/AuthenRegister/AfterChildbirthRegister'
import PregnantMothersRegister from '../components/AuthenRegister/PregnantMothersRegister'
import ConfirmNursing from '../components/AuthenRegister/ConfirmNursing'
import { WeightOfPregnant } from '../components/Tool/WeightOfPregnant/WeightOfPregnant'
import { Tool } from '../pages/Tool'
import NotFound from '../components/UI/NotFound'
import { ChangePassword } from '../components/Profile/ChangePassword/ChangePassword'
import { ProfileInformation } from '../components/Profile/ProfileInformation'
import { DisableAccount } from '../components/Profile/DisableAccount'
import { Schedule } from '../components/Schedule/Schedule'
import { WeightOfPregnantResult } from '../components/Tool/WeightOfPregnant/WeightOfPregnantResult'
import HomeMom from '../pages/Home/HomeMom'
import ChildrenGrowthPageResult from '../components/Tool/ChildrenGrowth/ChildrenGrowthPageResult'

export const Routers = () => {
  return (
    <>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/home-mom" element={<HomeMom/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/support' element={<Support/>} />
            <Route path='/health' element={<HealthInformation/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/register' element={<Register/>} />
            <Route path='/confirm-nursing' element={<ConfirmNursing/>} />
            <Route path='/tool' element={<Tool/>} />
            <Route path='/tool/estimated' element={<EstimateDatePage/>} />
            <Route path='/tool/weight' element={<WeightOfPregnant/>} />
            <Route path='/tool/weight/result' element={<WeightOfPregnantResult/>} />
            <Route path='/tool/growth-child' element={<ChildrenGrowthPage/>} />
            <Route path='/tool/growth-child/result' element={<ChildrenGrowthPageResult/>} />
            <Route path='/confirm-email' element={<ConfirmEmail/>} />
            <Route path='/register-form' element={<RegisterForm/>} />
            <Route path='/option-role' element={<OptionRole/>} />
            <Route path='/after-childbirth' element={<AfterChildbirthRegister/>} />
            <Route path='/nursing' element={<NursingRegister/>} />
            <Route path='/pregnant-mothers' element={<PregnantMothersRegister/>} />
            <Route path='/profile' element={<ProfileInformation/>} />
            <Route path='/change-pass' element={<ChangePassword/>} />
            <Route path='/account' element={<DisableAccount/>} />
            <Route path='/schedule' element={<Schedule/>} />
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    </>
  )
}
